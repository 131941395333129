import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../../../../styled/generic/StandardAppContainerRounded";
import { Box } from "@mui/material";
import Button from "../../../../styled/generic/Button";
import Dialog from "../../../../styled/generic/Dialog";
import DocumentUploader from "../../../../styled/generic/DocumentUploader";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../../../helpers/Api";
import TableContainer from "../../../../styled/generic/TableContainer";
import DocViewer from "../../../../styled/generic/DocViewer";

const Documents = ({ finrel }) => {
  const { user } = useSelector((state) => state.auth);
  const files = useSelector((state) => state.file);
  const { fileDictionary, createdFileIds } = files;
  const dispatch = useDispatch();

  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentIds, setDocumentIds] = useState([]);

  const uploadFinrelDocs = async (documentIdsAry) => {
    try {
      const { data } = await Api.post("/employee/docs/upload", {
        finRelId: finrel?._id,
        documents: documentIdsAry,
      });
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Documents uploaded successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to upload documents",
        },
      });
    }
  };

  const removeFinrelDoc = async (docId) => {
    try {
      const { data } = await Api.post("/employee/docs/remove", {
        finRelId: finrel?._id,
        documentId: docId,
      });
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Document deleted successfully",
        },
      });
    } catch (error) {
      console.log("Error deleting Finrel Docs: ", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to delete documents",
        },
      });
    }
  };

  useEffect(() => {
    if (finrel?._id) {
      setDocuments(finrel?.firstPartyContact?.documents || []);
    }
  }, [finrel]);

  return (
    <StandardAppContainerRounded>
      <Box display="flex" justifyContent="end">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowUploadDialog(true)}
        >
          Document
        </Button>
      </Box>
      {documents.length > 0 ? (
        <Box>
          <DocViewer
            data={documents}
            isDeletable={true}
            disableEditMetaData={true}
            disableShowMetaData={true}
            onMetadataChange={() => {}}
            deleteFunction={(delId) => {
              setDocumentIds(documentIds.filter((m) => m !== delId));
              setDocuments(documents.filter((m) => m._id !== delId));
              removeFinrelDoc(delId);
            }}
          />
        </Box>
      ) : (
        <Box>No documents found</Box>
      )}
      <Dialog
        open={showUploadDialog}
        setOpen={setShowUploadDialog}
        title="Upload Document"
      >
        <DocumentUploader
          documents={documents}
          onUpload={() => {
            setDocumentIds(createdFileIds);
            let data = [];
            for (let i = 0; i < createdFileIds?.length; i++) {
              data.push(fileDictionary[createdFileIds[i]]);
            }
            setDocuments(data);
            uploadFinrelDocs(createdFileIds);
          }}
          onDelete={(dataId) => {
            setDocumentIds(documentIds.filter((m) => m !== dataId));
            setDocuments(documents.filter((m) => m._id !== dataId));
          }}
          parentId={finrel?._id}
          parentType="FinanceRelation"
        />
      </Dialog>
    </StandardAppContainerRounded>
  );
};

export default Documents;

import React, { useEffect, useState } from "react";
import StandardAppContainer from "../../../../styled/generic/StandardAppContainer";
import StandardAppContainerRounded from "../../../../styled/generic/StandardAppContainerRounded";
import FormBox from "../../../../styled/generic/FormBox";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import Api from "../../../../../helpers/Api";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";

const PaySlipTemplate = () => {
  const { organizationId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [templates, setTemplates] = useState([]);
  const [templatesLoading, setTemplatesLoading] = useState(false);

  const getPayslipTemplates = async () => {
    try {
      setTemplatesLoading(true);
      const { data } = await Api.post("/payslip/template/get", {
        organizationId,
      });
      setTemplates(data);
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get payslip templates",
        },
      });
    } finally {
      setTemplatesLoading(false);
    }
  };

  useEffect(() => {
    getPayslipTemplates();
  }, []);

  return (
    <StandardAppContainerRounded fullHeight={true}>
      <FormBox label="Pay slip Template">
        {templatesLoading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 16 }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2} sx={{ mt: 3 }}>
            {templates.map((template) => (
              <Grid item xs={4}>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push(`/payslip/template/${template._id}`);
                  }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    {template?.name}
                  </Typography>
                  <div style={{ position: "relative" }}>
                    <img
                      src="/icons/app/payslip_img.png"
                      style={{ width: "100%" }}
                    />
                    {template?.isDefault && (
                      <div
                        style={{
                          backgroundColor: "#2D76E0",
                          padding: "3px 6px",
                          color: "#ffffff",
                          top: "0",
                          right: "0",
                          borderRadius: "0px 0px 0px 20px",
                          position: "absolute",
                          marginTop: "30px",
                          marginRight: "30px",
                        }}
                      >
                        Default
                      </div>
                    )}
                  </div>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </FormBox>
    </StandardAppContainerRounded>
  );
};

export default PaySlipTemplate;

import React, { useEffect } from "react";
import StandardAppContainer from "../../../../styled/generic/StandardAppContainer";
import StandardAppContainerRounded from "../../../../styled/generic/StandardAppContainerRounded";
import {
  Box,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import LabelWithSingleActionButton from "../../../../styled/generic/LabelWithSingleActionButton";
import { Add, DeleteOutlined, EditOutlined } from "@mui/icons-material";
import { useState } from "react";
import BoxSpaceBetween from "../../../../styled/generic/BoxSpaceBetween";
import SearchField from "../../../../styled/generic/SearchField";
import DrawerContainer from "../../../../styled/generic/DrawerContainer";
import FormBox from "../../../../styled/generic/FormBox";
import TextField from "../../../../styled/generic/TextField";
import SpaceBetween from "../../../../styled/generic/SpaceBetween";
import DatePicker from "../../../../styled/generic/DatePicker";
import Select from "../../../../styled/generic/Select";
import Switch from "../../../../styled/generic/Switch";
import DuoButtonGroup from "../../../../styled/generic/DuoButtonGroup";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../../../helpers/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import TableContainer from "../../../../styled/generic/TableContainer";
import DateText from "../../../../styled/generic/DateText";
import dayjs from "dayjs";

const HOLIDAY_TYPES = ["Public", "Religious", "Regional", "Secular", "Other"];

const HolidayList = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { organizationId } = useParams();

  const [showCreateHolidayDrawer, setShowCreateHolidayDrawer] = useState(false);
  const [holidayName, setHolidayName] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [date, setDate] = useState();
  const [description, setDescription] = useState("");
  const [holidayMode, setHolidayMode] = useState("single");
  const [holidayType, setHolidayType] = useState(HOLIDAY_TYPES[0]);
  const [restrictedHoliday, setRestrictedHoliday] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [holidaysLoading, setHolidaysLoading] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState(null);

  const createHoliday = async () => {
    try {
      const { data } = await Api.post(`/holidays/create`, {
        name: holidayName,
        mode: holidayMode,
        fromDate: holidayMode === "multiple" ? fromDate : null,
        toDate: holidayMode === "multiple" ? toDate : null,
        date: holidayMode === "single" ? date : null,
        description: description,
        type: holidayType,
        isRestricted: restrictedHoliday,
        user: user?._id,
        organization: organizationId,
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Holiday created successfully",
          },
        });
        setHolidays([...holidays, data]);
        setShowCreateHolidayDrawer(false);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const getHolidays = async () => {
    try {
      setHolidaysLoading(true);
      const { data } = await Api.post(`/holidays/get`, {
        organization: organizationId,
      });
      if (data) {
        setHolidays(data);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get holidays",
        },
      });
    } finally {
      setHolidaysLoading(false);
    }
  };

  const updateHoliday = async () => {
    try {
      const { data } = await Api.post(`/holidays/update`, {
        _id: selectedHoliday?._id,
        updateBody: {
          name: holidayName,
          mode: holidayMode,
          fromDate: holidayMode === "multiple" ? fromDate : null,
          toDate: holidayMode === "multiple" ? toDate : null,
          date: holidayMode === "single" ? date : null,
          description: description,
          type: holidayType,
          isRestricted: restrictedHoliday,
        },
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Holiday updated successfully",
          },
        });
        setHolidays(
          holidays.map((holiday) => {
            if (holiday._id === selectedHoliday?._id) {
              return data;
            }
            return holiday;
          })
        );

        setShowCreateHolidayDrawer(false);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const deleteHoliday = async (id) => {
    try {
      const { data } = await Api.post(`/holidays/delete`, {
        _id: id,
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Holiday deleted successfully",
        },
      });
      setHolidays(
        holidays.filter((holiday) => {
          if (holiday._id === id) {
            return false;
          }
          return true;
        })
      );
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    getHolidays();
  }, []);

  useEffect(() => {
    if (selectedHoliday) {
      setHolidayName(selectedHoliday?.name);
      setHolidayMode(selectedHoliday?.mode);
      setFromDate(dayjs(selectedHoliday?.fromDate));
      setToDate(dayjs(selectedHoliday?.toDate));
      setDate(dayjs(selectedHoliday?.date));
      setDescription(selectedHoliday?.description);
      setHolidayType(selectedHoliday?.type);
      setRestrictedHoliday(selectedHoliday?.isRestricted);
    } else {
      setHolidayName("");
      setHolidayMode("single");
      setFromDate();
      setToDate();
      setDate();
      setDescription("");
      setHolidayType(HOLIDAY_TYPES[0]);
      setRestrictedHoliday(false);
    }
  }, [showCreateHolidayDrawer]);

  return (
    <Box>
      <LabelWithSingleActionButton
        label="Holiday List"
        actionButtonIcon={<Add />}
        actionButtonText="Create"
        actionButtonVariant="contained"
        actionButtonListener={() => setShowCreateHolidayDrawer(true)}
      />
      <StandardAppContainerRounded>
        <Box display="flex" justifyContent="flex-end">
          <SearchField placeholder="Search Holiday List" size="small" />
        </Box>
        <TableContainer
          columns={[
            "HOLIDAY NAME",
            "FROM DATE",
            "TO DATE",
            "TOTAL DAYS",
            "TYPE",
            "ACTIONS",
          ]}
          data={holidays}
          loading={holidaysLoading}
        >
          {holidays.map((holiday) => (
            <tr>
              <td>{holiday.name}</td>
              <td>
                <DateText
                  date={
                    holiday?.mode === "multiple"
                      ? holiday?.fromDate
                      : holiday?.date
                  }
                />
              </td>
              <td>
                <DateText
                  date={
                    holiday?.mode === "multiple"
                      ? holiday?.toDate
                      : holiday?.date
                  }
                />
              </td>
              <td>
                {holiday?.mode === "single"
                  ? 1
                  : dayjs(holiday?.toDate).diff(holiday?.fromDate, "day")}
              </td>
              <td>{holiday?.type}</td>
              <td>
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSelectedHoliday(holiday);
                      setShowCreateHolidayDrawer(true);
                    }}
                    sx={{ mr: 2 }}
                  >
                    <EditOutlined />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => deleteHoliday(holiday?._id)}
                  >
                    <DeleteOutlined />
                  </IconButton>
                </Box>
              </td>
            </tr>
          ))}
        </TableContainer>
      </StandardAppContainerRounded>
      <DrawerContainer
        title="Holiday List"
        open={showCreateHolidayDrawer}
        setOpen={setShowCreateHolidayDrawer}
        placeholder="Enter"
      >
        <FormBox label="Holiday Name" disableMargins={true}>
          <TextField
            fullWidth
            value={holidayName}
            onChange={(e) => setHolidayName(e.target.value)}
            placeholder="Enter"
          />
        </FormBox>
        <RadioGroup
          row
          value={holidayMode}
          onChange={(e) => setHolidayMode(e.target.value)}
          sx={{ mt: 1 }}
        >
          <FormControlLabel
            value="single"
            control={<Radio />}
            label="Single Day"
          />
          <FormControlLabel
            value="multiple"
            control={<Radio />}
            label="Multiple Day"
          />
        </RadioGroup>
        {holidayMode === "single" ? (
          <SpaceBetween
            sx={{ mt: 3 }}
            left={
              <FormBox label="Date">
                <DatePicker value={date} onChange={(date) => setDate(date)} />
              </FormBox>
            }
          />
        ) : (
          <SpaceBetween
            sx={{ mt: 3 }}
            left={
              <FormBox label="From Date">
                <DatePicker
                  value={fromDate}
                  onChange={(date) => setFromDate(date)}
                />
              </FormBox>
            }
            right={
              <FormBox label="To Date">
                <DatePicker
                  value={toDate}
                  onChange={(date) => setToDate(date)}
                />
              </FormBox>
            }
          />
        )}
        <FormBox label="Description">
          <TextField
            value={description}
            onChange={(evt) => setDescription(evt.target.value)}
            fullWidth
            multiline
            rows={4}
            placeholder="Enter Description"
            inputProps={{ maxLength: 200 }}
            helperText={`${description.length}/200`}
          />
        </FormBox>
        <FormBox label="Holiday Type">
          <Select
            value={holidayType}
            onChange={(e) => setHolidayType(e.target.value)}
            fullWidth
          >
            {HOLIDAY_TYPES.map((holidayType) => (
              <MenuItem key={holidayType} value={holidayType}>
                {holidayType}
              </MenuItem>
            ))}
          </Select>
        </FormBox>
        <FormControlLabel
          control={
            <Switch
              checked={restrictedHoliday}
              onChange={(evt, checked) => setRestrictedHoliday(checked)}
            />
          }
          label="Restricted Holiday"
        />
        <DuoButtonGroup
          primaryButtonText={selectedHoliday ? "Update" : "Create"}
          primaryButtonListener={() => {
            if (selectedHoliday) {
              updateHoliday();
            } else {
              createHoliday();
            }
          }}
          secondaryButtonText="Cancel"
          secondaryButtonListener={() => setShowCreateHolidayDrawer(false)}
        />
      </DrawerContainer>
    </Box>
  );
};

export default HolidayList;

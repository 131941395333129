import React, { useEffect, useState } from "react";
import StandardContainer from "../../styled/generic/StandardContainer";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  AccountBalanceWalletOutlined,
  CoPresentOutlined,
  DraftsOutlined,
  ExpandLess,
  ExpandMore,
  PunchClockOutlined,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import Departments from "./administration/Departments";
import Designations from "./administration/Designations";
import Locations from "./administration/Locations";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import EditSalaryComponent from "./finance/salarycomponents/EditSalaryComponent";
import SalaryComponents from "./finance/salarycomponents/SalaryComponents";
import HolidayList from "./workschedule/holidaylist/HolidayList";
import WorkSchedule from "./workschedule/workschedule/WorkSchedule";
import ConfigureWorkSchedule from "./workschedule/workschedule/ConfigureWorkSchedule";
import WorkEntryCode from "./attendance/workentrycode/WorkEntryCode";
import LeaveType from "./leavepolicy/LeaveType";
import ConfigureLeave from "./leavepolicy/ConfigureLeave";
import PayrollSettings from "./finance/payrollsettings/PayrollSettings";
import PaySlipTemplate from "./finance/payslip/PaySlipTemplate";

const HRSettings = () => {
  const [toggledMenu, setToggledMenu] = useState("administration");

  const handleMenuToggle = (menu) => {
    if (toggledMenu === menu) {
      setToggledMenu(null);
    } else {
      setToggledMenu(menu);
    }
  };

  const [view, setView] = useState("WorkEntryCode");
  const { organizationId } = useParams();

  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const newView = searchParams.get("view");
    if (newView) {
      setView(newView);
    } else {
      setView("Departments");
    }
  }, [searchParams]);

  const changeView = (view) => {
    history.push(`/hr/settings/${organizationId}?view=${view}`);
  };

  const [drawerList, setDrawerList] = useState([
    {
      title: "Administration",
      icon: <SupervisorAccountOutlined />,
      code: "administration",
      subMenu: [
        {
          title: "Departments",
          code: "Departments",
        },
        {
          title: "Designations",
          code: "Designations",
        },
        {
          title: "Location",
          code: "Location",
        },
      ],
    },
    {
      title: "Finance",
      icon: <AccountBalanceWalletOutlined />,
      code: "finance",
      subMenu: [
        {
          title: "Salary Components",
          code: "SalaryComponents",
        },
        {
          title: "Payroll Settings",
          code: "PayrollSettings",
        },
        {
          title: "Pay Slip",
          code: "PaySlip",
        },
      ],
    },
    {
      title: "Work Schedule",
      icon: <PunchClockOutlined />,
      code: "workschedule",
      subMenu: [
        {
          title: "Holiday List",
          code: "HolidayList",
        },
        {
          title: "Work Schedule",
          code: "WorkSchedule",
        },
      ],
    },
    {
      title: "Attendance",
      icon: <CoPresentOutlined />,
      code: "attendance",
      subMenu: [
        {
          title: "Work Entry Code",
          code: "WorkEntryCode",
        },
      ],
    },
    {
      title: "Leave Policy",
      icon: <DraftsOutlined />,
      code: "leave",
      subMenu: [
        {
          title: "Leave Type",
          code: "LeaveType",
        },
      ],
    },
  ]);

  return (
    <StandardContainer
      appBarTitle="Settings"
      showAppBar={true}
      display="flex"
      onAppBarBackButtonClick={() => {
        // history.push(`/hr/${organizationId}`);
        history.goBack();
      }}
    >
      <Box flex="0.2">
        <List
          sx={{ width: "100%", bgcolor: "background.paper" }}
          component="nav"
        >
          {drawerList.map((item) => (
            <>
              <ListItemButton onClick={() => handleMenuToggle(item.code)}>
                <ListItemIcon
                  sx={{
                    color: (theme) =>
                      toggledMenu === item.code
                        ? theme.palette.primary.main
                        : "auto",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} />
                {toggledMenu === item.code ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse
                in={toggledMenu === item.code}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {item.subMenu.map((subItem) => (
                    <ListItemButton
                      sx={{ pl: 10 }}
                      onClick={() => changeView(subItem.code)}
                    >
                      <ListItemText
                        primary={subItem.title}
                        primaryTypographyProps={{
                          color: view === subItem.code ? "primary" : "auto",
                          fontWeight: view === subItem.code ? "bold" : "auto",
                        }}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </>
          ))}
        </List>
      </Box>
      <Box flex="0.8" sx={{ ml: 2 }}>
        {view === "Departments" && <Departments />}
        {view === "Designations" && <Designations />}
        {view === "Location" && <Locations />}
        {view === "SalaryComponents" && <SalaryComponents />}
        {view === "EditSalaryComponent" && <EditSalaryComponent />}
        {view === "WorkingDays" && <div>Working Days</div>}
        {view === "WorkSchedule" && <WorkSchedule />}
        {view === "ConfigureWorkSchedule" && <ConfigureWorkSchedule />}
        {view === "HolidayList" && <HolidayList />}
        {view === "WorkEntryCode" && <WorkEntryCode />}
        {view === "LeaveType" && <LeaveType />}
        {view === "ConfigureLeave" && <ConfigureLeave />}
        {view === "PayrollSettings" && <PayrollSettings />}
        {view === "PaySlip" && <PaySlipTemplate />}
      </Box>
    </StandardContainer>
  );
};

export default HRSettings;

import { Box } from "@mui/material";
import React from "react";

const FluidBox = ({ children }) => {
  return (
    <Box display="flex" justifyContent="center">
      <Box sx={{ width: "60%" }}>{children}</Box>
    </Box>
  );
};

export default FluidBox;

import React, { useEffect, useState } from "react";
import NormalDialog from "../../../styled/CommonComponents/NormalDialog";
import StandardDialogContainer from "../../../styled/generic/StandardDialogContainer";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import FormBox from "../../../styled/generic/FormBox";
import Select from "../../../styled/generic/Select";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import { useDispatch } from "react-redux";
import Api from "../../../../helpers/Api";

const ChangeStatusDialog = ({
  open,
  setOpen,
  selectedRows,
  setSelectedRows,
  reloadTimesheets,
}) => {
  const [fromAction, setFromAction] = useState("PENDING");
  const [toAction, setToAction] = useState("APPROVED");
  const dispatch = useDispatch();

  useEffect(() => {
    switch (fromAction) {
      case "PENDING":
        setToAction("APPROVED");
        break;
      case "APPROVED":
        setToAction("UNBILLED");
        break;
      case "UNBILLED":
        setToAction("PAID");
        break;
    }
  }, [fromAction]);

  const [rowsToUpdate, setRowsToUpdate] = useState([]);

  useEffect(() => {
    setRowsToUpdate(selectedRows?.filter((row) => row.status === fromAction));
  }, [selectedRows, fromAction]);

  const changeStatus = async () => {
    try {
      const { data } = await Api.post("/timesheet/update/status", {
        timesheetIds: rowsToUpdate.map((row) => row._id),
        status: toAction,
      });
      setOpen(false);
      setSelectedRows([]);
      reloadTimesheets();
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to change status",
        },
      });
    }
  };

  return (
    <NormalDialog
      openDialog={open}
      handleCloseShare={() => {
        setOpen(false);
      }}
      pageTitle={"Change Status"}
      content={
        <StandardDialogContainer>
          <SpaceBetween
            left={
              <FormBox label="From Status">
                <Select
                  value={fromAction}
                  onChange={(evt) => setFromAction(evt.target.value)}
                  fullWidth
                >
                  <MenuItem value="PENDING">Pending</MenuItem>
                  <MenuItem value="APPROVED">Approved</MenuItem>
                  <MenuItem value="UNBILLED">Unbilled</MenuItem>
                </Select>
              </FormBox>
            }
            right={
              <FormBox label="To Status">
                {fromAction === "PENDING" ? (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={toAction}
                    onChange={(evt) => setToAction(evt.target.value)}
                    fullWidth
                  >
                    <MenuItem value="APPROVED">Approved</MenuItem>
                    <MenuItem value="UNBILLED">Unbilled</MenuItem>
                    <MenuItem value="PAID">Paid</MenuItem>
                    <MenuItem value="REJECTED">Rejected</MenuItem>
                  </Select>
                ) : fromAction === "APPROVED" ? (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={toAction}
                    onChange={(evt) => setToAction(evt.target.value)}
                    fullWidth
                  >
                    <MenuItem value="UNBILLED">Unbilled</MenuItem>
                    <MenuItem value="PAID">Paid</MenuItem>
                  </Select>
                ) : fromAction === "UNBILLED" ? (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={toAction}
                    onChange={(evt) => setToAction(evt.target.value)}
                    fullWidth
                  >
                    <MenuItem value="PAID">Paid</MenuItem>
                  </Select>
                ) : (
                  <></>
                )}
              </FormBox>
            }
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ my: 2 }}
          >
            <Typography variant="body2" color="primary" sx={{ mr: 2 }}>
              {selectedRows?.length <= 0
                ? "Please select an item"
                : rowsToUpdate?.length <= 0
                ? "Please select a valid item"
                : rowsToUpdate?.length + " row(s) will be modified"}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() => changeStatus()}
              disabled={rowsToUpdate?.length <= 0}
            >
              Apply
            </Button>
          </Box>
        </StandardDialogContainer>
      }
    />
  );
};

export default ChangeStatusDialog;

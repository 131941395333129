import React, { useEffect, useState } from "react";
import StandardContainer from "../../../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../../../styled/generic/StandardAppContainerRounded";
import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import SingleUserWithCaptionTableCell from "../../../styled/generic/SingleUserWithCaptionTableCell";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import Api from "../../../../helpers/Api";
import FormBox from "../../../styled/generic/FormBox";
import dayjs from "dayjs";
import DatePicker from "../../../styled/generic/DatePicker";
import Select from "../../../styled/generic/Select";
import TextField from "../../../styled/generic/TextField";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import Button from "../../../styled/generic/Button";

const EndEmployeeContract = () => {
  const { employeeId } = useParams();
  const dispatch = useDispatch();
  const [loadingEmployeeDetails, setLoadingEmployeeDetails] = useState(false);
  const [finrel, setFinrel] = useState();
  const [exitDate, setExitDate] = useState(dayjs());
  const [exitReason, setExitReason] = useState("Resignation");
  const EXIT_REASONS = [
    "Resignation",
    "Retirement",
    "Termination (Specific Reason)",
  ];
  const [finalSettlementDate, setFinalSettlementDate] = useState(dayjs());
  const [noticePeriodEnabled, setNoticePeriodEnabled] = useState(false);
  const [noticePeriodType, setNoticePeriodType] = useState("Payable");
  const [noticePeriodAmount, setNoticePeriodAmount] = useState();
  const [paymentTime, setPaymentTime] = useState("Pay Schedule");
  const [description, setDescription] = useState("");
  const [noticePeriodStartDate, setNoticePeriodStartDate] = useState(dayjs());
  const [noticePeriodEndDate, setNoticePeriodEndDate] = useState(dayjs());

  const getEmployeeDetails = async () => {
    try {
      setLoadingEmployeeDetails(true);
      const { data } = await Api.post("/employee/get-by-id", {
        employeeId: employeeId,
      });

      if (data) {
        setFinrel(data);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch employee details",
        },
      });
    } finally {
      setLoadingEmployeeDetails(false);
    }
  };

  useEffect(() => {
    getEmployeeDetails();
  }, []);

  const exitEmployee = async () => {
    try {
      const { data } = await Api.post("/employee/exit", {
        finRelId: finrel._id,
        noticePeriodExists: noticePeriodEnabled,
        exitDate: exitDate.toDate(),
        endOfNoticePeriod: noticePeriodEndDate.toDate(),
        exitReason: exitReason,
        description: description,
      });

      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Employee exited successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to exit employee",
        },
      });
    }
  };

  return (
    <StandardContainer showAppBar={true} appBarTitle="Exit Employee">
      <StandardAppContainerRounded fullHeight={true}>
        {loadingEmployeeDetails ? (
          <Box
            sx={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <BoxSpaceBetween>
                <Box>
                  <SingleUserWithCaptionTableCell
                    label={finrel?.mainProfile?.parent?.displayName}
                    caption={`${finrel?.firstPartyContact?.department?.displayName} ${finrel?.firstPartyContact?.designation?.name}`}
                    src={finrel?.mainProfile?.parent?.displayPicture?.url}
                    avatarSx={{
                      width: 60,
                      height: 60,
                    }}
                    labelStyleProps={{ fontSize: "1.2rem" }}
                    captionStyleProps={{ fontSize: "1rem" }}
                  />
                </Box>
                <Box>
                  <FormBox
                    label="Contract Start Date:"
                    labelTypographyVariant="subtitle1"
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {dayjs(finrel?.payrollContract?.startDate).format(
                        "DD MMMM YYYY"
                      )}
                    </Typography>
                  </FormBox>
                </Box>
              </BoxSpaceBetween>
              <FormBox label="Exit Date" sx={{ mt: 2 }}>
                <DatePicker value={exitDate} onChange={setExitDate} />
              </FormBox>
              <FormBox label="Exit Reason">
                <Select
                  value={exitReason}
                  onChange={(e) => setExitReason(e.target.value)}
                  fullWidth
                >
                  {EXIT_REASONS.map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormBox>
              <FormBox label="Final Settlement Date">
                <DatePicker
                  value={finalSettlementDate}
                  onChange={setFinalSettlementDate}
                />
              </FormBox>
              <FormBox
                label="Notice Pay"
                sx={{
                  border: "1px solid #F3F3F3",
                  p: 3,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value={noticePeriodEnabled}
                      onChange={(e, checked) => setNoticePeriodEnabled(checked)}
                    />
                  }
                  label="Is the employee entitled to retain the notice pay?"
                />
                {/* <Typography variant="body2" sx={{ mt: 2 }} gutterBottom>
                  Please select this option if the employee is not fulfilling
                  the required notice period (an amount may be recoverable), or
                  if the company terminates an employee without prior notice (an
                  amount may be payable).
                </Typography>
                <BoxSpaceBetween>
                  <Typography variant="body2">Notice Pay Type</Typography>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={noticePeriodType}
                    onChange={(e) => setNoticePeriodType(e.target.value)}
                    defaultValue="Payable"
                  >
                    <FormControlLabel
                      value="Payable"
                      control={<Radio />}
                      label="Payable"
                    />
                    <FormControlLabel
                      value="Receivable "
                      control={<Radio />}
                      label="Receivable "
                    />
                  </RadioGroup>
                </BoxSpaceBetween>
                <BoxSpaceBetween>
                  <Typography variant="body2">Payable amount</Typography>
                  <TextField
                    value={noticePeriodAmount}
                    onChange={(e) => setNoticePeriodAmount(e.target.value)}
                    placeholder="Enter"
                    size="small"
                  />
                </BoxSpaceBetween> */}
              </FormBox>
            </Grid>
            <Grid item xs={6}>
              {/* <FormBox label="When would you prefer to finalize the last payment?">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Pay Schedule"
                  name="radio-buttons-group"
                  value={paymentTime}
                  onChange={(e) => setPaymentTime(e.target.value)}
                >
                  <FormControlLabel
                    value="Pay Schedule"
                    control={<Radio />}
                    label="Pay as per the regular pay schedule"
                  />
                  <FormControlLabel
                    value="Given Date"
                    control={<Radio />}
                    label="Pay on given date"
                  />
                </RadioGroup>
              </FormBox> */}
              <FormBox label="Description">
                <TextField
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter"
                  multiline
                  rows={4}
                  fullWidth
                />
              </FormBox>
              {/* <FormBox label="PaySlip Details">
                <Button variant="contained" color="primary">
                  Click here to manage payslip
                </Button>
              </FormBox> */}
              <FormBox label="End of notice period date">
                <DatePicker
                  value={noticePeriodEndDate}
                  onChange={setNoticePeriodEndDate}
                />
              </FormBox>
              <DuoButtonGroup
                primaryButtonText="Save"
                primaryButtonListener={() => {
                  exitEmployee();
                }}
                secondaryButtonText="Cancel"
              />
            </Grid>
          </Grid>
        )}
      </StandardAppContainerRounded>
    </StandardContainer>
  );
};

export default EndEmployeeContract;

import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import { Form } from "devextreme-react/data-grid";
import FormBox from "../../styled/generic/FormBox";
import {
  AlarmOutlined,
  EmailOutlined,
  FaceOutlined,
  PhoneOutlined,
  PlaceOutlined,
} from "@mui/icons-material";
import dayjs from "dayjs";

const ListComponent = ({ icon, label, value }) => {
  return (
    <Box display="flex" alignItems="center">
      {icon}
      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
          {label}
        </Typography>
        <Typography variant="subtitle2">{value || "-"}</Typography>
      </Box>
    </Box>
  );
};

const GridComponent = ({ label, value }) => {
  return (
    <Box>
      <Typography variant="subtitle2">{label}</Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
        {value || "-"}
      </Typography>
    </Box>
  );
};

const MyAccount = ({ finrel }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <StandardAppContainerRounded fullHeight={true}>
          <Stack direction="column" spacing={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Avatar
                sx={{ width: 80, height: 80 }}
                src={finrel?.mainProfile?.parent?.displayPicture?.url}
              />
              <Typography variant="h6" sx={{ mt: 2 }}>
                {finrel?.mainProfile?.parent?.displayName}
              </Typography>
              <Typography variant="subtitle1">
                {finrel?.firstPartyContact?.department?.displayName +
                  " " +
                  finrel?.firstPartyContact?.designation?.name}
              </Typography>
            </Box>
            <FormBox
              label="Personal Details"
              labelTypographyVariant="subtitle1"
              fontWeight="bold"
            >
              <Stack direction="column" spacing={1}>
                <ListComponent
                  icon={<EmailOutlined />}
                  label="Email"
                  value={finrel?.mainProfile?.parent?.email}
                />
                <ListComponent
                  icon={<PhoneOutlined />}
                  label="Phone"
                  value={finrel?.mainProfile?.parent?.phone}
                />
              </Stack>
            </FormBox>
            <FormBox
              label="Professional Details"
              labelTypographyVariant="subtitle1"
              fontWeight="bold"
            >
              <Stack direction="column" spacing={1}>
                <ListComponent
                  icon={<FaceOutlined />}
                  label={finrel?.firstPartyContact?.department?.displayName}
                  value="Department"
                />
                <ListComponent
                  icon={<PlaceOutlined />}
                  label={finrel?.firstPartyContact?.workLocation?.name}
                  value="Work Location"
                />
                <ListComponent
                  icon={<AlarmOutlined />}
                  label={
                    dayjs(finrel?.defaultWorkSchedule?.shiftStart).format(
                      "hh:mm A"
                    ) +
                    " to " +
                    dayjs(finrel?.defaultWorkSchedule?.shiftEnd).format(
                      "hh:mm A"
                    )
                  }
                  value="Work Schedule"
                />
              </Stack>
            </FormBox>
          </Stack>
        </StandardAppContainerRounded>
      </Grid>
      <Grid item xs={9}>
        <StandardAppContainerRounded fullHeight={true}>
          <FormBox label="Contract Details">
            <Grid container>
              <Grid item xs={3}>
                <GridComponent
                  label="Contract start date"
                  value={dayjs(finrel?.payrollContract?.contractStart).format(
                    "DD MMM YYYY"
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <GridComponent
                  label="Paid leaves"
                  value={finrel?.payrollContract?.paidLeaves}
                />
              </Grid>
              <Grid item xs={3}>
                <GridComponent
                  label="Pay cycle"
                  value={finrel?.payrollContract?.payCycle}
                />
              </Grid>
              <Grid item xs={3}>
                <GridComponent
                  label="Annual CTC"
                  value={"₹" + finrel?.payrollContract?.annualCTC}
                />
              </Grid>
            </Grid>
          </FormBox>
          <Grid container spacing={2} sx={{ mt: 5 }}>
            <Grid item xs={4}>
              <Typography variant="h5">Salary Components</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5">Monthly Amount</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5">Annual Amount</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Earnings</Typography>
            </Grid>
            {finrel?.payrollContract?.salaryDetails
              ?.filter((item) => {
                return item?.componentRef?.type === "Earning";
              })
              .map((item) => (
                <>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">
                      {item?.componentRef?.name} (
                      {item?.componentRef?.calculationType})
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">
                      ₹ {item?.amount || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">
                      ₹ {item?.amount * 12 || 0}
                    </Typography>
                  </Grid>
                </>
              ))}
            <Grid item xs={12}>
              <Typography variant="h6">Deductions</Typography>
            </Grid>
            {finrel?.payrollContract?.salaryDetails
              ?.filter((item) => {
                return (
                  item?.componentRef?.type === "PreTaxDeduction" ||
                  item?.componentRef?.type === "PostTaxDeduction"
                );
              })
              .map((item) => (
                <>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">
                      {item?.componentRef?.name} (
                      {item?.componentRef?.calculationType})
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">
                      ₹ {item?.amount || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">
                      ₹ {item?.amount * 12 || 0}
                    </Typography>
                  </Grid>
                </>
              ))}
            <Grid item xs={12}>
              <Typography variant="h6">Reimbursements</Typography>
            </Grid>
            {finrel?.payrollContract?.salaryDetails
              ?.filter((item) => {
                return item?.componentRef?.type === "Reimbursement";
              })
              .map((item) => (
                <>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">
                      {item?.componentRef?.name} (
                      {item?.componentRef?.calculationType})
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">
                      ₹ {item?.amount || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">
                      ₹ {item?.amount * 12 || 0}
                    </Typography>
                  </Grid>
                </>
              ))}
            <Grid item xs={12}>
              <Typography variant="h6">Taxes</Typography>
            </Grid>
            {finrel?.payrollContract?.salaryDetails
              ?.filter((item) => {
                return item?.componentRef?.type === "Tax";
              })
              .map((item) => (
                <>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">
                      {item?.componentRef?.name} (
                      {item?.componentRef?.calculationType})
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">
                      ₹ {item?.amount || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">
                      ₹ {item?.amount * 12 || 0}
                    </Typography>
                  </Grid>
                </>
              ))}
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <Typography variant="h6">
                ₹{" "}
                {finrel?.payrollContract?.salaryDetails?.reduce(
                  (accumulator, currentValue) => {
                    return accumulator + currentValue?.amount || 0;
                  },
                  0
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6">
                ₹{" "}
                {finrel?.payrollContract?.salaryDetails?.reduce(
                  (accumulator, currentValue) => {
                    return accumulator + (currentValue?.amount || 0) * 12;
                  },
                  0
                )}
              </Typography>
            </Grid>
          </Grid>
        </StandardAppContainerRounded>
      </Grid>
    </Grid>
  );
};

export default MyAccount;

import React, { useEffect, useState } from "react";
import Autocomplete from "./Autocomplete";
import { useDispatch } from "react-redux";
import { searchEmployees } from "../../contacts/api.call";
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import TextField from "./TextField";
import { useDebounce } from "react-use";
import Api from "../../../helpers/Api";

const EmployeeSearcher = ({ defaultValue, onSelect, organizationId }) => {
  const dispatch = useDispatch();

  const [text, setText] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const getEmployees = async () => {
    try {
      const { data } = await Api.post(`/employee/get`, {
        organizationId: organizationId,
      });

      if (data) {
        setSearchResults(data);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  useEffect(() => {
    if (defaultValue) {
      setSearchResults([defaultValue]);
    }
  }, [defaultValue]);

  return (
    <Autocomplete
      options={searchResults}
      defaultValue={defaultValue}
      onChange={(evt, val) => onSelect(val)}
      getOptionLabel={(option) => option?.mainProfile?.parent?.displayName}
      renderOption={(props, option, { selected }) => {
        return (
          <ListItemButton {...props} selected={selected}>
            <ListItemAvatar>
              <Avatar
                src={option?.mainProfile?.parent?.displayPicture?.url}
              />
            </ListItemAvatar>
            <ListItemText
              primary={option?.mainProfile?.parent?.displayName || "Untitled"}
              secondary={
                option?.isOnPlatform === false
                  ? "Not on platform"
                  : option?.mainProfile?.parent?.email
              }
            />
          </ListItemButton>
        );
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            defaultValue ? defaultValue?.displayName : `Search Employee`
          }
          value={text}
          onChange={(e) => setText(e.target.value)}
          variant="outlined"
        />
      )}
    />
  );
};

export default EmployeeSearcher;

import React, { useEffect } from "react";
import DrawerContainer from "../../../../styled/generic/DrawerContainer";
import FormBox from "../../../../styled/generic/FormBox";
import TextField from "../../../../styled/generic/TextField";
import { useState } from "react";
import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SpaceBetween from "../../../../styled/generic/SpaceBetween";
import TableContainer from "../../../../styled/generic/TableContainer";
import dayjs from "dayjs";
import { EditOutlined } from "@mui/icons-material";
import DateText from "../../../../styled/generic/DateText";
import Api from "../../../../../helpers/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useDispatch } from "react-redux";
import TableCheckboxCell from "../../../../styled/generic/TableCheckboxCell";
import StandardAppContainer from "../../../../styled/generic/StandardAppContainer";
import StandardAppContainerRounded from "../../../../styled/generic/StandardAppContainerRounded";
import DuoButtonGroup from "../../../../styled/generic/DuoButtonGroup";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Select from "../../../../styled/generic/Select";

const ConfigureWorkSchedule = () => {
  const { organizationId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [title, setTitle] = useState("");
  const [days, setDays] = useState([]);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [breakTimeFrom, setBreakTimeFrom] = useState();
  const [breakTimeTo, setBreakTimeTo] = useState();
  const [holidays, setHolidays] = useState([]);
  const [selectedHolidays, setSelectedHolidays] = useState([]);
  const [holidaysLoading, setHolidaysLoading] = useState(false);
  const [workSchedule, setWorkSchedule] = useState();
  const [workScheduleId, setWorkScheduleId] = useState(null);
  const [attendanceCodes, setAttendanceCodes] = useState([]);
  const [selectedAttendanceCode, setSelectedAttendanceCode] = useState("");
  let dayArr = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const newWorkscheduleId = searchParams.get("scheduleId");
    if (newWorkscheduleId) {
      setWorkScheduleId(newWorkscheduleId);
    }
  }, [searchParams]);

  const createWorkSchedule = async () => {
    try {
      const { data } = await Api.post(`/workschedule/employee/create`, {
        workScheduleName: title,
        daysOfWeek: days,
        shiftStart: startTime,
        shiftEnd: endTime,
        shiftBreakStart: breakTimeFrom,
        shiftBreakEnd: breakTimeTo,
        organization: organizationId,
        holidays: selectedHolidays.map((holiday) => holiday._id),
        defaultAttendanceCode: selectedAttendanceCode,
      });
      if (data) {
        history.push(`/hr/settings/${organizationId}?view=WorkSchedule`);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create work schedule",
        },
      });
    }
  };

  const getWorkSchedule = async () => {
    try {
      const { data } = await Api.post(`/workschedule/employee/get-by-id`, {
        _id: workScheduleId,
      });
      if (data && data?._id) {
        setWorkSchedule(data);
        setTitle(data?.workScheduleName);
        setDays(data?.daysOfWeek);
        setStartTime(dayjs(data?.shiftStart));
        setEndTime(dayjs(data?.shiftEnd));
        setBreakTimeFrom(dayjs(data?.shiftBreakStart));
        setBreakTimeTo(dayjs(data?.shiftBreakEnd));
        setSelectedHolidays(data?.holidays || []);
        setSelectedAttendanceCode(data?.defaultAttendanceCode || "");
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get work schedule",
        },
      });
    }
  };

  const getHolidays = async () => {
    try {
      setHolidaysLoading(true);
      const { data } = await Api.post(`/holidays/get`, {
        organization: organizationId,
      });
      if (data) {
        setHolidays(data);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get holidays",
        },
      });
    } finally {
      setHolidaysLoading(false);
    }
  };

  const getAttendanceCodes = async () => {
    try {
      const { data } = await Api.post(`/attendance-codes/get`, {
        organizationId: organizationId,
      });
      if (data) {
        setAttendanceCodes(data);
        if(data?.length > 0) {
          let absentCode = data.find((code) => code?.category === "category");
          setSelectedAttendanceCode(absentCode?._id || "");
        }
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get attendance codes",
        },
      });
    }
  };

  const updateWorkSchedule = async () => {
    try {
      const { data } = await Api.post(`/workschedule/employee/update`, {
        _id: workScheduleId,
        updateBody: {
          workScheduleName: title,
          daysOfWeek: days,
          shiftStart: startTime,
          shiftEnd: endTime,
          shiftBreakStart: breakTimeFrom,
          shiftBreakEnd: breakTimeTo,
          holidays: selectedHolidays.map((holiday) => holiday._id),
          defaultAttendanceCode: selectedAttendanceCode,
        },
      });
      if (data) {
        history.push(`/hr/settings/${organizationId}?view=WorkSchedule`);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to update work schedule",
        },
      });
    }
  };

  useEffect(() => {
    getHolidays();
    getAttendanceCodes();
  }, []);

  useEffect(() => {
    if (workScheduleId) {
      getWorkSchedule();
    }
  }, [workScheduleId]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StandardAppContainerRounded>
        <FormBox label="Work Schedule Name">
          <TextField
            placeholder="Enter"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
          />
        </FormBox>

        <FormBox label="Working Days">
          <ToggleButtonGroup
            value={days}
            onChange={(e, newDays) => setDays(newDays)}
            color="primary"
            fullWidth
          >
            {dayArr.map((day, i) => (
              <ToggleButton value={day} key={i + day}>
                <Typography fontSize="0.9rem">{day}</Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </FormBox>

        <SpaceBetween
          left={
            <FormBox label="Shift Start Time">
              <TimePicker
                value={startTime}
                onChange={(val) => setStartTime(val)}
                slotProps={{
                  textField: {
                    style: { marginRight: "5px" },
                    fullWidth: true,
                  },
                }}
              />
            </FormBox>
          }
          right={
            <FormBox label="Shift End Time">
              <TimePicker
                value={endTime}
                onChange={(val) => setEndTime(val)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
              />
            </FormBox>
          }
        />

        <SpaceBetween
          left={
            <FormBox label="Break Time From">
              <TimePicker
                value={breakTimeFrom}
                onChange={(val) => setBreakTimeFrom(val)}
                slotProps={{
                  textField: {
                    style: { marginRight: "5px" },
                    fullWidth: true,
                  },
                }}
              />
            </FormBox>
          }
          right={
            <FormBox label="Break Time To">
              <TimePicker
                value={breakTimeTo}
                onChange={(val) => setBreakTimeTo(val)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
              />
            </FormBox>
          }
        />

        <SpaceBetween
          left={
            <FormBox label="Default Work Entry Code">
              <Select
                fullWidth
                value={selectedAttendanceCode}
                onChange={(e) => setSelectedAttendanceCode(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">Select</MenuItem>
                {attendanceCodes?.map((attendanceCode) => (
                  <MenuItem value={attendanceCode?._id}>
                    {attendanceCode?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormBox>
          }
        />

        <FormBox label="Holidays">
          <TableContainer
            columns={[
              "HOLIDAY NAME",
              "FROM DATE",
              "TO DATE",
              "TOTAL DAYS",
              "TYPE",
            ]}
            data={holidays}
            loading={holidaysLoading}
            enableSelectAll={true}
            selectedRows={selectedHolidays}
            setSelectedRows={setSelectedHolidays}
          >
            {holidays.map((holiday) => (
              <tr>
                <td>
                  <TableCheckboxCell
                    currentRow={holiday}
                    selectedRows={selectedHolidays}
                    setSelectedRows={setSelectedHolidays}
                  />
                </td>
                <td>{holiday.name}</td>
                <td>
                  <DateText
                    date={
                      holiday?.mode === "multiple"
                        ? holiday?.fromDate
                        : holiday?.date
                    }
                  />
                </td>
                <td>
                  <DateText
                    label={
                      holiday?.mode === "multiple"
                        ? holiday?.toDate
                        : holiday?.date
                    }
                  />
                </td>
                <td>
                  {holiday?.mode === "single"
                    ? 1
                    : dayjs(holiday?.toDate).diff(holiday?.fromDate, "day")}
                </td>
                <td>{holiday?.type}</td>
              </tr>
            ))}
          </TableContainer>
        </FormBox>
        <DuoButtonGroup
          primaryButtonText={workSchedule?._id ? "Update" : "Create"}
          primaryButtonListener={() => {
            if (workSchedule?._id) {
              updateWorkSchedule();
            } else {
              createWorkSchedule();
            }
          }}
          secondaryButtonText="Cancel"
          secondaryButtonListener={() => history.goBack()}
        />
      </StandardAppContainerRounded>
    </LocalizationProvider>
  );
};

export default ConfigureWorkSchedule;

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Earning from "./editsalarycomponentforms/Earning";
import Reimbursement from "./editsalarycomponentforms/Reimbursement";
import Deduction from "./editsalarycomponentforms/Deduction";
import Taxes from "./editsalarycomponentforms/Taxes";
import Api from "../../../../../helpers/Api";
import { useDispatch } from "react-redux";
import CalculatorBox from "./CalculatorBox";

const EditSalaryComponent = () => {
  const [form, setForm] = useState("Earning");
  const [componentId, setComponentId] = useState(null);
  const { organizationId } = useParams();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const newForm = searchParams.get("type");
    if (newForm) {
      setForm(newForm);
    }
    const newComponentId = searchParams.get("componentId");
    if (newComponentId) {
      setComponentId(newComponentId);
    }
  }, [searchParams]);

  const [salaryStructures, setSalaryStructures] = useState([]);
  const [selectedSalaryStructure, setSelectedSalaryStructure] = useState(null);

  const getSalaryStructures = async () => {
    try {
      const { data } = await Api.post(`/salary-structure/get`, {
        organizationId: organizationId,
      });
      setSalaryStructures(data);
      if (data.length > 0) {
        setSelectedSalaryStructure(data[0]); // TODO: Handle salary structure selection properly
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get earning",
        },
      });
    }
  };

  useEffect(() => {
    getSalaryStructures();
  }, []);

  return (
    <Box>
      {selectedSalaryStructure?._id && (
        <Box>
          {form === "Earning" && (
            <Earning
              componentId={componentId}
              salaryStructure={selectedSalaryStructure}
            />
          )}
          {form === "Reimbursement" && (
            <Reimbursement
              componentId={componentId}
              salaryStructure={selectedSalaryStructure}
            />
          )}
          {(form === "PreTaxDeduction" || form === "PostTaxDeduction") && (
            <Deduction
              componentId={componentId}
              form={form}
              salaryStructure={selectedSalaryStructure}
            />
          )}
          {form === "Taxes" && (
            <Taxes
              componentId={componentId}
              salaryStructure={selectedSalaryStructure}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default EditSalaryComponent;

import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import LabelWithSingleActionButton from "../../../styled/generic/LabelWithSingleActionButton";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import StandardAppContainerRounded from "../../../styled/generic/StandardAppContainerRounded";
import SearchField from "../../../styled/generic/SearchField";
import { Add, DeleteOutline, EditOutlined } from "@mui/icons-material";
import HorizBox from "../../../styled/generic/HorizBox";
import TableContainer from "../../../styled/generic/TableContainer";
import { useDispatch } from "react-redux";
import Api from "../../../../helpers/Api";

const LeaveType = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { organizationId } = useParams();

  const [leaves, setLeaves] = useState([]);
  const [leavesLoading, setLeavesLoading] = useState(false);

  const createLeave = () => {
    history.push(`/hr/settings/${organizationId}?view=ConfigureLeave`);
  };

  const updateLeave = (leaveId) => {
    history.push(
      `/hr/settings/${organizationId}?view=ConfigureLeave&leaveId=${leaveId}`
    );
  };

  const getLeaves = async () => {
    try {
      setLeavesLoading(true);
      const { data } = await Api.post("/leave/getAll", {
        organization: organizationId,
      });

      if (data) {
        setLeaves(data);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get leaves",
        },
      });
    } finally {
      setLeavesLoading(false);
    }
  };

  useEffect(() => {
    getLeaves();
  }, []);

  return (
    <Box>
      <LabelWithSingleActionButton
        label="Leave Type"
        actionButtonIcon={<Add />}
        actionButtonText="Create"
        actionButtonVariant="contained"
        actionButtonListener={() => {
          createLeave();
        }}
      />
      <StandardAppContainerRounded>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <SearchField size="small" />
        </Box>
        <TableContainer
          columns={["LEAVE NAME", "LEAVE CODE", "TYPE", "ACTIONS"]}
          data={leaves}
          loading={leavesLoading}
          boldTextEnabled={true}
        >
          {leaves?.map((item) => (
            <tr>
              <td>{item?.name}</td>
              <td>{item?.code}</td>
              <td>{item?.type}</td>
              <td>
                <HorizBox>
                  <IconButton
                    onClick={() => {
                      updateLeave(item?._id);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                </HorizBox>
              </td>
            </tr>
          ))}
        </TableContainer>
      </StandardAppContainerRounded>
    </Box>
  );
};

export default LeaveType;

import React from "react";
import TextField from "./TextField";
import { InputAdornment } from "@mui/material";

const TextFieldWithIcon = ({ startIcon, endIcon, readOnly = false, ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        startAdornment: startIcon ? (
          <InputAdornment position="start">{startIcon}</InputAdornment>
        ) : null,
        endAdornment: endIcon ? (
          <InputAdornment position="end">{endIcon}</InputAdornment>
        ) : null,
        readOnly: readOnly,
      }}
    />
  );
};

export default TextFieldWithIcon;

import React, { useEffect, useState } from "react";
import StandardAppContainer from "../../styled/generic/StandardAppContainer";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import { Box, Grid, Typography } from "@mui/material";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import FormBox from "../../styled/generic/FormBox";
import TableContainer from "../../styled/generic/TableContainer";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import dayjs from "dayjs";
import SingleUserWithCaptionTableCell from "../../styled/generic/SingleUserWithCaptionTableCell";
import StatusHandler from "../../styled/generic/StatusHandler";
import { parsedInt } from "../../../helpers/textHelper";

const Overview = () => {
  const { organizationId } = useParams();
  const dispatch = useDispatch();
  const [stats, setStats] = useState({});
  const [attendanceDetails, setAttendanceDetails] = useState([]);
  const [salaries, setSalaries] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [loading, setLoading] = useState(false);

  const getOverviewData = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post(`/employee/getOverviewData`, {
        organizationId,
      });
      setStats(data?.stats);
      setAttendanceDetails(data?.todayTimesheets);
      setSalaries(data?.paychecks);
      setHolidays(data?.holidays);
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch payrolls",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOverviewData();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Grid container spacing={2} my={4}>
          <Grid item xs={4}>
            <Box
              sx={{
                bgcolor: "#ffffff",
                p: 2,
                borderRadius: "5px",
                boxShadow: "1px 1px 3px rgba(0,0,0,0.10)",
              }}
            >
              <BoxSpaceBetween>
                <Typography variant="h4">
                  {stats?.totalEmployees || 0}
                </Typography>
                <div
                  style={{
                    backgroundColor: "#E6EAF5",
                    padding: "6px",
                    borderRadius: "50%",
                    width: "32px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0 22 20"
                    fill="none"
                  >
                    <path
                      d="M15 19V17C15 15.9391 14.5786 14.9217 13.8284 14.1716C13.0783 13.4214 12.0609 13 11 13H5C3.93913 13 2.92172 13.4214 2.17157 14.1716C1.42143 14.9217 1 15.9391 1 17V19"
                      stroke="#0043FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 9C10.2091 9 12 7.20914 12 5C12 2.79086 10.2091 1 8 1C5.79086 1 4 2.79086 4 5C4 7.20914 5.79086 9 8 9Z"
                      stroke="#0043FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21 18.9999V16.9999C20.9993 16.1136 20.7044 15.2527 20.1614 14.5522C19.6184 13.8517 18.8581 13.3515 18 13.1299M15 1.12988C15.8604 1.35018 16.623 1.85058 17.1676 2.55219C17.7122 3.2538 18.0078 4.11671 18.0078 5.00488C18.0078 5.89305 17.7122 6.75596 17.1676 7.45757C16.623 8.15918 15.8604 8.65958 15 8.87988"
                      stroke="#0043FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </BoxSpaceBetween>
              <Typography variant="subtitle1">Total Workforce</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                bgcolor: "#ffffff",
                p: 2,
                borderRadius: "5px",
                boxShadow: "1px 1px 3px rgba(0,0,0,0.10)",
              }}
            >
              <BoxSpaceBetween>
                <Typography variant="h4">{stats?.absentToday || 0}</Typography>
                <div
                  style={{
                    backgroundColor: "#E6EAF5",
                    padding: "6px",
                    borderRadius: "50%",
                    width: "32px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M17.5 15.1663H19.25V18.4563L22.0967 20.1013L21.2217 21.618L17.5 19.4713V15.1663ZM21.1517 10.8847C20.1017 7.94467 17.3017 5.83301 14 5.83301C10.85 5.83301 8.16669 7.75801 7.00002 10.4997C3.78002 10.4997 1.16669 13.113 1.16669 16.333C1.16669 19.553 3.78002 22.1663 7.00002 22.1663H11.2934C12.6117 24.9197 15.4117 26.833 18.6667 26.833C23.1817 26.833 26.8334 23.1813 26.8334 18.6663C26.8334 15.0263 24.4534 11.9463 21.1517 10.8847ZM7.00002 19.833C5.06335 19.833 3.50002 18.2697 3.50002 16.333C3.50002 14.3963 5.06335 12.833 7.00002 12.833C7.43169 12.833 7.85169 12.9147 8.23669 13.0547C8.46186 11.6889 9.16501 10.4475 10.2207 9.55214C11.2763 8.65676 12.6158 8.16561 14 8.16634C15.9017 8.16634 17.5817 9.08801 18.6667 10.4997C14.14 10.4997 10.5 14.163 10.5 18.6663C10.5 19.063 10.535 19.448 10.5934 19.833H7.00002ZM18.6667 24.4997C15.4467 24.4997 12.8334 21.8863 12.8334 18.6663C12.8334 15.4463 15.4467 12.833 18.6667 12.833C21.8867 12.833 24.5 15.4463 24.5 18.6663C24.5 21.8863 21.8867 24.4997 18.6667 24.4997Z"
                      fill="#0043FF"
                    />
                  </svg>
                </div>
              </BoxSpaceBetween>
              <Typography variant="subtitle1">Absent</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                bgcolor: "#ffffff",
                p: 2,
                borderRadius: "5px",
                boxShadow: "1px 1px 3px rgba(0,0,0,0.10)",
              }}
            >
              <BoxSpaceBetween>
                <Typography variant="h4">{stats?.onLeave || 0}</Typography>
                <div
                  style={{
                    backgroundColor: "#E6EAF5",
                    padding: "6px",
                    borderRadius: "50%",
                    width: "32px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_8_59885)">
                      <path
                        d="M16.6699 8.89648C17.1777 9.13737 17.6367 9.4401 18.0469 9.80469C18.457 10.1693 18.8086 10.5827 19.1016 11.0449C19.3945 11.5072 19.6159 12.002 19.7656 12.5293C19.9154 13.0566 19.9935 13.6035 20 14.1699C20 14.9707 19.847 15.7259 19.541 16.4355C19.235 17.1452 18.8184 17.7637 18.291 18.291C17.7637 18.8184 17.1452 19.235 16.4355 19.541C15.7259 19.847 14.9707 20 14.1699 20C13.6035 20 13.0566 19.9219 12.5293 19.7656C12.002 19.6094 11.5104 19.388 11.0547 19.1016C10.599 18.8151 10.1855 18.4668 9.81445 18.0566C9.44336 17.6465 9.13737 17.1842 8.89648 16.6699H0V1.66992H3.33008V0H5V1.66992H11.6699V0H13.3301V1.66992H16.6699V8.89648ZM1.66992 5H15V3.33008H1.66992V5ZM8.38867 15C8.34961 14.7266 8.33008 14.4499 8.33008 14.1699C8.33008 13.3626 8.48307 12.6042 8.78906 11.8945C9.09505 11.1849 9.51172 10.5664 10.0391 10.0391C10.5664 9.51172 11.1849 9.09505 11.8945 8.78906C12.6042 8.48307 13.3626 8.33008 14.1699 8.33008C14.4499 8.33008 14.7266 8.34961 15 8.38867V6.66992H1.66992V15H8.38867ZM14.1699 18.3301C14.7428 18.3301 15.2799 18.2227 15.7812 18.0078C16.2826 17.793 16.7253 17.4935 17.1094 17.1094C17.4935 16.7253 17.7897 16.2858 17.998 15.791C18.2064 15.2962 18.3171 14.7559 18.3301 14.1699C18.3301 13.597 18.2227 13.0566 18.0078 12.5488C17.793 12.041 17.4967 11.5983 17.1191 11.2207C16.7415 10.8431 16.2988 10.5469 15.791 10.332C15.2832 10.1172 14.7428 10.0065 14.1699 10C13.5905 10 13.0501 10.1074 12.5488 10.3223C12.0475 10.5371 11.6048 10.8366 11.2207 11.2207C10.8366 11.6048 10.5404 12.0475 10.332 12.5488C10.1237 13.0501 10.013 13.5905 10 14.1699C10 14.7493 10.1074 15.2897 10.3223 15.791C10.5371 16.2923 10.8366 16.7318 11.2207 17.1094C11.6048 17.487 12.0475 17.7832 12.5488 17.998C13.0501 18.2129 13.5905 18.3236 14.1699 18.3301ZM15 13.3301H16.6699V15H13.3301V11.6699H15V13.3301Z"
                        fill="#0043FF"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_8_59885">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </BoxSpaceBetween>
              <Typography variant="subtitle1">On Leave</Typography>
            </Box>
          </Grid>
        </Grid>
        {/* Employees & Attendance Details */}
        <Box
          sx={{
            bgcolor: "#ffffff",
            p: 2,
            borderRadius: "16px",
            boxShadow: "1px 1px 3px rgba(0,0,0,0.04)",
            mt: 4,
            border: "1px solid rgba(0, 0, 0, 0.10)",
          }}
        >
          <FormBox label="Employees & Attendance Details">
            <TableContainer
              columns={["NAME", "PUNCH IN", "PUNCH OUT", "ATTENDANCE STATUS"]}
              data={attendanceDetails}
              loading={loading}
              containerHeight="200px"
            >
              {attendanceDetails?.map((item) => (
                <tr>
                  <td>{item?.worker?.mainProfile?.parent?.displayName}</td>
                  <td>
                    {item?.lastClockInTime
                      ? dayjs(item?.lastClockInTime).format("hh:mm A")
                      : "-"}
                  </td>
                  <td>
                    {item?.lastClockOutTime
                      ? dayjs(item?.lastClockOutTime).format("hh:mm A")
                      : "-"}
                  </td>
                  <td>
                    <div
                      style={{
                        backgroundColor: item?.attendanceCode?.color,
                        fontSize: "0.9rem",
                        height: "100%",
                        padding: "5px",
                        textAlign: "center",
                        width: "60%",
                        borderRadius: "4px",
                      }}
                    >
                      {item?.attendanceCode?.name}
                    </div>
                  </td>
                </tr>
              ))}
            </TableContainer>
          </FormBox>
        </Box>

        {/* Employee Salaries */}
        <Box
          sx={{
            bgcolor: "#ffffff",
            p: 2,
            borderRadius: "16px",
            boxShadow: "1px 1px 3px rgba(0,0,0,0.04)",
            mt: 4,
            border: "1px solid rgba(0, 0, 0, 0.10)",
          }}
        >
          <FormBox label="Employees Salaries">
            <TableContainer
              columns={[
                "WORKER",
                "PAYABLE DAYS",
                "PAY CYCLE",
                "PAYMENT STATUS",
              ]}
              data={salaries}
              loading={loading}
              hideHeader={true}
              containerHeight="200px"
            >
              {salaries?.map((item) => (
                <tr>
                  <td>
                    <SingleUserWithCaptionTableCell
                      label={item?.worker?.mainProfile?.parent?.displayName}
                      caption={
                        item?.worker?.firstPartyContact?.designation?.name
                      }
                      url={
                        item?.worker?.mainProfile?.parent?.displayPicture?.url
                      }
                    />
                  </td>
                  <td>₹ {parsedInt(item?.totalSalary) || "0"}</td>
                  <td>Monthly</td>
                  <td>
                    <StatusHandler
                      label={item?.status}
                      success={["Paid"]}
                      error={["Unpaid"]}
                    />
                  </td>
                </tr>
              ))}
            </TableContainer>
          </FormBox>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box
          sx={{
            bgcolor: "#ffffff",
            p: 2,
            borderRadius: "16px",
            boxShadow: "1px 1px 3px rgba(0,0,0,0.04)",
            mt: 4,
            border: "1px solid rgba(0, 0, 0, 0.10)",
          }}
        >
          <FormBox label="Upcoming Leaves">
            {/* STATS */}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box
                  sx={{
                    bgcolor: "#ffffff",
                    p: 2,
                    borderRadius: "5px",
                    boxShadow: "1px 1px 3px rgba(0,0,0,0.10)",
                  }}
                >
                  <Typography variant="h5">{holidays?.length || 0}</Typography>
                  <Typography variant="subtitle1">Total Holidays</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    bgcolor: "#ffffff",
                    p: 2,
                    borderRadius: "5px",
                    boxShadow: "1px 1px 3px rgba(0,0,0,0.10)",
                  }}
                >
                  <Typography variant="h5">
                    {holidays?.filter((item) => {
                      if (item?.mode === "single")
                        return dayjs(item?.date).isAfter(dayjs());
                      else return dayjs(item?.fromDate).isAfter(dayjs());
                    }).length || 0}
                  </Typography>
                  <Typography variant="subtitle1">Upcoming Holidays</Typography>
                </Box>
              </Grid>
            </Grid>

            <TableContainer
              columns={["LEAVE NAME", "DATE"]}
              data={holidays}
              loading={loading}
              containerHeight="200px"
              containerStyleProps={{ marginTop: "16px" }}
            >
              {holidays?.map((item) => (
                <tr>
                  <td>{item?.name}</td>
                  <td>
                    {item?.mode === "single"
                      ? dayjs(item?.date).format("DD-MM-YYYY")
                      : dayjs(item?.fromDate).format("DD-MM-YYYY") +
                        "-" +
                        dayjs(item?.toDate).format("DD-MM-YYYY")}
                  </td>
                </tr>
              ))}
            </TableContainer>
          </FormBox>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Overview;

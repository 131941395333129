import { Checkbox } from "@mui/material";
import React from "react";

const TableCheckboxCell = ({ currentRow, selectedRows, setSelectedRows }) => {
  return (
    <Checkbox
      checked={selectedRows.some((row) => row._id === currentRow._id)}
      onChange={(evt, checked) => {
        if (checked) {
          setSelectedRows([...selectedRows, currentRow]);
        } else {
          setSelectedRows(selectedRows.filter((row) => row._id !== currentRow._id));
        }
      }}
    />
  );
};

export default TableCheckboxCell;

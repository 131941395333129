import { Box } from "@mui/material";
import React from "react";

const StandardAppContainer = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{
        p: 2,
        my: 2,
        backgroundColor: "#FFFFFF",
        border: "1px solid #F3F3F3",
        boxShadow: "0px 0px 80px 0px rgba(171, 190, 209, 0.15)",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default StandardAppContainer;

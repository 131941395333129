import React, { useEffect, useState } from "react";
import StandardContainer from "../styled/generic/StandardContainer";
import StandardContainerRounded from "../styled/generic/StandardContainerRounded";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import FluidBox from "../styled/generic/FluidBox";
import Image from "../styled/generic/Image";
import FormBox from "../styled/generic/FormBox";
import HorizBox from "../styled/generic/HorizBox";
import TextField from "../styled/generic/TextField";
import Button from "../styled/generic/Button";
import { NotificationsNoneOutlined } from "@mui/icons-material";
import MyWorkInvitationsDrawer from "./MyWorkInvitationsDrawer";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../helpers/Api";
import ChooseOrgView from "./ChooseOrgView";
import { Box, CircularProgress, Typography } from "@mui/material";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import dayjs from "dayjs";
import MyWorkHome from "./MyWorkHome";

const MyWork = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [showInvitationsDrawer, setShowInvitationsDrawer] = useState(false);
  const [finrel, setFinrel] = useState();
  const [loading, setLoading] = useState(false);

  const getMyWork = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/employee/mywork", {
        userProfile: user?.profile,
      });
      setFinrel(data);
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMyWork();
  }, []);

  const exitEmployee = async () => {
    try {
      const { data } = await Api.post("/employee/exit", {
        finRelId: finrel._id,
        noticePeriodExists: false,
        exitDate: new Date(),
        endOfNoticePeriod: new Date(),
      });

      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Employee exited successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to exit employee",
        },
      });
    }
  };

  return (
    <StandardContainer
      appBarTitle="My Work"
      showAppBar={true}
      appBarActions={
        <HorizBox>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<NotificationsNoneOutlined />}
            onClick={() => setShowInvitationsDrawer(true)}
          >
            View Invitations
          </Button>
          {finrel?._id && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => exitEmployee()}
            >
              Exit Organization
            </Button>
          )}
        </HorizBox>
      }
    >
      <Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {!finrel ? (
              <ChooseOrgView getMyWork={getMyWork} />
            ) : (
              <MyWorkHome finrel={finrel} />
            )}
          </Box>
        )}
      </Box>
      <MyWorkInvitationsDrawer
        open={showInvitationsDrawer}
        setOpen={setShowInvitationsDrawer}
      />
    </StandardContainer>
  );
};

export default MyWork;

import React from "react";
import Button from "./Button";
import { TuneOutlined } from "@mui/icons-material";

const FilterButton = ({ ...props }) => {
  return (
    <Button
      {...props}
      variant="outlined"
      color="primary"
      startIcon={<TuneOutlined />}
      sx={{ ml: 2 }}
    >
      Filter
    </Button>
  );
};

export default FilterButton;

import { Box, IconButton, MenuItem, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import StandardAppContainer from "../../../styled/generic/StandardAppContainer";
import StandardAppContainerRounded from "../../../styled/generic/StandardAppContainerRounded";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import LabelWithSingleActionButton from "../../../styled/generic/LabelWithSingleActionButton";
import { Add, DeleteOutline, Edit, EditOutlined } from "@mui/icons-material";
import Dialog from "../../../styled/generic/Dialog";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import FormBox from "../../../styled/generic/FormBox";
import TextField from "../../../styled/generic/TextField";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import Api from "../../../../helpers/Api";
import TableContainer from "../../../styled/generic/TableContainer";
import Select from "../../../styled/generic/Select";

const Designations = () => {
  const [showCreateDesignationDialog, setShowCreateDesignationDialog] =
    useState(false);

  const [designationName, setDesignationName] = useState("");
  const [designationCode, setDesignationCode] = useState("");
  const [designationDescription, setDesignationDescription] = useState("");
  const [designationsLoading, setDesignationsLoading] = useState(false);
  const [designations, setDesignations] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [designationDepartment, setDesignationDepartment] = useState("");

  useEffect(() => {
    if (showCreateDesignationDialog) {
      setDesignationName(selectedDesignation?.name);
      setDesignationDepartment(selectedDesignation?.department?._id);
    } else {
      setDesignationName("");
      setSelectedDesignation(null);
      setDesignationDepartment("");
    }
  }, [showCreateDesignationDialog]);

  const { user } = useSelector((state) => state.auth);
  const { organizationId } = useParams();
  const dispatch = useDispatch();

  const createDesignation = async () => {
    try {
      setDesignationsLoading(true);
      const { data } = await Api.post(`/designation/create`, {
        name: designationName,
        organization: organizationId,
        author: user?._id,
        department: designationDepartment,
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Designation created successfully",
          },
        });
        setDesignations((prev) => [...prev, data]);
        setShowCreateDesignationDialog(false);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setDesignationsLoading(false);
    }
  };

  const getDesignations = async () => {
    try {
      const { data } = await Api.post(`/designation/get`, {
        organization: organizationId,
      });
      if (data) {
        setDesignations(data);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch designations",
        },
      });
    }
  };

  const updateDesignation = async () => {
    try {
      const { data } = await Api.post(`/designation/update`, {
        _id: selectedDesignation?._id,
        updateBody: {
          name: designationName,
          code: designationCode,
          description: designationDescription,
          department: designationDepartment,
        },
      });
      if (data) {
        setDesignations((prev) => {
          let temp = [...prev];
          temp[temp.findIndex((item) => item._id === selectedDesignation._id)] =
            data;
          return temp;
        });
        setShowCreateDesignationDialog(false);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Designation updated successfully",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const deleteDesignation = async (id) => {
    try {
      const { data } = await Api.post(`/designation/delete`, {
        _id: id,
      });

      setDesignations((prev) => {
        let temp = [...prev];
        temp.splice(
          temp.findIndex((item) => item._id === id),
          1
        );
        return temp;
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Designation deleted successfully",
        },
      });
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const getDepartments = async () => {
    try {
      const { data } = await Api.post(`/department/get`, {
        organization: organizationId,
      });
      if (data) {
        setDepartments(data);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch departments",
        },
      });
    }
  };

  useEffect(() => {
    getDesignations();
    getDepartments();
  }, []);

  return (
    <Box>
      <LabelWithSingleActionButton
        label="Designations"
        actionButtonIcon={<Add />}
        actionButtonText="Designations"
        actionButtonVariant="contained"
        actionButtonListener={() => setShowCreateDesignationDialog(true)}
      />
      <StandardAppContainerRounded>
        <TableContainer
          columns={["DESIGNATION NAME", "DEPARTMENT", "ACTIONS"]}
          data={designations}
          loading={designationsLoading}
        >
          {designations?.map((item) => (
            <tr>
              <td>{item?.name}</td>
              <td>{item?.department?.displayName}</td>
              <td>
                <Stack direction="row" spacing={1}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSelectedDesignation(item);
                      setShowCreateDesignationDialog(true);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      deleteDesignation(item?._id);
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </Stack>
              </td>
            </tr>
          ))}
        </TableContainer>
      </StandardAppContainerRounded>
      <Dialog
        open={showCreateDesignationDialog}
        setOpen={setShowCreateDesignationDialog}
        title="Create Designations"
      >
        <Box>
          <SpaceBetween
            left={
              <FormBox label="Designation Name">
                <TextField
                  placeholder="Enter"
                  value={designationName}
                  onChange={(e) => setDesignationName(e.target.value)}
                  fullWidth
                />
              </FormBox>
            }
            right={
              <FormBox label="Choose Department">
                <Select
                  placeholder="Enter"
                  value={designationDepartment}
                  onChange={(e) => setDesignationDepartment(e.target.value)}
                  fullWidth
                  displayEmpty
                >
                  <MenuItem value="">Select</MenuItem>
                  {departments?.map((item) => (
                    <MenuItem value={item?._id}>{item?.displayName}</MenuItem>
                  ))}
                </Select>
              </FormBox>
            }
          />
          <DuoButtonGroup
            primaryButtonText={selectedDesignation ? "Update" : "Create"}
            primaryButtonListener={() => {
              if (selectedDesignation) {
                updateDesignation();
              } else {
                createDesignation();
              }
            }}
            secondaryButtonText="Cancel"
            secondaryButtonListener={() =>
              setShowCreateDesignationDialog(false)
            }
            disablePrimaryButton={
              !designationDepartment || designationDepartment === ""
            }
          />
        </Box>
      </Dialog>
    </Box>
  );
};

export default Designations;

import React, { useEffect, useState } from "react";
import StandardContainer from "../../../../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../../../../styled/generic/StandardAppContainerRounded";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../../../helpers/Api";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import FormBox from "../../../../styled/generic/FormBox";
import UploadButton from "../../../../styled/generic/UploadButton";
import DocumentUploader from "../../../../styled/generic/DocumentUploader";
import CustomFileUploadButton from "../../../../file/Uploader/CustomFileUploadButton";
import { getAllFiles } from "../../../../community/api.call";
import DuoButtonGroup from "../../../../styled/generic/DuoButtonGroup";
import Template from "./Template";

const PaySlipTemplateEdit = () => {
  const { templateId } = useParams();
  const { fileDictionary, createdFileIds } = useSelector((state) => state.file);
  const dispatch = useDispatch();
  const history = useHistory();
  const [template, setTemplate] = useState();
  const [showOrganizationAddress, setShowOrganizationAddress] = useState(false);
  const [showDoj, setShowDoj] = useState(false);
  const [showPan, setShowPan] = useState(false);
  const [showWorkLocation, setShowWorkLocation] = useState(false);
  const [showDepartment, setShowDepartment] = useState(false);
  const [showDesignation, setShowDesignation] = useState(false);

  const getPayslipTemplate = async () => {
    try {
      const { data } = await Api.post("/payslip/template/get-by-id", {
        templateId,
      });
      setTemplate(data);
      setShowOrganizationAddress(data?.showOrganizationAddress);
      setShowDoj(data?.showDoj);
      setShowPan(data?.showPan);
      setShowWorkLocation(data?.showWorkLocation);
      setShowDepartment(data?.showDepartment);
      setShowDesignation(data?.showDesignation);
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get payslip template",
        },
      });
    }
  };

  const updatePayslipTemplate = async () => {
    try {
      const { data } = await Api.post("/payslip/template/update", {
        _id: templateId,
        updateBody: {
          showOrganizationAddress,
          showDoj,
          showPan,
          showWorkLocation,
          showDepartment,
          showDesignation,
        },
      });
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Payslip template updated successfully",
        },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to update payslip template",
        },
      });
    }
  };

  useEffect(() => {
    getPayslipTemplate();
  }, []);

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle="Edit Payslip Template"
      display="flex"
    >
      <Box flex="0.2" sx={{ p: 4 }}>
        <FormBox label="Organization Logo">
          {/* <CustomFileUploadButton
            showComponent={
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "60px",
                  padding: "16px 20px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "12px",
                  flexShrink: 0,
                  borderRadius: "10px",
                  border: "1px dashed #ABBED1",
                  background: "#F5F7FA",
                }}
              >
                <img
                  src="/icons/app/document-upload.svg"
                  style={{ width: "28px", height: "28px" }}
                  alt="upload icon"
                />
              </Box>
            }
            parentType={"PaySlipTemplate"}
            parentId={templateId}
            fileNum={1}
            givenMaxSize={26214400}
            closeFunCall={() => {
              if (createdFileIds?.length > 0) {
                setLogo(fileDictionary[createdFileIds[0]]);
              }

              dispatch({ type: "FileUploadReset" });
              dispatch({
                type: "FileReset",
              });
              console.log("On close upload dialog called");
            }}
          /> */}
          <FormControlLabel
            control={
              <Checkbox
                checked={showOrganizationAddress}
                onChange={(evt, checked) => setShowOrganizationAddress(checked)}
              />
            }
            label="Show Organization Address"
          />
        </FormBox>
        <FormBox label="Pay slip Preference">
          <FormControlLabel
            control={
              <Checkbox
                checked={showDoj}
                onChange={(evt, checked) => setShowDoj(checked)}
              />
            }
            label="Show Date of Joining"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showPan}
                onChange={(evt, checked) => setShowPan(checked)}
              />
            }
            label="Show PAN"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showWorkLocation}
                onChange={(evt, checked) => setShowWorkLocation(checked)}
              />
            }
            label="Show Work Location"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showDepartment}
                onChange={(evt, checked) => setShowDepartment(checked)}
              />
            }
            label="Show Department"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showDesignation}
                onChange={(evt, checked) => setShowDesignation(checked)}
              />
            }
            label="Show Designation"
          />
        </FormBox>
        <DuoButtonGroup
          primaryButtonText="Save"
          primaryButtonListener={() => updatePayslipTemplate()}
          secondaryButtonText="Cancel"
        />
      </Box>
      <Box flex="0.8">
        <StandardAppContainerRounded>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Edit Payslip Template
          </Typography>
          <Box>
            <Template
              showOrganizationAddress={showOrganizationAddress}
              showDoj={showDoj}
              showPan={showPan}
              showWorkLocation={showWorkLocation}
              showDepartment={showDepartment}
              showDesignation={showDesignation}
            />
          </Box>
        </StandardAppContainerRounded>
      </Box>
    </StandardContainer>
  );
};

export default PaySlipTemplateEdit;

import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../../../../styled/generic/StandardAppContainerRounded";
import FormBox from "../../../../styled/generic/FormBox";
import dayjs from "dayjs";
import FormHeaderWithActions from "../../../../styled/generic/FormHeaderWithActions";
import Button from "../../../../styled/generic/Button";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const GridComponent = ({ label, value }) => {
  return (
    <Box>
      <Typography variant="subtitle2">{label}</Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
        {value || "-"}
      </Typography>
    </Box>
  );
};

const ContractDetails = ({ finrel }) => {
  const history = useHistory();
  return (
    <StandardAppContainerRounded fullHeight={true}>
      <FormHeaderWithActions
        label="Contract Details"
        actions={
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              history.push(
                `/hr/employees/employee/${finrel?._id}/end-contract`
              );
            }}
          >
            End Contract
          </Button>
        }
      >
        <Grid container>
          <Grid item xs={3}>
            <GridComponent
              label="Contract start date"
              value={dayjs(finrel?.payrollContract?.contractStart).format(
                "DD MMM YYYY"
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <GridComponent
              label="Paid leaves"
              value={finrel?.payrollContract?.paidLeaves}
            />
          </Grid>
          <Grid item xs={3}>
            <GridComponent
              label="Pay cycle"
              value={finrel?.payrollContract?.payCycle}
            />
          </Grid>
          <Grid item xs={3}>
            <GridComponent
              label="Annual CTC"
              value={"₹" + finrel?.payrollContract?.annualCTC}
            />
          </Grid>
        </Grid>
      </FormHeaderWithActions>
      <Grid container spacing={2} sx={{ mt: 5 }}>
        <Grid item xs={4}>
          <Typography variant="h5">Salary Components</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h5">Monthly Amount</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h5">Annual Amount</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Earnings</Typography>
        </Grid>
        {finrel?.payrollContract?.salaryDetails
          ?.filter((item) => {
            return item?.componentRef?.type === "Earning";
          })
          .map((item) => (
            <>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  {item?.componentRef?.name} (
                  {item?.componentRef?.calculationType})
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  ₹ {item?.amount || 0}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  ₹ {item?.amount * 12 || 0}
                </Typography>
              </Grid>
            </>
          ))}
        <Grid item xs={12}>
          <Typography variant="h6">Deductions</Typography>
        </Grid>
        {finrel?.payrollContract?.salaryDetails
          ?.filter((item) => {
            return (
              item?.componentRef?.type === "PreTaxDeduction" ||
              item?.componentRef?.type === "PostTaxDeduction"
            );
          })
          .map((item) => (
            <>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  {item?.componentRef?.name} (
                  {item?.componentRef?.calculationType})
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  ₹ {item?.amount || 0}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  ₹ {item?.amount * 12 || 0}
                </Typography>
              </Grid>
            </>
          ))}
        <Grid item xs={12}>
          <Typography variant="h6">Reimbursements</Typography>
        </Grid>
        {finrel?.payrollContract?.salaryDetails
          ?.filter((item) => {
            return item?.componentRef?.type === "Reimbursement";
          })
          .map((item) => (
            <>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  {item?.componentRef?.name} (
                  {item?.componentRef?.calculationType})
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  ₹ {item?.amount || 0}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  ₹ {item?.amount * 12 || 0}
                </Typography>
              </Grid>
            </>
          ))}
        <Grid item xs={12}>
          <Typography variant="h6">Taxes</Typography>
        </Grid>
        {finrel?.payrollContract?.salaryDetails
          ?.filter((item) => {
            return item?.componentRef?.type === "Tax";
          })
          .map((item) => (
            <>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  {item?.componentRef?.name} (
                  {item?.componentRef?.calculationType})
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  ₹ {item?.amount || 0}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">
                  ₹ {item?.amount * 12 || 0}
                </Typography>
              </Grid>
            </>
          ))}
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Typography variant="h6">
            ₹{" "}
            {finrel?.payrollContract?.salaryDetails?.reduce(
              (accumulator, currentValue) => {
                return accumulator + currentValue?.amount || 0;
              },
              0
            )}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">
            ₹{" "}
            {finrel?.payrollContract?.salaryDetails?.reduce(
              (accumulator, currentValue) => {
                return accumulator + (currentValue?.amount || 0) * 12;
              },
              0
            )}
          </Typography>
        </Grid>
      </Grid>
    </StandardAppContainerRounded>
  );
};

export default ContractDetails;

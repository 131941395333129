import React, { useEffect, useState } from "react";
import StandardContainer from "../../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import TableContainer from "../../styled/generic/TableContainer";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import HorizBox from "../../styled/generic/HorizBox";
import TextField from "../../styled/generic/TextField";
import Button from "../../styled/generic/Button";
import { Add } from "@mui/icons-material";
import {
  useTableAStyles,
  useTableBStyles,
  useTableCStyles,
  useTableEStyles,
} from "../../../utils/style";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import Select from "../../styled/generic/Select";
import Api from "../../../helpers/Api";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import DatePicker from "../../styled/generic/DatePicker";
import UserSearcher from "../../styled/generic/UserSearcher";

const PAY_CYCLE_OPTIONS = [
  "Daily",
  "Weekly",
  "Bi-weekly",
  "Semi-monthly",
  "Monthly",
  "Bi-Monthly",
  "Quarterly",
  "Semi-annually",
  "Annually",
];
const PAID_LEAVES = ["15FY", "20FY", "25FY", "30FY", "35FY", "40FY"];

const BulkAddEmployees = () => {
  const mainColumns = [
    "SL. NO",
    "EMPLOYEE NAME",
    "EMAIL",
    "PHONE NO",
    "DEPARTMENT",
    "DESIGNATION",
    "LOCATION",
    "DOJ",
    "CONTRACT START",
    "MANAGER",
    "MENTOR",
    "WORK SCHEDULE",
    "PAY CYCLE",
    "PAID LEAVES",
    "ANNUAL CTC",
  ];
  const [columns, setColumns] = useState(mainColumns);

  const [employees, setEmployees] = useState([]);
  const [salaryStructures, setSalaryStructures] = useState([]);
  const [salaryStructure, setSalaryStructure] = useState("");
  const [salaryComponents, setSalaryComponents] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [workSchedules, setWorkSchedules] = useState([]);
  const [leaveOptions, setLeaveOptions] = useState([]);

  const { organizationId } = useParams();
  const dispatch = useDispatch();

  const classes = useTableEStyles();

  const addEmployee = () => {
    setEmployees([
      ...employees,
      {
        organizationId: organizationId,
        name: "",
        email: "",
        phone: "",
        department: "",
        designation: "",
        workLocation: "",
        doj: dayjs(),
        contractStart: dayjs(),
        manager: null,
        mentor: null,
        workSchedule: "",
        payCycle: PAY_CYCLE_OPTIONS[0],
        paidLeaves: PAID_LEAVES[0],
        annualCtc: "",
        leaves: leaveOptions?.map((item) => ({
          leaveId: item?._id,
          leaveName: item?.name,
          leaveBalance: 0,
        })),
      },
    ]);
  };

  const updateEmployee = (index, key, value) => {
    let newEmployees = [...employees];
    newEmployees[index][key] = value;
    setEmployees(newEmployees);
  };

  const getHROptions = async () => {
    try {
      const { data } = await Api.post("/employee/hr-options", {
        organizationId: organizationId,
      });

      if (data) {
        setSalaryStructures(data?.salaryStructures);
        setDepartments(data?.departments);
        setDesignations(data?.designations);
        setLocations(data?.locations);
        setWorkSchedules(data?.workSchedules);
        setLeaveOptions(data?.leaves);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const getSalaryComponentsByStructure = async () => {
    if (!salaryStructure?._id) return;
    try {
      const { data } = await Api.post(`/salary-component/get-by-structure`, {
        structureId: salaryStructure,
      });
      if (data) {
        setSalaryComponents(data);
        setColumns([...mainColumns, ...data?.map((item) => item?.name)]);
        let tempEmployes = [...employees];

        for (let i = 0; i < tempEmployes.length; i++) {
          for (let j = 0; j < data.length; j++) {
            tempEmployes[i][data[j].name] = "";
          }
        }
        setEmployees(tempEmployes);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch salary components",
        },
      });
    }
  };

  useEffect(() => {
    getHROptions();
    addEmployee();
  }, []);

  useEffect(() => {
    getSalaryComponentsByStructure();
  }, [salaryStructure]);

  const createEmployees = async () => {
    if (!salaryStructure?._id)
      return dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Please select a salary structure",
        },
      });

    if (employees?.length === 0)
      return dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Please add atleast one employee",
        },
      });

    try {
      let processedEmployees = [];
      let processedSalaryDetails = [];

      for (let i = 0; i < employees.length; i++) {
        let employee = employees[i];
        let salaryDetails = [];
        for (let j = 0; j < salaryComponents.length; j++) {
          let component = salaryComponents[j];
          salaryDetails.push({
            salaryComponentId: component?._id,
            value: employee[component?.name],
          });
        }
        processedSalaryDetails.push(salaryDetails);
      }

      for (let i = 0; i < employees.length; i++) {
        let employee = employees[i];
        let processedEmployee = {
          displayName: employee?.name,
          email: employee?.email,
          phone: employee?.phone,
          designation: employee?.designation?._id,
          department: employee?.department?._id,
          doj: employee?.doj,
          workLocation: employee?.workLocation?._id,
          manager: employee?.manager?._id,
          mentor: employee?.mentor?._id,
          startDate: employee?.contractStart,
          workSchedule: employee?.workSchedule,
          payCycle: employee?.payCycle,
          paidLeaves: employee?.paidLeaves,
          annualCTC: employee?.annualCtc,
          salaryDetails: processedSalaryDetails,
        };
        processedEmployees.push(processedEmployee);
      }

      const { data } = await Api.post("/employee/bulk-add", {
        employees: processedEmployees,
        salaryStructureId: salaryStructure?._id,
        organizationId: organizationId,
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Employees created successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create employees",
        },
      });
    }
  };

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle="Bulk Add Employees"
      appBarActions={
        <Button
          variant="contained"
          color="primary"
          onClick={() => createEmployees()}
        >
          Save
        </Button>
      }
    >
      <StandardAppContainerRounded>
        <BoxSpaceBetween>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => addEmployee()}
          >
            Add Row
          </Button>
          <Select
            value={salaryStructure}
            onChange={(evt) => setSalaryStructure(evt.target.value)}
            displayEmpty
            sx={{ minWidth: "150px" }}
          >
            <MenuItem value="">Select</MenuItem>
            {salaryStructures?.map((structure) => (
              <MenuItem value={structure}>{structure?.name}</MenuItem>
            ))}
          </Select>
        </BoxSpaceBetween>
        <Box sx={{ mt: 3, maxWidth: "100%", overflowX: "auto" }}>
          <table className={classes.tableSty}>
            <tr
              style={{
                height: "40px",
                borderBottom: "none",
              }}
            >
              {columns?.map((col) => (
                <th>{col}</th>
              ))}
            </tr>
            {employees?.map((employee, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>
                  <TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    size="small"
                    value={employee?.name}
                    onChange={(evt) => {
                      updateEmployee(index, "name", evt.target.value);
                    }}
                    placeholder="Employee Name"
                  />
                </td>
                <td>
                  <TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    size="small"
                    value={employee?.email}
                    onChange={(evt) => {
                      updateEmployee(index, "email", evt.target.value);
                    }}
                    placeholder="Email"
                  />
                </td>
                <td>
                  <TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    size="small"
                    value={employee?.phone}
                    onChange={(evt) => {
                      updateEmployee(index, "phone", evt.target.value);
                    }}
                    placeholder="Phone No"
                  />
                </td>
                <td>
                  <Select
                    value={employee?.department}
                    onChange={(evt) => {
                      updateEmployee(index, "department", evt.target.value);
                    }}
                    displayEmpty
                    sx={{ minWidth: "150px" }}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {departments?.map((department) => (
                      <MenuItem value={department}>
                        {department?.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
                <td>
                  <Select
                    value={employee?.designation}
                    onChange={(evt) => {
                      updateEmployee(index, "designation", evt.target.value);
                    }}
                    displayEmpty
                    sx={{ minWidth: "150px" }}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {designations?.map((designation) => (
                      <MenuItem value={designation}>
                        {designation?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
                <td>
                  <Select
                    value={employee?.workLocation}
                    onChange={(evt) => {
                      updateEmployee(index, "workLocation", evt.target.value);
                    }}
                    displayEmpty
                    sx={{ minWidth: "150px" }}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {locations?.map((location) => (
                      <MenuItem value={location}>{location?.name}</MenuItem>
                    ))}
                  </Select>
                </td>
                <td>
                  <DatePicker
                    value={employee?.doj}
                    onChange={(date) => {
                      updateEmployee(index, "doj", date);
                    }}
                  />
                </td>
                <td>
                  <DatePicker
                    value={employee?.contractStart}
                    onChange={(date) => {
                      updateEmployee(index, "contractStart", date);
                    }}
                  />
                </td>
                <td>
                  <UserSearcher
                    defaultValue={employee?.manager}
                    onSelect={(val) => {
                      updateEmployee(index, "manager", val);
                    }}
                  />
                </td>
                <td>
                  <UserSearcher
                    defaultValue={employee?.mentor}
                    onSelect={(val) => {
                      updateEmployee(index, "mentor", val);
                    }}
                  />
                </td>
                <td>
                  <Select
                    value={employee?.workSchedule}
                    onChange={(evt) => {
                      updateEmployee(index, "workSchedule", evt.target.value);
                    }}
                    displayEmpty
                    sx={{ minWidth: "150px" }}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {workSchedules?.map((schedule) => (
                      <MenuItem value={schedule}>
                        {schedule?.workScheduleName || "Untitled"}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
                <td>
                  <Select
                    fullWidth
                    value={employee?.payCycle}
                    onChange={(evt) => {
                      updateEmployee(index, "payCycle", evt.target.value);
                    }}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {PAY_CYCLE_OPTIONS?.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </td>
                <td>
                  <Select
                    fullWidth
                    value={employee?.paidLeaves}
                    onChange={(evt) => {
                      updateEmployee(index, "paidLeaves", evt.target.value);
                    }}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {PAID_LEAVES?.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </td>
                <td>
                  <TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    size="small"
                    value={employee?.annualCtc}
                    onChange={(evt) => {
                      updateEmployee(index, "annualCtc", evt.target.value);
                    }}
                    placeholder="Annual CTC"
                    type="number"
                  />
                </td>
                {salaryComponents?.map((item) => (
                  <td>
                    <TextField
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      size="small"
                      value={employee[item?.name]}
                      onChange={(evt) => {
                        updateEmployee(index, item?.name, evt.target.value);
                      }}
                      placeholder={item?.name}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </table>
        </Box>
      </StandardAppContainerRounded>
    </StandardContainer>
  );
};

export default BulkAddEmployees;

import React, { useEffect, useState } from "react";
import Dialog from "../../styled/generic/Dialog";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helpers/Api";
import FormBox from "../../styled/generic/FormBox";
import Select from "../../styled/generic/Select";
import { Box, MenuItem, Typography } from "@mui/material";
import { Form } from "devextreme-react/data-grid";
import SpaceBetween from "../../styled/generic/SpaceBetween";
import DatePicker from "../../styled/generic/DatePicker";
import TextField from "../../styled/generic/TextField";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import dayjs from "dayjs";

const AssignLeaveDialog = ({
  open,
  setOpen,
  organizationId,
  onLeaveAssigned,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [employees, setEmployees] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [leaveBalance, setLeaveBalance] = useState([]);
  const [employee, setEmployee] = useState("");
  const [leave, setLeave] = useState("");
  const [startDate, setStartDate] = useState(dayjs().add(1, "day"));
  const [endDate, setEndDate] = useState(dayjs().add(2, "day"));
  const [description, setDescription] = useState("");

  const [prevLeaveBalance, setPrevLeaveBalance] = useState(0);
  const [newLeaveBalance, setNewLeaveBalance] = useState(0);
  const [maxLeaves, setMaxLeaves] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [disableMessage, setDisableMessage] = useState();
  const [loading, setLoading] = useState(false);

  const getAssignLeaveOptions = async () => {
    try {
      const { data } = await Api.post("/leave/assign/options", {
        organizationId: organizationId,
      });

      if (data) {
        setEmployees(data.employees);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const getLeaveBalanceOfEmployee = async () => {
    try {
      const { data } = await Api.post("/leave/balance", {
        finRelId: employee,
      });

      if (data) {
        let _leaveBalanceAry = data;
        setLeaveBalance(_leaveBalanceAry);

        let _leaves = [];
        for (let i = 0; i < _leaveBalanceAry?.length; i++) {
          let _leaveBalance = _leaveBalanceAry[i];
          let _leave = _leaveBalance?.leave;
          _leaves.push(_leave);
        }
        setLeaves(_leaves);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const assignLeave = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/leave/assign", {
        finRelId: employee,
        leaveType: leave,
        startDate: startDate?.toDate(),
        endDate: endDate?.toDate(),
        description: description,
        organizationId: organizationId,
        approvedBy: user?._id,
      });

      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Leave assigned successfully",
          },
        });
        setOpen(false);
        onLeaveAssigned();
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLeaveSelection = () => {
    if (!employee || employee === "") {
      setDisableMessage("Please select employee");
      return;
    }

    if (!leave || leave === "") {
      setDisableMessage("Please select leave type");
      return;
    }

    if (!startDate) {
      setDisableMessage("Please select start date");
      return;
    }

    if (!endDate) {
      setDisableMessage("Please select end date");
      return;
    }

    // Get the leave balance of the selected leave
    let _leave = leaveBalance.find((lb) => lb?.leave?._id === leave);

    // No need to validate if the leave is not paid
    if (_leave?.leave?.type === "UNPAID") {
      setDisableMessage("");
      return;
    }

    let _leaveBalance = _leave?.balance || 0;
    let _maxLeaves = _leave?.leave?.policy?.totalPaidLeavesInYear || 0;

    // Calculate day difference between start and end date
    let dayDiff = dayjs(endDate).diff(dayjs(startDate), "day") + 1;

    let newLeaveBalance = _leaveBalance - dayDiff;

    // Check if the new leave balance exceeds the maximum leaves
    // if (newLeaveBalance < 0) {
    //   setDisableMessage("Not enough leave balance to assign this leave");
    //   return;
    // }

    setDisableMessage("");

    setPrevLeaveBalance(_leaveBalance);
    setNewLeaveBalance(newLeaveBalance);
    setMaxLeaves(_maxLeaves);
    setTotalDays(dayDiff);
  };

  useEffect(() => {
    getAssignLeaveOptions();
  }, [organizationId]);

  useEffect(() => {
    if (employee) {
      getLeaveBalanceOfEmployee();
    }
  }, [employee]);

  useEffect(() => {
    handleLeaveSelection();
  }, [employee, leave, startDate, endDate]);

  return (
    <Dialog open={open} setOpen={setOpen} title="Assign Leave">
      <SpaceBetween
        left={
          <FormBox label="Employee">
            <Select
              value={employee}
              onChange={(e) => setEmployee(e.target.value)}
              fullWidth
              variant="outlined"
              displayEmpty
            >
              <MenuItem value="">Select</MenuItem>
              {employees.map((employee) => (
                <MenuItem value={employee?._id}>
                  {employee?.mainProfile?.parent?.displayName || "Untitled"}
                </MenuItem>
              ))}
            </Select>
          </FormBox>
        }
        right={
          <FormBox label="Leave Type">
            <Select
              fullWidth
              variant="outlined"
              value={leave}
              onChange={(e) => setLeave(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">Select</MenuItem>
              {leaves.map((leave) => (
                <MenuItem value={leave?._id}>{leave?.name}</MenuItem>
              ))}
            </Select>
          </FormBox>
        }
      />
      <SpaceBetween
        left={
          <FormBox label="Start Date">
            <DatePicker
              value={startDate}
              onChange={(date) => setStartDate(date)}
              fullWidth
              variant="outlined"
            />
          </FormBox>
        }
        right={
          <FormBox label="End Date">
            <DatePicker
              value={endDate}
              onChange={(date) => setEndDate(date)}
              fullWidth
              variant="outlined"
            />
          </FormBox>
        }
      />
      <FormBox label="Description">
        <TextField
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          placeholder="Enter"
        />
      </FormBox>
      <Box display="flex" alignItems="end" flexDirection="column">
        {disableMessage ? (
          <Typography variant="subtitle2">{disableMessage}</Typography>
        ) : (
          <Box>
            {
              // CHeck if selected leave is unpaid
              leaveBalance.find((lb) => String(lb?.leave?._id) == String(leave))
                ?.leave?.type === "PAID" && (
                <>
                  <Typography variant="subtitle2">
                    Current Leave Balance: {prevLeaveBalance} / {maxLeaves}
                  </Typography>
                  <Typography variant="subtitle2">
                    Total Days: {totalDays}
                  </Typography>
                  <Typography variant="subtitle2">
                    New Leave Balance: {newLeaveBalance} / {maxLeaves}
                  </Typography>
                </>
              )
            }
          </Box>
        )}
      </Box>
      <DuoButtonGroup
        primaryButtonText="Create"
        primaryButtonListener={() => {
          assignLeave();
        }}
        loadingPrimary={loading}
        disablePrimaryButton={
          disableMessage && disableMessage !== "" ? true : false
        }
        secondaryButtonText="Cancel"
        secondaryButtonListener={() => setOpen(false)}
      />
    </Dialog>
  );
};

export default AssignLeaveDialog;

import { Box } from "@mui/material";
import React from "react";

const StandardChipContainer = ({ children }) => {
  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      {children}
    </Box>
  );
};

export default StandardChipContainer;

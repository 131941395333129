import { Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LabelWithSingleActionButton from "../../../styled/generic/LabelWithSingleActionButton";
import { Add } from "@material-ui/icons";
import StandardAppContainerRounded from "../../../styled/generic/StandardAppContainerRounded";
import useLocationForm from "../../../styled/hooks/useLocationForm";
import Dialog from "../../../styled/generic/Dialog";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import FormBox from "../../../styled/generic/FormBox";
import useFullLocationForm from "../../../styled/hooks/useFullLocationForm";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../../helpers/Api";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import {
  DeleteOutline,
  EditOutlined,
  SupervisorAccountOutlined,
} from "@mui/icons-material";

const Locations = () => {
  const [showCreateLocationDialog, setShowCreateLocationDialog] =
    useState(false);

  const [locations, setLocations] = useState([]);

  const {
    locationForm,
    location,
    setLocation,
    city,
    setCity,
    state,
    setState,
    address1,
    setAddress1,
    address2,
    setAddress2,
    landMark,
    setLandMark,
    zip,
    setZip,
  } = useFullLocationForm({});

  const { user } = useSelector((state) => state.auth);
  const { organizationId } = useParams();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [defaultLocation, setDefaultLocation] = useState(null);

  const handleSetAsDefault = async (locationId) => {
    try {
      const { data } = await Api.post("/location/update", {
        _id: locationId,
        updateBody: { isDefault: true },
        organization: organizationId
      });
      if (data) {
        setDefaultLocation(locationId);
        setLocations(prevLocations => 
          prevLocations.map(loc => ({
            ...loc,
            isDefault: loc._id === locationId
          }))
        );
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Default location updated successfully",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to set default location",
        },
      });
    }
  };

  const createLocation = async () => {
    try {
      const { data } = await Api.post(`/location/create`, {
        location: location,
        name: name,
        isDefault: locations.length === 0,
        organization: organizationId,
        author: user?._id,
      });
      // if (data) {
      //   dispatch({
      //     type: "AddApiAlert",
      //     payload: {
      //       success: true,
      //       message: "Location created successfully",
      //     },
      //   });
      //   setLocations((prev) => [...prev, data]);
      //   setShowCreateLocationDialog(false);
      // }
      if (data?._id) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Location created successfully",
          },
        });
        setLocations(prevLocations => [...prevLocations, data]);
        if (data.isDefault) {
          setDefaultLocation(data._id);
        }
        setShowCreateLocationDialog(false);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const getLocations = async () => {
    try {
      const { data } = await Api.post(`/location/get`, {
        organization: organizationId,
      });
      if (data) {
        setLocations(data);
        const defaultLoc = data.find(loc => loc.isDefault);
        setDefaultLocation(defaultLoc ? defaultLoc._id : null);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch locations",
        },
      });
    }
  };

  const updateLocation = async () => {
    try {
      const { data } = await Api.post(`/location/update`, {
        _id: selectedLocation?._id,
        updateBody: {
          location: location,
          name: name,
          isDefault: defaultLocation === selectedLocation._id,
        },
      });
      if (data) {
        setLocations(prevLocations => 
          prevLocations.map(loc => 
            loc._id === data._id ? data : loc
          )
        );
        setShowCreateLocationDialog(false);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Location updated successfully",
          },
        });
        // If the updated location is now the default, update the defaultLocation state
        if (data.isDefault) {
          setDefaultLocation(data._id);
        }
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const deleteLocation = async (id) => {
    try {
      const { data } = await Api.post(`/location/delete`, {
        _id: id,
      });

      setLocations((prev) => {
        let temp = [...prev];
        temp.splice(
          temp.findIndex((item) => item._id === id),
          1
        );
        return temp;
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Location deleted successfully",
        },
      });
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    if (showCreateLocationDialog) {
      setLocation(selectedLocation?.location || {});
      setName(selectedLocation?.name || "");
      setCity(selectedLocation?.location?.city || "");
      setState(selectedLocation?.location?.state || "");
      setAddress1(selectedLocation?.location?.address_line1 || "");
      setAddress2(selectedLocation?.location?.address_line2 || "");
      setLandMark(selectedLocation?.location?.landmark || "");
      setZip(selectedLocation?.location?.zip || "");
    } else {
      setLocation({});
      setName("");
      setCity("");
      setState("");
      setAddress1("");
      setAddress2("");
      setLandMark("");
      setZip("");
    }
  }, [showCreateLocationDialog]);

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <Box>
      <LabelWithSingleActionButton
        label="Locations"
        actionButtonIcon={<Add />}
        actionButtonText="Locations"
        actionButtonVariant="contained"
        actionButtonListener={() => setShowCreateLocationDialog(true)}
      />
      <StandardAppContainerRounded>
        <Grid container spacing={2}>
          {locations?.map((item, index) => (
            <Grid item xs={4}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  boxShadow: "2px 3px 2px rgba(0,0,0,0.10)",
                  p: 2,
                }}
              >
                <BoxSpaceBetween>
                  <Typography variant="h6">{item?.name}</Typography>
                  <Box display="flex" alignItems="center">
                  {defaultLocation !== item._id ? (
              <IconButton
                size="small"
                onClick={() => handleSetAsDefault(item._id)}
              >
                <SupervisorAccountOutlined />
              </IconButton>
            ) : (
              <Typography variant="caption" sx={{ color: 'primary.main' }}>
                Default
              </Typography>
            )}
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedLocation(item);
                        setShowCreateLocationDialog(true);
                      }}
                    >
                      <EditOutlined />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => deleteLocation(item?._id)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Box>
                </BoxSpaceBetween>
                <Typography variant="body1" gutterBottom>
                  {item?.location?.address_line1 ||
                    item?.location?.address_line2 ||
                    "-"}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {item?.location?.state && item?.location?.state + " ,"}{" "}
                  {item?.location?.city && item?.location?.city}
                  {item?.location?.zip && "(" + item?.location?.zip + ")"}
                </Typography>
                <Box sx={{ mt: 2 }} display="flex" alignItems="center">
                  <SupervisorAccountOutlined />{" "}
                  <Typography sx={{ ml: 2 }} variant="body1">
                    0 Employees
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </StandardAppContainerRounded>
      <Dialog
        open={showCreateLocationDialog}
        setOpen={setShowCreateLocationDialog}
        title="Create Location"
      >
        <Box>
          <SpaceBetween
            left={
              <FormBox label="Location Name">
                <TextField
                  fullWidth
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormBox>
            }
          />
          <Box>{locationForm}</Box>
          <DuoButtonGroup
            primaryButtonText={selectedLocation ? "Update" : "Create"}
            primaryButtonListener={() => {
              if (selectedLocation) {
                updateLocation();
              } else {
                createLocation();
              }
            }}
            secondaryButtonText="Cancel"
            secondaryButtonListener={() => setShowCreateLocationDialog(false)}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

export default Locations;

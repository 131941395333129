import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import TableContainer from "../../styled/generic/TableContainer";
import Api from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CreateUsersTimesheet from "./drawers/CreateUsersTimesheet";
import Select from "../../styled/generic/Select";
import { Box, Button, Checkbox, MenuItem, Typography } from "@mui/material";
import ProjectPickerDropdown from "../../styled/generic/ProjectPickerDropdown";
import moment from "moment";
import StatusChip from "../../styled/generic/StatusChip";
import ListingTableActionCell from "../../styled/generic/ListingTableActionCell";
import CreateIndividualTimesheet from "./drawers/CreateIndividualTimesheet";
import PalFormDrawer from "../../AddMember/newPalFormDrawer";
import CreateGroupTimesheet from "./drawers/CreateGroupTimesheet";
import TableCheckboxCell from "../../styled/generic/TableCheckboxCell";
import TableActionsContainer from "../../styled/generic/TableActionsContainer";
import ChangeStatusDialog from "./dialogs/ChangeStatusDialog";

const TimesheetTab = ({
  showUsersTimesheetDrawer,
  setShowUsersTimesheetDrawer,
  showPersonalTimesheetCreationDrawer,
  setShowPersonalTimesheetCreationDrawer,
  showGroupTimesheetDrawer,
  setShowGroupTimesheetDrawer,
}) => {
  const [timesheets, setTimesheets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scope, setScope] = useState("project");
  const [searchQuery, setSearchQuery] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("All");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [orderBy, setOrderBy] = useState("date");
  const [orderIn, setOrderIn] = useState("asc");
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { teamId } = useParams();

  const getTimesheets = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/timesheet/scoped", {
        scope: scope,
        organizationTeamId: teamId,
        projectId: selectedProject?._id,
        userId: user?._id,
        searchRegex: searchQuery ? searchQuery : "",
        approvalStatus: approvalStatus,
        fromDate: fromDate,
        toDate: toDate,
        orderBy: orderBy, // Date/ Total/ Num Workers
        orderIn: orderIn, // ASC/ DESC
      });

      setTimesheets(data || []);
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch timesheets",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTimesheets();
  }, [scope, selectedProject]);

  const reloadTimesheets = () => {
    getTimesheets();
  };

  const [showCreatePalDrawer, setShowCreatePalDrawer] = useState(false);
  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);


  return (
    <>
      <StandardAppContainerRounded>
        <TableActionsContainer
          left={
            <Box>
              <Select
                value={scope}
                onChange={(evt) => setScope(evt.target.value)}
                sx={{ width: "150px", mr: 2 }}
                size="small"
              >
                <MenuItem value="project">Project</MenuItem>
                <MenuItem value="org">Organization</MenuItem>
                <MenuItem value="orgproject">Org & Project</MenuItem>
              </Select>
              {scope === "project" && (
                <ProjectPickerDropdown
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  defaultProjectId={null}
                  size="small"
                  hideLabel={true}
                />
              )}
            </Box>
          }
          right={
            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowChangeStatusDialog(true)}
              >
                Change Status
              </Button>
            </Box>
          }
        />
        <TableContainer
          data={timesheets}
          loading={loading}
          columns={[
            "ID",
            "TIMESHEET NAME",
            "TYPE",
            "CREATED DATE",
            "PROJECT",
            "COST",
            "WORKERS",
            "MANUALLY CREATED",
            "STATUS",
            "ACTIONS",
          ]}
          enableSelectAll={true}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        >
          {timesheets?.map((item, index) => (
            <tr>
              <td>
                <TableCheckboxCell
                  currentRow={item}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                />
              </td>
              <td>{index + 1}</td>
              <td>
                {item?.worker?._id
                  ? item?.worker?.profile?.parent?.displayName
                  : item?.title
                  ? item.title
                  : "Untitled"}
              </td>
              <td>
                {item?.scope === "User"
                  ? "Personal"
                  : item?.isGroup
                  ? "Org (Group)"
                  : "Org"}
              </td>
              <td>
                {item.date ? moment(item.date).format("DD MMM YYYY") : ""}
              </td>
              <td>{item?.project ? item?.project?.displayName : "-"}</td>
              <td>
                ₹ {item?.scope === "Personal" ? "-" : item?.totalCost || 0}
              </td>
              <td>
                <div
                  style={{
                    backgroundColor: "#E7ECFD",
                    borderRadius: "5px",
                    padding: "5px",
                    color: "#111111",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {item?.numOfWorkers || 1}
                </div>
              </td>
              <td>
                {String(item?.manuallyCreated).charAt(0).toUpperCase() +
                  String(item?.manuallyCreated).slice(1)}
              </td>
              <td>
                <StatusChip
                  label={item?.status}
                  variant={item?.status === "PENDING" ? "Warning" : "Success"}
                />
              </td>
              <td>
                <ListingTableActionCell
                  cellActions={["Delete"]}
                  cellActionListener={() => {}}
                  cellData={item}
                />
              </td>
            </tr>
          ))}
        </TableContainer>
      </StandardAppContainerRounded>
      <CreateUsersTimesheet
        open={showUsersTimesheetDrawer}
        setOpen={setShowUsersTimesheetDrawer}
        reloadTimesheets={reloadTimesheets}
        selectedProject={selectedProject}
        setShowCreatePalDrawer={setShowCreatePalDrawer}
      />
      <CreateIndividualTimesheet
        open={showPersonalTimesheetCreationDrawer}
        setOpen={setShowPersonalTimesheetCreationDrawer}
        reloadTimesheets={reloadTimesheets}
        selectedProject={selectedProject}
        setShowCreatePalDrawer={setShowCreatePalDrawer}
      />
      <CreateGroupTimesheet
        open={showGroupTimesheetDrawer}
        setOpen={setShowGroupTimesheetDrawer}
        reloadTimesheets={reloadTimesheets}
        selectedProject={selectedProject}
        setShowCreatePalDrawer={setShowCreatePalDrawer}
      />
      <PalFormDrawer
        open={showCreatePalDrawer}
        setOpen={setShowCreatePalDrawer}
        text=""
        onSelect={() => setShowCreatePalDrawer(false)}
        setLoading={setLoading}
        relationType={"Customer"}
      />

      <ChangeStatusDialog
        open={showChangeStatusDialog}
        setOpen={setShowChangeStatusDialog}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        reloadTimesheets={reloadTimesheets}
      />
    </>
  );
};

export default TimesheetTab;

import React from "react";
import { Switch, Route } from "react-router-dom";
import BasicPrivateRoute from "../components/PrivateRoute/BasicPrivateRoute";
import PublicPrivateRoute from "../components/PrivateRoute/PublicPrivateRoute";
import HR from "../components/hr/HR.jsx";
import HRSettings from "../components/hr/settings/HRSettings.jsx";
import MyWork from "../components/mywork/MyWork.jsx";
import EditEmployee from "../components/hr/employees/EditEmployee.jsx";
import BulkAddEmployees from "../components/hr/employees/BulkAddEmployees.jsx";
import PayrollChecks from "../components/hr/payroll/payrollcheck/PayrollChecks.jsx";
import PaySlipTemplateEdit from "../components/hr/settings/finance/payslip/PaySlipTemplateEdit.jsx";
import EmployeeAbout from "../components/hr/employees/about/EmployeeAbout.jsx";
import EndEmployeeContract from "../components/hr/employees/endcontract/EndEmployeeContract.jsx";
import WorkSchedule from "../components/team/WorkSchedule/WorkSchedule";

const Routes = () => {
  return [
    <BasicPrivateRoute
        exact
        path="/organization/workschedule/:teamId"
        noAppbar={false}
        useBothSide={true}
        component={WorkSchedule}
    />,
    <BasicPrivateRoute
    exact
    path="/hr/:organizationId"
    noAppbar={true}
    useBothSide
    component={HR}
  />,

    <BasicPrivateRoute
    exact
    path="/hr/settings/:organizationId"
    noAppbar={true}
    useBothSide
    component={HRSettings}
  />,





  <BasicPrivateRoute
    exact
    path="/hr/employees/employee/about/:employeeId"
    noAppbar={true}
    useBothSide
    component={EmployeeAbout}
  />,

  <BasicPrivateRoute
    exact
    path="/hr/employees/employee/:employeeId/end-contract"
    noAppbar={true}
    useBothSide
    component={EndEmployeeContract}
  />,

  <BasicPrivateRoute
    exact
    path="/payslip/template/:templateId"
    noAppbar={true}
    useBothSide
    component={PaySlipTemplateEdit}
  />,

  <BasicPrivateRoute
    exact
    path="/payroll/:payrollRunId"
    noAppbar={true}
    useBothSide
    component={PayrollChecks}
  />,

  <BasicPrivateRoute
    exact
    path="/hr/employees/employee/:organizationId"
    noAppbar={true}
    useBothSide
    component={EditEmployee}
  />,

  <BasicPrivateRoute
    exact
    path="/hr/employees/bulk/:organizationId"
    noAppbar={true}
    useBothSide
    component={BulkAddEmployees}
  />,

  <BasicPrivateRoute
    exact
    path="/my-work/:profileId"
    noAppbar={true}
    useBothSide
    component={MyWork}
  />,
  ];
};
export default Routes;

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableContainer from "../../../../styled/generic/TableContainer";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import Api from "../../../../../helpers/Api";
import dayjs from "dayjs";
import MoreButton from "../../../../styled/generic/MoreButton";
import StatusHandler from "../../../../styled/generic/StatusHandler";
import { parsedInt } from "../../../../../helpers/textHelper";

const PayrollRun = ({ payrollRuns, setPayrollRuns, payrollRunsLoading }) => {
  const { organizationId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Box>
      <TableContainer
        columns={[
          "PAYROLL PERIOD",
          "PAY CYCLE",
          "TOTAL PAYSLIPS",
          "PAYMENT DATE",
          "TOTAL EXPENSE",
          "STATUS",
        ]}
        data={payrollRuns}
        loading={payrollRunsLoading}
      >
        {payrollRuns?.map((item) => (
          <tr
            onClick={() => {
              history.push(`/payroll/${item?._id}`);
            }}
          >
            <td>{dayjs(item?.date).format("MMMM YYYY")}</td>
            <td>Monthly</td>
            <td>{item?.paychecksCount}</td>
            <td>{dayjs(item?.date).format("DD-MM-YYYY")}</td>
            <td>₹ {parsedInt(item?.totalExpense) || 0}</td>
            <td>
              <StatusHandler
                label={item?.status}
                success={["Completed"]}
                error={["Awaiting"]}
              />
            </td>
            {/* <td>
              <MoreButton />
            </td> */}
          </tr>
        ))}
      </TableContainer>
    </Box>
  );
};

export default PayrollRun;

import React, { useEffect, useState } from "react";
import StandardContainer from "../../../../../styled/generic/StandardContainer";
import StandardAppContainer from "../../../../../styled/generic/StandardAppContainer";
import SpaceBetween from "../../../../../styled/generic/SpaceBetween";
import FormBox from "../../../../../styled/generic/FormBox";
import TextField from "../../../../../styled/generic/TextField";
import DuoButtonGroup from "../../../../../styled/generic/DuoButtonGroup";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import {
  createSalaryComponent,
  getSalaryComponent,
  updateSalaryComponent,
} from "./api";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const Deduction = ({ componentId, form, salaryStructure }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { organizationId } = useParams();

  const [componentName, setComponentName] = useState("");
  const [deductionFrequency, setDeductionFrequency] = useState(
    "One- Time Deduction"
  );
  const [deduction, setDeduction] = useState(null);

  const createDeduction = async () => {
    const { data } = await createSalaryComponent({
      type: form,
      name: componentName,
      deductionFrequency: deductionFrequency,
      organization: organizationId,
      author: user?._id,
      salaryStructure: salaryStructure?._id,
    });
    if (data) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Salary Component created successfully",
        },
      });
      history.push(`/hr/settings/${organizationId}?view=SalaryComponents`);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const getDeduction = async () => {
    try {
      const { data } = await getSalaryComponent({
        _id: componentId,
      });
      setDeduction(data);
      setComponentName(data?.name);
      setDeductionFrequency(data?.deductionFrequency);
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get earning",
        },
      });
    }
  };

  const updateEarning = async () => {
    try {
      const { data } = await updateSalaryComponent({
        _id: componentId,
        updateBody: {
          name: componentName,
          deductionFrequency: deductionFrequency,
        },
      });
      setDeduction(data);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Salary Component updated successfully",
        },
      });
      history.push(`/hr/settings/${organizationId}?view=SalaryComponents`);
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get earning",
        },
      });
    }
  };

  useEffect(() => {
    if (componentId) {
      getDeduction();
    }
  }, [componentId]);

  return (
    <StandardContainer>
      <StandardAppContainer>
        <SpaceBetween
          left={
            <FormBox label="Component Name">
              <TextField
                placeholder="Enter"
                fullWidth
                value={componentName}
                onChange={(e) => setComponentName(e.target.value)}
              />
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <FormBox label="Select Deduction Frequency">
              <RadioGroup
                defaultValue={deductionFrequency}
                onChange={(evt, val) => setDeductionFrequency(val)}
              >
                <FormControlLabel
                  value="One- Time Deduction"
                  control={<Radio />}
                  label="One- Time Deduction"
                />
                <FormControlLabel
                  value="Recurring Deduction"
                  control={<Radio />}
                  label="Recurring Deduction"
                />
              </RadioGroup>
            </FormBox>
          }
        />

        <DuoButtonGroup
          primaryButtonText={deduction ? "Update" : "Create"}
          primaryButtonListener={() => {
            if (deduction) {
              updateEarning();
            } else {
              createDeduction();
            }
          }}
          secondaryButtonText="Cancel"
          secondaryButtonListener={() => {
            history.push(
              `/hr/settings/${organizationId}?view=SalaryComponents`
            );
          }}
        />
      </StandardAppContainer>
    </StandardContainer>
  );
};

export default Deduction;

import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../../../styled/generic/StandardAppContainerRounded";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import FormBox from "../../../styled/generic/FormBox";
import TextField from "../../../styled/generic/TextField";
import dayjs from "dayjs";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import DatePicker from "../../../styled/generic/DatePicker";
import HorizBox from "../../../styled/generic/HorizBox";
import Api from "../../../../helpers/Api";
import { useDispatch } from "react-redux";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";

const ConfigureLeave = () => {
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const [leaveName, setLeaveName] = useState("");
  const [leaveCode, setLeaveCode] = useState("");
  const LEAVE_TYPES = ["PAID", "UNPAID"];
  const [leaveType, setLeaveType] = useState(LEAVE_TYPES[0]);
  const [description, setDescription] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [totalPaidLeavesInYear, setTotalPaidLeavesInYear] = useState(0);
  const [carryForwardLimit, setCarryForwardLimit] = useState(0);
  const [carryForwardValue, setCarryForwardValue] = useState(0);
  const [carryForwardValueUnit, setCarryForwardValueUnit] = useState("UNIT");
  const CARRY_FORWARD_VALUE_UNIT_OPTIONS = ["UNIT", "PERCENTAGE"];
  const [resetDate, setResetDate] = useState(dayjs().add(1, "year"));
  const [leaveId, setLeaveId] = useState(null);
  const [leave, setLeave] = useState(null);
  const [workEntryCode, setWorkEntryCode] = useState("");
  const [workEntryCodes, setWorkEntryCodes] = useState([]);

  const locationHook = useLocation();
  const searchParams = new URLSearchParams(locationHook.search);

  const getHROptions = async () => {
    try {
      const { data } = await Api.post("/employee/hr-options", {
        organizationId: organizationId,
      });

      if (data) {
        setWorkEntryCodes(data?.workEntryCodes);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const createLeave = async () => {
    try {
      const { data } = await Api.post(`/leave/create`, {
        leaveData: {
          name: leaveName,
          code: leaveCode,
          type: leaveType,
          description,
          validFromDate: fromDate,
          validToDate: toDate,
          organization: organizationId,
          workEntryCode,
        },
        leavePolicyData: {
          totalPaidLeavesInYear,
          carryForwardLimit,
          carryForwardValue,
          carryForwardValueUnit,
          resetDate,
          organization: organizationId,
        },
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Leave created successfully",
        },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create leave",
        },
      });
    }
  };

  const getLeaveById = async () => {
    try {
      const { data } = await Api.post(`/leave/get`, {
        _id: leaveId,
      });
      if (data) {
        setLeave(data);
        setLeaveName(data?.name);
        setLeaveCode(data?.code);
        setLeaveType(data?.type);
        setDescription(data?.description);
        setFromDate(dayjs(data?.validFromDate));
        setToDate(dayjs(data?.validToDate));
        setTotalPaidLeavesInYear(data?.policy?.totalPaidLeavesInYear);
        setCarryForwardLimit(data?.policy?.carryForwardLimit);
        setCarryForwardValue(data?.policy?.carryForwardValue);
        setCarryForwardValueUnit(data?.policy?.carryForwardValueUnit);
        setResetDate(dayjs(data?.policy?.resetDate));
        setWorkEntryCode(data?.workEntryCode);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get leave",
        },
      });
    }
  };

  const updateLeave = async () => {
    try {
      const { data } = await Api.post(`/leave/update`, {
        _id: leaveId,
        updateLeaveBody: {
          name: leaveName,
          code: leaveCode,
          type: leaveType,
          description,
          validFromDate: fromDate,
          validToDate: toDate,
          workEntryCode,
        },
        updateLeavePolicyBody: {
          totalPaidLeavesInYear,
          carryForwardLimit,
          carryForwardValue,
          carryForwardValueUnit,
          resetDate,
        },
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Leave updated successfully",
        },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to ypdate leave",
        },
      });
    }
  };

  useEffect(() => {
    const _leaveId = searchParams.get("leaveId");
    if (_leaveId) {
      setLeaveId(_leaveId);
    }
  }, []);

  useEffect(() => {
    getHROptions();
  }, []);

  useEffect(() => {
    if (leaveId) {
      getLeaveById();
    }
  }, [leaveId]);

  return (
    <StandardAppContainerRounded>
      <SpaceBetween
        left={
          <FormBox label="Leave Name">
            <TextField
              placeholder="Enter"
              value={leaveName}
              onChange={(e) => setLeaveName(e.target.value)}
              fullWidth
            />
          </FormBox>
        }
        right={
          <FormBox label="Leave Code">
            <TextField
              placeholder="Enter"
              value={leaveCode}
              onChange={(e) => setLeaveCode(e.target.value)}
              fullWidth
            />
          </FormBox>
        }
      />
      <SpaceBetween
        left={
          <FormBox label="Leave Type">
            <Select
              value={leaveType}
              onChange={(e) => setLeaveType(e.target.value)}
              fullWidth
            >
              {LEAVE_TYPES.map((type) => (
                <MenuItem value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormBox>
        }
        right={
          <FormBox label="Work Entry Code">
            <Select
              value={workEntryCode}
              onChange={(e) => setWorkEntryCode(e.target.value)}
              fullWidth
              displayEmpty
            >
              <MenuItem value="">Select</MenuItem>
              {workEntryCodes.map((code) => (
                <MenuItem value={code._id}>{code?.name}</MenuItem>
              ))}
            </Select>
          </FormBox>
        }
      />
      <FormBox label="Description">
        <TextField
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter"
          multiline
          rows={4}
        />
      </FormBox>
      <FormBox label="Validity">
        <SpaceBetween
          left={
            <DatePicker
              value={fromDate}
              onChange={setFromDate}
              textfieldProps={{ placeholder: "From Date" }}
            />
          }
          right={
            <DatePicker
              value={toDate}
              onChange={setToDate}
              textfieldProps={{ placeholder: "To Date" }}
            />
          }
        />
      </FormBox>

      <SpaceBetween
        left={
          <FormBox label="Total Paid Leaves in Year">
            <TextField
              fullWidth
              value={totalPaidLeavesInYear}
              onChange={(e) => setTotalPaidLeavesInYear(e.target.value)}
              placeholder="Enter"
            />
          </FormBox>
        }
        right={
          <FormBox label="Carry Forward Limit">
            <TextField
              fullWidth
              value={carryForwardLimit}
              onChange={(e) => setCarryForwardLimit(e.target.value)}
              placeholder="Enter"
            />
          </FormBox>
        }
      />
      <SpaceBetween
        left={
          <FormBox label="Carry Forward Value">
            <TextField
              fullWidth
              value={carryForwardValue}
              onChange={(e) => setCarryForwardValue(e.target.value)}
              placeholder="Enter"
            />
          </FormBox>
        }
        right={
          <FormBox label="Carry Forward Value Unit">
            <Select
              value={carryForwardValueUnit}
              onChange={(e) => setCarryForwardValueUnit(e.target.value)}
              fullWidth
            >
              {CARRY_FORWARD_VALUE_UNIT_OPTIONS.map((unit) => (
                <MenuItem value={unit}>{unit}</MenuItem>
              ))}
            </Select>
          </FormBox>
        }
      />
      <SpaceBetween
        left={
          <FormBox label="Reset Date">
            <DatePicker
              value={resetDate}
              onChange={setResetDate}
              textfieldProps={{ placeholder: "Reset Date" }}
            />
          </FormBox>
        }
      />

      <DuoButtonGroup
        primaryButtonText={leave?._id ? "Update" : "Create"}
        primaryButtonListener={() => {
          leave?._id ? updateLeave() : createLeave();
        }}
        secondaryButtonText="Cancel"
        secondaryButtonListener={() => {}}
      />
    </StandardAppContainerRounded>
  );
};

export default ConfigureLeave;

import React, { useEffect, useState } from "react";
import StandardAppContainer from "../../styled/generic/StandardAppContainer";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import {
  Box,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import TableContainer from "../../styled/generic/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import SpaceBetween from "../../styled/generic/SpaceBetween";
import FormBox from "../../styled/generic/FormBox";
import EmployeeSearcher from "../../styled/generic/EmployeeSearcher";
import dayjs from "dayjs";
import TextField from "../../styled/generic/TextField";
import { Spa } from "@mui/icons-material";
import DatePicker from "../../styled/generic/DatePicker";
import Api from "../../../helpers/Api";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import MoreButton from "../../styled/generic/MoreButton";
import DateText from "../../styled/generic/DateText";
import Loan from "./tabs/loans/Loan";
import Button from "../../styled/generic/Button";
import PayrollRun from "./tabs/payrollrun/PayrollRun";
import StandardDialogContainer from "../../styled/generic/StandardDialogContainer";
import Dialog from "../../styled/generic/Dialog";
import StatusChip from "../../styled/generic/StatusChip";
import socket from "../../../helpers/socket/socketio";

const Payroll = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { organizationId } = useParams();
  const [tab, setTab] = useState("payrollrun");
  const [showLoanDrawer, setShowLoanDrawer] = useState(false);
  const [payrollRuns, setPayrollRuns] = useState([]);
  const [showPayrollDialog, setShowPayrollDialog] = useState(false);
  const [handlingPayrolls, setHandlingPayrolls] = useState(false);
  const [payrollExisting, setPayrollExisting] = useState(null);
  const [creatingPayroll, setCreatingPayroll] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [payrollRunsLoading, setPayrollRunsLoading] = useState(false);
  const [progressMessage, setProgressMessage] = useState(
    "Please wait, this will take a while!"
  );

  const handlePayrollGeneration = async () => {
    try {
      setHandlingPayrolls(true);
      const { data } = await Api.post(`/payroll/handle`, {
        organizationId,
        date: selectedDate.toDate(),
      });
      setPayrollExisting(data);
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setHandlingPayrolls(false);
    }
  };

  const createPayroll = async () => {
    try {
      setCreatingPayroll(true);
      const { data } = await Api.post(`/payroll/create`, {
        organizationId,
        date: selectedDate.toDate(),
      });
      setShowPayrollDialog(false);
      getPayrollRuns();
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Payroll generated successfully",
        },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch payrolls",
        },
      });
    } finally {
      setCreatingPayroll(false);
    }
  };

  useEffect(() => {
    handlePayrollGeneration();
  }, [selectedDate]);

  const getPayrollRuns = async () => {
    try {
      setPayrollRunsLoading(true);
      const { data } = await Api.post(`/payroll/get`, {
        organizationId: organizationId,
      });
      if (data && Array.isArray(data)) {
        setPayrollRuns(data);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch payrolls",
        },
      });
    } finally {
      setPayrollRunsLoading(false);
    }
  };

  useEffect(() => {
    getPayrollRuns();
  }, []);

  useEffect(() => {
    const path = "payroll/" + organizationId;
    socket.on(path, (data) => {
      if (data) {
        let processed = data?.payload?.processed || 0;
        let total = data?.payload?.total || 0;
        let progress = data?.payload?.progress || 0;
        let msg = `Processing ${processed} of ${total} payrolls (${progress}%)`;
        setProgressMessage(msg);
      }
    });
  }, []);

  return (
    <StandardAppContainerRounded>
      <BoxSpaceBetween sx={{ mb: 3 }}>
        <Box>
          <ToggleButtonGroup
            color="primary"
            value={tab}
            exclusive
            onChange={(e, value) => setTab(value)}
            aria-label="Platform"
            size="small"
          >
            <ToggleButton value="payrollrun">Payroll Run</ToggleButton>
            <ToggleButton value="loans">Loans</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box>
          {tab === "payrollrun" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowPayrollDialog(true)}
            >
              Generate Payroll
            </Button>
          )}
          {tab === "loans" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowLoanDrawer(true)}
            >
              Add Loan
            </Button>
          )}
        </Box>
      </BoxSpaceBetween>
      {tab === "payrollrun" && <Box></Box>}

      {tab === "loans" && (
        <Loan
          showLoanDrawer={showLoanDrawer}
          setShowLoanDrawer={setShowLoanDrawer}
        />
      )}

      {tab === "payrollrun" && (
        <>
          <PayrollRun
            payrollRuns={payrollRuns}
            setPayrollRuns={setPayrollRuns}
            payrollRunsLoading={payrollRunsLoading}
          />
          <Dialog
            open={showPayrollDialog}
            setOpen={setShowPayrollDialog}
            title={`Generate Payroll for ${dayjs(selectedDate)
              .startOf("month")
              .format("DD MMM YYYY")} to ${dayjs(selectedDate)
              .endOf("month")
              .format("DD MMM YYYY")}`}
          >
            <FormBox label="Select Date">
              <DatePicker
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                views={["year", "month"]}
                openTo="month"
                format="MMMM YYYY"
              />
            </FormBox>
            {handlingPayrolls ? (
              <CircularProgress />
            ) : (
              <Box>
                {payrollExisting?._id ? (
                  <StatusChip
                    label={`Warning: A payroll already exists for the period ${dayjs(
                      payrollExisting?.date
                    )
                      .startOf("month")
                      .format("DD MMM YYYY")} to ${dayjs(payrollExisting?.date)
                      .endOf("month")
                      .format(
                        "DD MMM YYYY"
                      )}. Proceeding will overwrite the existing payroll.`}
                    variant="Error"
                    styleProps={{
                      fontSize: "0.9rem",
                      display: "block",
                      width: "100%",
                      padding: "10px",
                    }}
                  />
                ) : (
                  <StatusChip
                    label="Click on generate button to generate the payroll"
                    variant="Success"
                    styleProps={{
                      fontSize: "0.9rem",
                      display: "block",
                      width: "100%",
                      padding: "10px",
                    }}
                  />
                )}
              </Box>
            )}
            {creatingPayroll && (
              <StatusChip
                label={progressMessage}
                variant="Success"
                styleProps={{
                  fontSize: "0.9rem",
                  display: "block",
                  width: "100%",
                  padding: "10px",
                  marginTop: "15px",
                }}
              />
            )}
            <DuoButtonGroup
              primaryButtonText="Generate"
              primaryButtonListener={() => createPayroll()}
              loadingPrimary={creatingPayroll}
              secondaryButtonText="Cancel"
              secondaryButtonListener={() => setShowPayrollDialog(false)}
            />
          </Dialog>
        </>
      )}
    </StandardAppContainerRounded>
  );
};

export default Payroll;

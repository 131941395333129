import React, { useEffect, useState } from "react";
import StandardContainer from "../styled/generic/StandardContainer";
import StandardAppContainer from "../styled/generic/StandardAppContainer";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import ThemedButton from "../styled/generic/ThemedButton";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import TabsWithCommonAction from "../styled/generic/TabsWithCommonAction";
import TabsWithActions from "../styled/generic/TabsWithActions";
import Button from "../styled/generic/Button";
import {
  Add,
  CalendarMonthOutlined,
  CalendarTodayOutlined,
  CalendarViewDayOutlined,
  CalendarViewMonthOutlined,
  Groups2Outlined,
  Settings,
} from "@mui/icons-material";
import Overview from "./overview/Overview";
import Employees from "./employees/Employees";
import HorizBox from "../styled/generic/HorizBox";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../helpers/Api";
import { AttendanceDayView } from "./attendance/AttendanceDayView";
import AttendanceMonthView from "./attendance/AttendanceMonthView";
import AssignLeaveDialog from "./leaves/AssignLeaveDialog";
import Payroll from "./payroll/Payroll";
import Leaves from "./leaves/Leaves";
import TabItem from "../styled/generic/TabItem";
import TabBox from "../styled/generic/TabBox";

const HR = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { organizationId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [tab, setTab] = useState("Overview");
  const [attendanceView, setAttendanceView] = useState("day");
  const [showBulkAttendanceDrawer, setShowBulkAttendanceDrawer] =
    useState(false);
  const [leaves, setLeaves] = useState([]);

  const createEmployee = async () => {
    history.push(`/hr/employees/employee/${organizationId}`);
  };

  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    const tab = queryParams.get("tab");
    if (tab) {
      setTab(tab);
    }
  }, [location]);

  return (
    <StandardContainer
      appBarTitle="Human Resource"
      showAppBar={true}
      appBarActions={
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Settings />}
          onClick={() => history.push(`/hr/settings/${organizationId}`)}
        >
          Settings
        </Button>
      }
    >
      {/* {[
            { title: "Overview", action: <></> },
            {
              title: "Employees",
              action: (
                <HorizBox>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Groups2Outlined />}
                    onClick={() =>
                      history.push(`/hr/employees/bulk/${organizationId}`)
                    }
                  >
                    Bulk Add
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => createEmployee()}
                  >
                    Employee
                  </Button>
                </HorizBox>
              ),
            },
            {
              title: "Attendance",
              action: (
                <HorizBox>
                  <ToggleButtonGroup
                    value={attendanceView}
                    exclusive
                    onChange={(evt, val) => setAttendanceView(val)}
                  >
                    <ToggleButton value="day">
                      <CalendarTodayOutlined />
                    </ToggleButton>
                    <ToggleButton value="month">
                      <CalendarMonthOutlined />
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {attendanceView === "month" && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setShowBulkAttendanceDrawer(true)}
                    >
                      Bulk Entry
                    </Button>
                  )}
                </HorizBox>
              ),
            },
            {
              title: "Leaves",
              action: (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setShowAssignLeaveDialog(true)}
                >
                  Leaves
                </Button>
              ),
            },
            {
              title: "Payroll",
              action: <></>,
            },
          ] */}

      <TabBox display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex">
          <TabItem
            variant="body1"
            active={tab === "Overview"}
            onClick={() => {
              history.push(`/hr/${organizationId}?tab=Overview`);
              setTab("Overview");
            }}
          >
            Overview
          </TabItem>
          <TabItem
            variant="body1"
            active={tab === "Employees"}
            onClick={() => {
              history.push(`/hr/${organizationId}?tab=Employees`);
              setTab("Employees");
            }}
          >
            Employees
          </TabItem>
          <TabItem
            variant="body1"
            active={tab === "Attendance"}
            onClick={() => {
              history.push(`/hr/${organizationId}?tab=Attendance`);
              setTab("Attendance");
            }}
          >
            Attendance
          </TabItem>
          <TabItem
            variant="body1"
            active={tab === "Leaves"}
            onClick={() => {
              history.push(`/hr/${organizationId}?tab=Leaves`);
              setTab("Leaves");
            }}
          >
            Leaves
          </TabItem>
          <TabItem
            variant="body1"
            active={tab === "Payroll"}
            onClick={() => {
              history.push(`/hr/${organizationId}?tab=Payroll`);
              setTab("Payroll");
            }}
          >
            Payroll
          </TabItem>
        </Box>

        {tab === "Overview" && <Box></Box>}
        {tab === "Employees" && (
          <HorizBox>
            {/* <Button
              variant="outlined"
              color="primary"
              startIcon={<Groups2Outlined />}
              onClick={() =>
                history.push(`/hr/employees/bulk/${organizationId}`)
              }
            >
              Bulk Add
            </Button> */}
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => createEmployee()}
            >
              Employee
            </Button>
          </HorizBox>
        )}
        {tab === "Attendance" && (
          <HorizBox>
            <ToggleButtonGroup
              value={attendanceView}
              exclusive
              onChange={(evt, val) => setAttendanceView(val)}
            >
              <ToggleButton value="day">
                <CalendarTodayOutlined />
              </ToggleButton>
              <ToggleButton value="month">
                <CalendarMonthOutlined />
              </ToggleButton>
            </ToggleButtonGroup>
            {attendanceView === "month" && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowBulkAttendanceDrawer(true)}
              >
                Bulk Entry
              </Button>
            )}
          </HorizBox>
        )}
        {tab === "Leaves" && <Box></Box>}
        {tab === "Payroll" && <Box></Box>}
      </TabBox>

      {tab === "Overview" && <Overview />}
      {tab === "Attendance" && (
        <Box>
          {attendanceView === "day" ? (
            <AttendanceDayView />
          ) : (
            <AttendanceMonthView
              showBulkAttendanceDrawer={showBulkAttendanceDrawer}
              setShowBulkAttendanceDrawer={setShowBulkAttendanceDrawer}
            />
          )}
        </Box>
      )}
      {tab === "Employees" && <Employees />}
      {tab === "Leaves" && <Leaves />}
      {tab === "Payroll" && <Payroll />}
    </StandardContainer>
  );
};

export default HR;

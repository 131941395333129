import React, { useEffect, useState } from "react";
import FormBox from "../../../styled/generic/FormBox";
import Api from "../../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import {
  Avatar,
  Box,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import Autocomplete from "../../../styled/generic/Autocomplete";
import TextField from "../../../styled/generic/TextField";
import PalAutocomplete from "../../../styled/generic/PalAutocomplete";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import StandardContainerRounded from "../../../styled/generic/StandardContainerRounded";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";

const CreateIndividualTimesheet = ({
  open,
  setOpen,
  reloadTimesheets,
  selectedProject,
  setShowCreatePalDrawer
}) => {
  const [individualTotalHours, setIndividualTotalHours] = useState(0);
  const [individualOvertime, setIndividualOvertime] = useState(0);
  const [individualLateFine, setIndividualLateFine] = useState(0);
  const [individualHourlyRate, setIndividualHourlyRate] = useState(0);
  const [individualAllowance, setIndividualAllowance] = useState(0);
  const [individualDeduction, setIndividualDeduction] = useState(0);
  const [loading, setLoading] = useState(false);
  const [individualTotalCost, setIndividualTotalCost] = useState(0);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const { user } = useSelector((state) => state.auth);
  const { teamId } = useParams();
  const dispatch = useDispatch();

  const createIndividualTimesheet = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/timesheet/create/individual", {
        title: "Individual Timesheet",
        userId: user?._id,
        projectId: selectedProject?._id || null,
        orgTeamId: teamId,
        worker: selectedEntity?._id,
        workerProfile: selectedEntity?.profile?._id,
        date: new Date().toISOString(),
        hourlyRate: individualHourlyRate,
        allowance: individualAllowance,
        deduction: individualDeduction,
        overtime: individualOvertime,
        lateFine: individualLateFine,
        totalCost: individualTotalCost,
        totalHours: individualTotalHours,
      });
      if (data) {
        setOpen(false);
        reloadTimesheets();
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create timesheet",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const calculateIndividualTotalCost = () => {
    let cost = 0;
    cost += parseFloat(individualHourlyRate) * parseFloat(individualTotalHours);

    cost += parseFloat(individualAllowance);
    cost += parseFloat(individualOvertime);

    cost -= parseFloat(individualDeduction);
    cost -= parseFloat(individualLateFine);

    setIndividualTotalCost(isNaN(cost) ? 0 : cost);
  };

  useEffect(() => {
    calculateIndividualTotalCost();
  }, [
    individualHourlyRate,
    individualTotalHours,
    individualAllowance,
    individualDeduction,
    individualOvertime,
    individualLateFine,
  ]);

  return (
    <DrawerContainer
      title="Create Individual Timesheet"
      open={open}
      setOpen={setOpen}
    >
      <Box>
        <PalAutocomplete
          selectedEntity={selectedEntity}
          setSelectedEntity={setSelectedEntity}
          teamId={teamId}
        />
        <SpaceBetween
          left={
            <FormBox label="Hourly Rate">
              <TextField
                fullWidth
                value={individualHourlyRate}
                onChange={(evt) => setIndividualHourlyRate(evt.target.value)}
                placeholder="Enter"
                type="Number"
              />
            </FormBox>
          }
          right={
            <FormBox label="Total Hours">
              <TextField
                fullWidth
                value={individualTotalHours}
                onChange={(evt) => setIndividualTotalHours(evt.target.value)}
                placeholder="Enter"
                type="Number"
              />
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <FormBox label="Allowance">
              <TextField
                fullWidth
                value={individualAllowance}
                onChange={(evt) => setIndividualAllowance(evt.target.value)}
                placeholder="Enter"
                type="Number"
              />
            </FormBox>
          }
          right={
            <FormBox label="Deduction">
              <TextField
                fullWidth
                value={individualDeduction}
                onChange={(evt) => setIndividualDeduction(evt.target.value)}
                placeholder="Enter"
                type="Number"
              />
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <FormBox label="Overtime">
              <TextField
                fullWidth
                value={individualOvertime}
                onChange={(evt) => setIndividualOvertime(evt.target.value)}
                placeholder="Enter"
                type="Number"
              />
            </FormBox>
          }
          right={
            <FormBox label="Late Fee">
              <TextField
                fullWidth
                value={individualLateFine}
                onChange={(evt) => setIndividualLateFine(evt.target.value)}
                placeholder="Enter"
                type="Number"
              />
            </FormBox>
          }
        />
        <StandardContainerRounded>
          <FormBox disableMargins={true} label="Total Cost">
            <Typography variant="subtitle1" color="primary">
              {`(${individualHourlyRate} * ${individualTotalHours}) + ${individualAllowance} + ${individualOvertime} - ${individualDeduction} - ${individualLateFine} =  ₹ ${individualTotalCost}`}
            </Typography>
          </FormBox>
        </StandardContainerRounded>
        <DuoButtonGroup
          primaryButtonText="Create"
          primaryButtonListener={() => createIndividualTimesheet()}
          secondaryButtonText="New Relation"
          secondaryButtonListener={() => setShowCreatePalDrawer(true)}
        />
      </Box>
    </DrawerContainer>
  );
};

export default CreateIndividualTimesheet;

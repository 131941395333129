import { Clear } from "@material-ui/icons";
import { Avatar, Box, IconButton } from "@mui/material";
import React from "react";

const StandardChip = ({ src, label, onDelete }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        fontSize: "1rem",
        margin: "4px",
        border: "1px solid rgba(0,0,0,0.15)",
        borderRadius: "10px",
        padding: "3px 4px",
      }}
    >
      <Avatar alt={label} src={src} sx={{ width: 28, height: 28, mr: 1 }} />
      <span style={{ margin: "0px 5px" }}>{label}</span>
      <IconButton size="small" onClick={() => onDelete()}>
        <Clear style={{ width: "20px", height: "20px" }} />
      </IconButton>
    </Box>
  );
};

export default StandardChip;

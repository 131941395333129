import React from "react";
import { getColorByLabel } from "../../../helpers/colorHelper";
import StatusChip from "./StatusChip";

const StatusHandler = ({
  label,
  success = [],
  error = [],
  warning = [],
  info = [],
  fontSize,
  rounded
}) => {
  return (
    <StatusChip
      label={label}
      variant={
        success.includes(label)
          ? "Success"
          : error.includes(label)
          ? "Error"
          : warning.includes(label)
          ? "Warning"
          : info.includes(label)
          ? "Info"
          : "Info"
      }
      fontSize={fontSize}
      rounded={rounded}
    />
  );
};

export default StatusHandler;

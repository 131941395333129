import React, { useEffect, useState } from "react";
import StandardContainer from "../../../../../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../../../../../styled/generic/StandardAppContainerRounded";
import SpaceBetween from "../../../../../styled/generic/SpaceBetween";
import FormBox from "../../../../../styled/generic/FormBox";
import TextField from "../../../../../styled/generic/TextField";
import Select from "../../../../../styled/generic/Select";
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { Percent } from "@mui/icons-material";
import DuoButtonGroup from "../../../../../styled/generic/DuoButtonGroup";
import {
  createSalaryComponent,
  getSalaryComponent,
  getSalaryComponents,
  updateSalaryComponent,
} from "./api";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import CalculatorBox from "../CalculatorBox";

const Earning = ({ componentId, salaryStructure }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { organizationId } = useParams();

  const [componentName, setComponentName] = useState("");
  const CALCULATION_TYPES = ["Fixed", "Percentage", "Formula"];
  const [calculationType, setCalculationType] = useState(CALCULATION_TYPES[0]);
  const [taxable, setTaxable] = useState(false);
  const [showOnPaySlip, setShowOnPaySlip] = useState(false);
  const DIVIDE_FROM_TYPES = ["Annual CTC"];
  const [dividedFrom, setDividedFrom] = useState(DIVIDE_FROM_TYPES[0]);
  const [dividePercentage, setDividePercentage] = useState("");
  const [earning, setEarning] = useState(null);
  const [expression, setExpression] = useState("");
  const [components, setComponents] = useState([]);

  const createEarning = async () => {
    const { data } = await createSalaryComponent({
      type: "Earning",
      name: componentName,
      calculationType: calculationType,
      //dividedFrom: dividedFrom, // TODO
      percentage: dividePercentage,
      taxable: taxable,
      showOnPaySlip: showOnPaySlip,
      organization: organizationId,
      author: user?._id,
      salaryStructure: salaryStructure?._id,
      expression: expression,
    });
    if (data) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Salary Component created successfully",
        },
      });
      history.push(`/hr/settings/${organizationId}?view=SalaryComponents`);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const getEarning = async () => {
    try {
      const { data } = await getSalaryComponent({
        _id: componentId,
      });
      setEarning(data);
      setComponentName(data?.name);
      setCalculationType(data?.calculationType);
      setTaxable(data?.taxable);
      setShowOnPaySlip(data?.showOnPaySlip);
      setDividedFrom(data?.dividedFrom);
      setDividePercentage(data?.percentage);
      setExpression(data?.expression);
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get earning",
        },
      });
    }
  };

  const updateEarning = async () => {
    try {
      const { data } = await updateSalaryComponent({
        _id: componentId,
        updateBody: {
          name: componentName,
          calculationType: calculationType,
          //dividedFrom: dividedFrom, // TODO
          percentage: dividePercentage,
          taxable: taxable,
          showOnPaySlip: showOnPaySlip,
          expression: expression,
        },
      });
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Salary Component updated successfully",
        },
      });
      history.push(`/hr/settings/${organizationId}?view=SalaryComponents`);
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get earning",
        },
      });
    }
  };

  useEffect(() => {
    if (componentId) {
      getEarning();
    }
  }, [componentId]);

  const getData = async () => {
    const { data } = await getSalaryComponents({
      organization: organizationId,
    });
    setComponents(data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <StandardContainer>
      <StandardAppContainerRounded>
        <SpaceBetween
          left={
            <FormBox label="Component Name">
              <TextField
                placeholder="Enter"
                fullWidth
                value={componentName}
                onChange={(e) => setComponentName(e.target.value)}
              />
            </FormBox>
          }
          right={
            <FormBox label="Calculation Type">
              <Select
                value={calculationType}
                onChange={(e) => setCalculationType(e.target.value)}
                fullWidth
              >
                {CALCULATION_TYPES.map((type) => (
                  <MenuItem value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormBox>
          }
        />

        <Box>
          {calculationType === "Percentage" && (
            <SpaceBetween
              left={
                <FormBox label="Divided from">
                  <Select
                    value={dividedFrom}
                    onChange={(e) => setDividedFrom(e.target.value)}
                    fullWidth
                  >
                    {DIVIDE_FROM_TYPES.map((type) => (
                      <MenuItem value={type}>{type}</MenuItem>
                    ))}
                  </Select>
                </FormBox>
              }
              right={
                <FormBox label="Percentage">
                  <TextField
                    placeholder="Percentage"
                    variant="outlined"
                    fullWidth
                    value={dividePercentage}
                    onChange={(e) => setDividePercentage(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Percent />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormBox>
              }
            />
          )}

          {calculationType === "Formula" && (
            <CalculatorBox
              expression={expression}
              setExpression={setExpression}
              components={
                componentId && components
                  ? components.slice(
                      0,
                      components.findIndex((obj) => obj._id === componentId) !==
                        -1
                        ? components.findIndex((obj) => obj._id === componentId)
                        : 0
                    )
                  : components
                  ? components
                  : []
              }
            />
          )}

          <Box display="flex" flex="wrap">
            <FormControlLabel
              control={
                <Checkbox
                  checked={taxable}
                  onChange={(evt, checked) => setTaxable(checked)}
                />
              }
              label="Taxable"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showOnPaySlip}
                  onChange={(evt, checked) => setShowOnPaySlip(checked)}
                />
              }
              label="Show on Payslip"
            />
          </Box>
        </Box>

        <DuoButtonGroup
          primaryButtonText={earning ? "Update" : "Create"}
          primaryButtonListener={() => {
            if (earning) {
              updateEarning();
            } else {
              createEarning();
            }
          }}
          secondaryButtonText="Cancel"
          secondaryButtonListener={() =>
            history.push(`/hr/settings/${organizationId}?view=SalaryComponents`)
          }
        />
      </StandardAppContainerRounded>
    </StandardContainer>
  );
};

export default Earning;

import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import FormBox from "../styled/generic/FormBox";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import HorizBox from "../styled/generic/HorizBox";
import dayjs from "dayjs";
import Image from "../styled/generic/Image";
import {
  AddAlarmOutlined,
  AlarmOffOutlined,
  AlarmOnOutlined,
  CalendarTodayOutlined,
} from "@mui/icons-material";
import Button from "../styled/generic/Button";
import TabsWithActions from "../styled/generic/TabsWithActions";
import MyAccount from "./tabs/MyAccount";
import Timesheet from "./tabs/Timesheet";
import Api from "../../helpers/Api";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useDebounce } from "react-use";
import PaySlip from "./tabs/PaySlip";
import Leaves from "./tabs/Leaves";

const MyWorkHome = ({ finrel }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [tab, setTab] = useState("My Account");

  // Timesheet states and functions
  const [elapsedHours, setElapsedHours] = useState(0);
  const [elapsedMinutes, setElapsedMinutes] = useState(0);
  const [elapsedSeconds, setElapsedSeconds] = useState(0);

  const [isWorking, setIsWorking] = useState(false);
  const [workStartTime, setWorkStartTime] = useState();
  const [workEndTime, setWorkEndTime] = useState();
  const [timesheets, setTimesheets] = useState([]);
  const [timesheetsLoading, setTimesheetsLoading] = useState(false);
  const [currentTimesheet, setCurrentTimesheet] = useState();

  const clockIn = async () => {
    try {
      setWorkStartTime(new Date());
      setIsWorking(true);
      const { data } = await Api.post("/timesheet/clockInForEmployee", {
        timesheetId: currentTimesheet?._id,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to clock in",
        },
      });
      setWorkStartTime(null);
      setIsWorking(false);
    }
  };

  const clockOut = async () => {
    try {
      setWorkEndTime(new Date());
      setIsWorking(false);
      const { data } = await Api.post("/timesheet/clockOutForEmployee", {
        timesheetId: currentTimesheet?._id,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to clock out",
        },
      });
      setWorkEndTime(null);
      setIsWorking(true);
    }
  };

  const getHoursMinSecFromTwoDates = (startDate, endDate) => {
    let start = dayjs(startDate);
    let end = dayjs(endDate);

    const diffInMilliseconds = end.diff(start);

    const totalHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const totalMinutes = Math.floor(
      (diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );
    const totalSeconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000);

    return {
      totalHours,
      totalMinutes,
      totalSeconds,
    };
  };

  const calculateWorkTimeElapsed = () => {
    if (isWorking) {
      const startDate = workStartTime;
      const endDate = new Date();
      const { totalHours, totalMinutes, totalSeconds } =
        getHoursMinSecFromTwoDates(startDate, endDate);

      setElapsedHours(totalHours);
      setElapsedMinutes(totalMinutes);
      setElapsedSeconds(totalSeconds);
    } else {
      if (workStartTime && workEndTime) {
        const startDate = workStartTime;
        const endDate = workEndTime;
        const { totalHours, totalMinutes, totalSeconds } =
          getHoursMinSecFromTwoDates(startDate, endDate);

        setElapsedHours(totalHours);
        setElapsedMinutes(totalMinutes);
        setElapsedSeconds(totalSeconds);
      } else {
        setElapsedHours(0);
        setElapsedMinutes(0);
        setElapsedSeconds(0);
      }
    }
  };

  const getTimesheets = async () => {
    try {
      setTimesheetsLoading(true);
      const { data } = await Api.post("/timesheet/getTimesheetsForEmployee", {
        userProfile: user?.profile,
      });
      setTimesheets(data?.previousTimesheets || []);
      setCurrentTimesheet(data?.todaysTimesheet);

      if (data?.todaysTimesheet?.isClockedIn) {
        setIsWorking(true);
        setWorkStartTime(new Date(data?.todaysTimesheet?.lastClockInTime));
      } else if (
        data?.todaysTimesheet?.lastClockInTime &&
        data?.todaysTimesheet?.lastClockOutTime
      ) {
        setIsWorking(false);
        setWorkStartTime(new Date(data?.todaysTimesheet?.lastClockInTime));
        setWorkEndTime(new Date(data?.todaysTimesheet?.lastClockOutTime));
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setTimesheetsLoading(false);
    }
  };

  useEffect(() => {
    getTimesheets();
  }, []);

  useDebounce(
    () => {
      if (isWorking) {
        calculateWorkTimeElapsed();
      }
    },
    1000,
    [isWorking, elapsedSeconds]
  );

  return (
    <Box>
      <StandardAppContainerRounded>
        <FormBox label="My Organization" labelTypographyVariant="h5">
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Hello {finrel?.mainProfile?.parent?.displayName}!
          </Typography>
          <Typography variant="subtitle1">
            You can check in here, see your timesheets, payslips and apply for leaves etc
          </Typography>
        </FormBox>
        <BoxSpaceBetween>
          <HorizBox sx={{ width: "50%" }}>
            <Image
              src={finrel?.profile?.parent?.displayPicture?.url}
              width="180px"
              height="100px"
            />
            <Box>
              <Typography variant="h6">
                {finrel?.profile?.parent?.displayName}
              </Typography>
              <Typography variant="subtitle1">
                Organization Id: {finrel?.profile?.parent?._id}
              </Typography>
              <Typography variant="subtitle1">
                Created on:{" "}
                {dayjs(finrel?.profile?.parent?.createdAt).format(
                  "DD MMM YYYY"
                )}
              </Typography>
            </Box>
          </HorizBox>

          {currentTimesheet?._id && (
            <HorizBox>
              <Box
                sx={{
                  border: "1px solid #E48900",
                  borderRadius: "10px",
                  px: 3,
                  py: 1,
                  backgroundColor: "#FFF5E5",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src="/icons/app/hourglass.png" />
                  <Grid container columnSpacing={2} sx={{ ml: "5px" }}>
                    <Grid item xs={4}>
                      <Typography variant="h6" textAlign="center">
                        {elapsedHours}
                      </Typography>
                      <Typography variant="subtitle1" textAlign="center">
                        hours
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h6" textAlign="center">
                        {elapsedMinutes}
                      </Typography>
                      <Typography variant="subtitle1" textAlign="center">
                        minutes
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h6" textAlign="center">
                        {elapsedSeconds}
                      </Typography>
                      <Typography variant="subtitle1" textAlign="center">
                        seconds
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              {!isWorking ? (
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<AlarmOnOutlined />}
                  size="large"
                  onClick={() => clockIn()}
                >
                  Clock In
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<AlarmOffOutlined />}
                  size="large"
                  onClick={() => clockOut()}
                >
                  Clock Out
                </Button>
              )}
            </HorizBox>
          )}
        </BoxSpaceBetween>
      </StandardAppContainerRounded>
      <TabsWithActions
        currentTabVariable={tab}
        onTabChange={(newTab) => setTab(newTab)}
        tabs={[
          { title: "My Account", action: <></> },
          { title: "Timesheet", action: <></> },
          { title: "Leaves", action: <></> },
          { title: "Request", action: <></> },
          { title: "Pay Slip", action: <></> },
        ]}
      />
      {tab === "My Account" && <MyAccount finrel={finrel} />}
      {tab === "Timesheet" && (
        <Timesheet
          currentTimesheet={currentTimesheet}
          timesheets={timesheets}
          timesheetsLoading={timesheetsLoading}
          finrel={finrel}
          workStartTime={workStartTime}
          workEndTime={workEndTime}
          isWorking={isWorking}
        />
      )}
      {tab === "Leaves" && <Leaves finrel={finrel} />}
      {tab === "Request" && <></>}
      {tab === "Pay Slip" && <PaySlip finrel={finrel} />}
    </Box>
  );
};

export default MyWorkHome;

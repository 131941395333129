import React from "react";
import TextField from "./TextField";
import { InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";

const SearchField = ({ placeholder = "Search...", ...props }) => {
  return (
    <TextField
      {...props}
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search fontSize="small" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;

import React, { useEffect, useState } from "react";
import StandardContainer from "../../../../../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../../../../../styled/generic/StandardAppContainerRounded";
import FormBox from "../../../../../styled/generic/FormBox";
import TextField from "../../../../../styled/generic/TextField";
import SpaceBetween from "../../../../../styled/generic/SpaceBetween";
import DuoButtonGroup from "../../../../../styled/generic/DuoButtonGroup";
import {
  createSalaryComponent,
  getSalaryComponent,
  updateSalaryComponent,
} from "./api";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const Reimbursement = ({ componentId, salaryStructure }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const { organizationId } = useParams();

  const [componentName, setComponentName] = useState("");
  const [amount, setAmount] = useState("");

  const [reimbursement, setReimbursement] = useState(null);

  const createReimbursement = async () => {
    const { data } = await createSalaryComponent({
      type: "Reimbursement",
      name: componentName,
      amount: amount,
      organization: organizationId,
      author: user?._id,
      salaryStructure: salaryStructure?._id,
    });
    if (data) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Salary Component created successfully",
        },
      });
      history.push(`/hr/settings/${organizationId}?view=SalaryComponents`);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const getReimbursement = async () => {
    try {
      const { data } = await getSalaryComponent({
        _id: componentId,
      });
      setReimbursement(data);
      setComponentName(data?.name);
      setAmount(data?.amount);
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get reimbursement",
        },
      });
    }
  };

  const updateReimbursement = async () => {
    try {
      const { data } = await updateSalaryComponent({
        _id: componentId,
        updateBody: {
          name: componentName,
          amount: amount,
        },
      });
      setReimbursement(data);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Salary Component updated successfully",
        },
      });
      history.push(`/hr/settings/${organizationId}?view=SalaryComponents`);
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get earning",
        },
      });
    }
  };

  useEffect(() => {
    if (componentId) {
      getReimbursement();
    }
  }, [componentId]);

  return (
    <StandardContainer>
      <StandardAppContainerRounded>
        <SpaceBetween
          left={
            <FormBox label="Component Name">
              <TextField
                placeholder="Enter"
                fullWidth
                value={componentName}
                onChange={(e) => setComponentName(e.target.value)}
              />
            </FormBox>
          }
          right={
            <FormBox label="Amount">
              <TextField
                placeholder="Enter"
                fullWidth
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </FormBox>
          }
        />
        <DuoButtonGroup
          primaryButtonText={
            reimbursement ? "Update Reimbursement" : "Create Reimbursement"
          }
          primaryButtonListener={() => {
            if (reimbursement) {
              updateReimbursement();
            } else {
              createReimbursement();
            }
          }}
          secondaryButtonText="Cancel"
          secondaryButtonListener={() => {
            history.push(
              `/hr/settings/${organizationId}?view=SalaryComponents`
            );
          }}
        />
      </StandardAppContainerRounded>
    </StandardContainer>
  );
};

export default Reimbursement;

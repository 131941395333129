import {
  AccountBalance,
  AlternateEmail,
  BusinessOutlined,
  CheckBox,
  CheckBoxOutlineBlank,
  Email,
  LanguageOutlined,
  LocalAtmOutlined,
  LocationOnOutlined,
  PaymentOutlined,
  Person,
  Phone,
  PhoneAndroid,
} from "@material-ui/icons";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import getGeocodeFromZip from "../../helpers/geoCode";
import Api from "../../helpers/Api";

const StyledTextField = ({ label, type, icon, size, value, onChange }) => {
  return (
    <FormControl fullWidth variant="outlined">
      <OutlinedInput
        type={type ? type : "text"}
        startAdornment={
          <InputAdornment position="start">{icon}</InputAdornment>
        }
        placeholder={label}
        size={size}
        value={value}
        onChange={(e) => onChange(e)}
      />
    </FormControl>
  );
};

const PalFormDrawer = ({
  text,
  onSelect,
  setLoading,
  open,
  setOpen,
  relationType,
}) => {
  const { user, userProfile } = useSelector((state) => state.auth);
  const [type, setType] = useState("User");
  const [fullName, setFullName] = useState(text || "");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [website, setWebsite] = useState("");
  const [PAN, setPAN] = useState("");
  const [taxId, setTaxId] = useState("");
  const [costCode, setCostCode] = useState("");
  const [hourlyRate, setHourlyRate] = useState("");

  const relations = [
    "Contractor",
    "Customer",
    "Employee",
    "Investor",
    "Lender",
    "Vendor",
    "Tenant",
  ];

  const [userRelation, setUserRelation] = useState(false);
  const [projectRelation, setProjectRelation] = useState(false);
  const [orgRelation, setOrgRelation] = useState(false);
  const [userRelationVal, setUserRelationVal] = useState(
    relations.find((r) => r === relationType) ? relationType : relations[0]
  );
  const [projectRelationVal, setProjectRelationVal] = useState(
    relations.find((r) => r === relationType) ? relationType : relations[0]
  );
  const [orgRelationVal, setOrgRelationVal] = useState(
    relations.find((r) => r === relationType) ? relationType : relations[0]
  );
  const [projectRelationData, setProjectRelationData] = useState([]);
  const [orgRelationData, setOrgRelationData] = useState([]);

  const [projectOptions, setProjectOptions] = useState([]);
  const [orgOptions, setOrgOptions] = useState([]);

  const getBasicData = async () => {
    const res = await Api.post("shared/getBasicData", {
      userProfileId: user.profile,
    });

    if (res?.data) {
      const data = res?.data;
      const orgTeamRes = data.orgTeams;
      const projectTeamRes = data.projectTeams;
      let projects = projectTeamRes.map((item) => item.parent);
      let orgs = orgTeamRes.map((item) => item.parent);
      setProjectOptions(projects);
      setOrgOptions(orgs);
    }
  };

  useEffect(() => {
    getBasicData();
  }, []);

  const createPal = async () => {
    let zipCodeData = {};
    setLoading(true);
    if (zip.length > 0) {
      zipCodeData = await getGeocodeFromZip(zip);
    }

    await Api.post("pal/create", {
      type: type,
      address: {
        ...zipCodeData,
        postal_code: zip,
        line1: address,
      },
      displayName: fullName,
      email: email.toLowerCase(),
      phone: phone,
      mobile: mobile,
      companyName: companyName,
      companyPhone: companyPhone,
      zip: zip,
      website: website,
      pan: PAN,
      taxId: taxId,
      parent: user._id,
      parentModelName: user.model,
      user: user.model === "User" ? user._id : userProfile._id,
      userProfile: user.profile,
      userRelation,
      projectRelation,
      orgRelation,
      userRelationVal,
      projectRelationVal,
      orgRelationVal,
      projectRelationData,
      orgRelationData,
      costCode: costCode,
      hourlyRate: hourlyRate
    }).then((res) => {
      const data = res?.data;

      if (onSelect && data) {
        onSelect(data);
      }
    });

    setLoading(false);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
      sx={{ zIndex: 2222 }}
    >
      <Box sx={{ width: "60vw", px: 4, py: 2 }}>
        <Typography variant="h5">Add new {type}</Typography>
        <FormControl>
          <RadioGroup row value={type} onChange={(evt, val) => setType(val)}>
            <FormControlLabel value="User" control={<Radio />} label="User" />
            <FormControlLabel
              value="Organization"
              control={<Radio />}
              label="Organization"
            />
            <FormControlLabel value="Group" control={<Radio />} label="Group" />
          </RadioGroup>
        </FormControl>
        <Grid container spacing={2} style={{ marginTop: "5px" }}>
          <Grid item xs={6}>
            <StyledTextField
              label="Full Name"
              size="small"
              icon={<Person />}
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <StyledTextField
              fullWidth
              variant="outlined"
              label="Cost Code"
              size="small"
              icon={<AccountBalance />}
              value={costCode}
              onChange={(e) => setCostCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              fullWidth
              variant="outlined"
              label="Hourly Rate"
              size="small"
              icon={<PaymentOutlined />}
              value={hourlyRate}
              onChange={(e) => setHourlyRate(e.target.value)}
            />
          </Grid>

          {type !== "Group" && (
            <>
              <Grid item xs={6}>
                <StyledTextField
                  label="Email"
                  size="small"
                  icon={<AlternateEmail />}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  label="Mobile"
                  size="small"
                  icon={<PhoneAndroid />}
                  type="email"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  label="Telephone"
                  size="small"
                  type="number"
                  icon={<Phone />}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Grid>
              {type === "User" && (
                <>
                  <Grid item xs={6}>
                    <StyledTextField
                      label="Company Name"
                      size="small"
                      icon={<BusinessOutlined />}
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledTextField
                      fullWidth
                      label="Company Phone"
                      size="small"
                      icon={<Phone />}
                      value={companyPhone}
                      onChange={(e) => setCompanyPhone(e.target.value)}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  label="Address"
                  size="small"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  label="Zip"
                  size="small"
                  type="number"
                  icon={<LocationOnOutlined />}
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  label="Website"
                  size="small"
                  icon={<LanguageOutlined />}
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  label="PAN"
                  size="small"
                  icon={<LocalAtmOutlined />}
                  value={PAN}
                  onChange={(e) => setPAN(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  label="TAX ID"
                  size="small"
                  icon={<PaymentOutlined />}
                  value={taxId}
                  onChange={(e) => setTaxId(e.target.value)}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Add In Directory
              </Typography>
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box style={{ width: "190px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userRelation}
                          onChange={(evt, checked) => setUserRelation(checked)}
                        />
                      }
                      label="With Myself"
                    />
                  </Box>

                  <Select
                    MenuProps={{
                      style: { zIndex: 4444 },
                    }}
                    size="small"
                    value={userRelationVal}
                    onChange={(e) => setUserRelationVal(e.target.value)}
                    style={{ minWidth: "120px" }}
                  >
                    {relations.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box style={{ width: "190px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={projectRelation}
                          onChange={(evt, checked) =>
                            setProjectRelation(checked)
                          }
                        />
                      }
                      label="With Project"
                    />
                  </Box>
                  <Autocomplete
                    multiple
                    disablePortal
                    id="checkboxes-tags-demo"
                    options={projectOptions}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.displayName}
                    onChange={(evt, val) => setProjectRelationData(val)}
                    componentsProps={{ zIndex: 3333 }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                          checkedIcon={<CheckBox fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.displayName}
                      </li>
                    )}
                    style={{ width: "200px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Projects"
                        placeholder="Select Projects"
                        size="small"
                      />
                    )}
                  />

                  <Select
                    MenuProps={{
                      style: { zIndex: 4444 },
                    }}
                    size="small"
                    value={projectRelationVal}
                    onChange={(e) => setProjectRelationVal(e.target.value)}
                    style={{ minWidth: "120px" }}
                  >
                    {relations.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box style={{ width: "190px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={orgRelation}
                          onChange={(evt, checked) => setOrgRelation(checked)}
                        />
                      }
                      label="With Org"
                    />
                  </Box>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={orgOptions}
                    disablePortal
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.displayName}
                    onChange={(evt, val) => setOrgRelationData(val)}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                          checkedIcon={<CheckBox fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.displayName}
                      </li>
                    )}
                    style={{ width: "200px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Organizations"
                        placeholder="Select Organizations"
                        size="small"
                      />
                    )}
                  />

                  <Select
                    MenuProps={{
                      style: { zIndex: 4444 },
                    }}
                    size="small"
                    value={orgRelationVal}
                    onChange={(e) => setOrgRelationVal(e.target.value)}
                    style={{ minWidth: "120px" }}
                    disablePortal
                  >
                    {relations.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Button
          fullWidth
          variant="contained"
          onClick={() => createPal()}
          style={{ marginTop: "10px" }}
        >
          Create
        </Button>
      </Box>
    </Drawer>
  );
};

export default PalFormDrawer;

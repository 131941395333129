import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import FluidBox from "../styled/generic/FluidBox";
import Image from "../styled/generic/Image";
import FormBox from "../styled/generic/FormBox";
import HorizBox from "../styled/generic/HorizBox";
import TextField from "../styled/generic/TextField";
import { Button } from "@mui/material";
import { inviteAccept } from "../team/api.call";
import teamUtils from "../team/team.utils";
import AccessControlStoreHook from "../AccessControl/AccessControlStoreHook";

const ChooseOrgView = ({ getMyWork }) => {
  const dispatch = useDispatch();
  const { handleTeams } = teamUtils;
  const { updateAccessByTeam } = AccessControlStoreHook()

  const state = useSelector((state) => state);
  const { user } = useSelector((state) => state.auth);
  let { invitations } = useSelector((state) => state.team);

  const [accessCode, setAccessCode] = useState("")
  const [loader, setLoader] = useState(false)

  const joinInvitation = async () => {
    setLoader(true);
    await inviteAccept({
      invite: accessCode,
      acceptBy: "accessCode",
      profile: user.profile,
    })
      .then(async (teamData) => {
        if (teamData?.parentModelName === "Project" || teamData?.parentModelName === "Organization") {
          handleTeams([teamData], state, dispatch);
          updateAccessByTeam(teamData?._id)
        }
        let filteredInvites = invitations.filter((el) => el.accessCode !== accessCode);
        dispatch({
          type: "AddTeam",
          payload: {
            invitations: filteredInvites,
          },
        });
        await getMyWork();
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }



  return (
    <StandardAppContainerRounded fullHeight={true}>
      <FluidBox>
        <Image
          src="/icons/my_work_illustration.png"
          height="300px"
          objectFit="contain"
        />
        <FormBox label="Enter Invitation Code to join Organisation">
          <HorizBox>
            <TextField
              placeholder="Enter Invitation Code"
              variant="outlined"
              value={accessCode}
              onChange={(e) => { setAccessCode(e.target.value) }}
              fullWidth={true}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={loader ? true : accessCode && accessCode.length > 0 ? false : true}
              onClick={joinInvitation}
            >
              Join
            </Button>
          </HorizBox>
        </FormBox>
      </FluidBox>
    </StandardAppContainerRounded>
  );
};

export default ChooseOrgView;
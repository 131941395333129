import React from "react";
import "./template.css";

const Template = ({
  showOrganizationAddress,
  showDoj,
  showPan,
  showWorkLocation,
  showDepartment,
  showDesignation,
}) => {
  return (
    <div class="p-6">
      {/* <!--HEADER AND LOGO --> */}
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <img
            src="https://cdn.pixabay.com/photo/2016/11/07/13/04/yoga-1805784_1280.png"
            class="h-20 w-20"
          />
          <div>
            <div class="font-bold">ABC Organization</div>
            {showOrganizationAddress && (
              <div class="text-sm font-light">Budhanagar, Delhi</div>
            )}
          </div>
        </div>

        <div>
          <div>
            <div class="text-sm font-light">Payslip of</div>
            <div class="text-sm font-semibold">December 2023</div>
          </div>
        </div>
      </div>

      {/* <!--DIVIDER--> */}
      <div class="h-[1px] bg-[rgba(0,0,0,0.10)]"></div>

      {/* <!-- EMPLOYEE SUMMARY --> */}
      <div class="mt-4 text-[#2D76E0]">Employee Summary</div>
      <div class="flex items-start justify-between">
        <table class="mt-2 border-collapse text-sm">
          <tr>
            <td class="text-zinc-700">Employee Name:</td>
            <td class="pl-2 font-semibold text-zinc-900">John Smith</td>
          </tr>
          {showDesignation && (
            <tr>
              <td class="text-zinc-700">Designation:</td>
              <td class="pl-2 font-semibold text-zinc-900">
                Accounts Executive
              </td>
            </tr>
          )}
          {showPan && (
            <tr>
              <td class="text-zinc-700">PF A/C No:</td>
              <td class="pl-2 font-semibold text-zinc-900">AA/AA</td>
            </tr>
          )}
          <tr>
            <td class="text-zinc-700">EBank A/C No:</td>
            <td class="pl-2 font-semibold text-zinc-900">AA/AA</td>
          </tr>
        </table>

        <table class="mt-2 border-collapse text-sm">
          {showDoj && (
            <tr>
              <td class="text-zinc-700">Date of joining:</td>
              <td class="pl-2 font-semibold text-zinc-900">22-03-2023</td>
            </tr>
          )}
          <tr>
            <td class="text-zinc-700">Pay Date:</td>
            <td class="pl-2 font-semibold text-zinc-900">December 30</td>
          </tr>
          {showDepartment && (
            <tr>
              <td class="text-zinc-700">Department:</td>
              <td class="pl-2 font-semibold text-zinc-900">Finance</td>
            </tr>
          )}
          {showWorkLocation && (
            <tr>
              <td class="text-zinc-700">Location:</td>
              <td class="pl-2 font-semibold text-zinc-900">Delhi</td>
            </tr>
          )}
        </table>

        <table class="mt-2 border-collapse border border-gray-500 text-sm">
          <tr>
            <td class="border border-gray-500 px-2 py-1 text-zinc-700">
              Paid Days
            </td>
            <td class="border border-gray-500 px-2 py-1 font-semibold text-zinc-900">
              28
            </td>
          </tr>
          <tr>
            <td class="border border-gray-500 px-2 py-1 text-zinc-700">
              LOP days
            </td>
            <td class="border border-gray-500 px-2 py-1 font-semibold text-zinc-900">
              03
            </td>
          </tr>
        </table>
      </div>

      {/* <!-- SALARY SUMMARY --> */}
      <div class="mt-4 text-[#2D76E0]">Salary Calculations</div>

      <table class="mt-2 w-full table-auto border-collapse border border-gray-500 text-sm">
        <tr>
          <td class="border border-gray-500 px-3 py-2 font-semibold text-zinc-900">
            EARNINGS
          </td>
          <td class="border border-gray-500 px-3 py-2 font-semibold text-zinc-900">
            AMOUNT
          </td>
          <td class="border border-gray-500 px-3 py-2 font-semibold text-zinc-900">
            DEDUCTIONS
          </td>
          <td class="border border-gray-500 px-3 py-2 font-semibold text-zinc-900">
            AMOUNT
          </td>
        </tr>
        <tr>
          <td class="border border-gray-500 px-3 py-2">Base Salary</td>
          <td class="border border-gray-500 px-3 py-2 font-semibold">₹000</td>
          <td class="border border-gray-500 px-3 py-2">EPF</td>
          <td class="border border-gray-500 px-3 py-2 font-semibold">₹000</td>
        </tr>
        <tr>
          <td class="border border-gray-500 px-3 py-2">Base Salary</td>
          <td class="border border-gray-500 px-3 py-2 font-semibold">₹000</td>
          <td class="border border-gray-500 px-3 py-2">EPF</td>
          <td class="border border-gray-500 px-3 py-2 font-semibold">₹000</td>
        </tr>
        <tr>
          <td class="border border-gray-500 px-3 py-2">Base Salary</td>
          <td class="border border-gray-500 px-3 py-2 font-semibold">₹000</td>
          <td class="border border-gray-500 px-3 py-2">EPF</td>
          <td class="border border-gray-500 px-3 py-2 font-semibold">₹000</td>
        </tr>
        <tr class="bg-[#F5F7FE]">
          <td class="border border-gray-500 px-3 py-2">Total Earnings</td>
          <td class="border border-gray-500 px-3 py-2 font-semibold">₹000</td>
          <td class="border border-gray-500 px-3 py-2">Total Deductions</td>
          <td class="border border-gray-500 px-3 py-2 font-semibold">₹000</td>
        </tr>
      </table>

      <table class="mt-4 w-full table-auto border-collapse border border-gray-500 text-sm">
        <tr>
          <td class="border border-gray-500 px-3 py-2 font-semibold text-zinc-900">
            REIMBURSMENTS
          </td>
          <td class="border border-gray-500 px-3 py-2 font-semibold text-zinc-900">
            AMOUNT
          </td>
        </tr>
        <tr>
          <td class="border border-gray-500 px-3 py-2">Petrol</td>
          <td class="border border-gray-500 px-3 py-2 font-semibold">₹000</td>
        </tr>
        <tr>
          <td class="border border-gray-500 px-3 py-2">Fuel</td>
          <td class="border border-gray-500 px-3 py-2 font-semibold">₹000</td>
        </tr>

        <tr class="bg-[#F5F7FE]">
          <td class="border border-gray-500 px-3 py-2">Total Reimbursments</td>
          <td class="border border-gray-500 px-3 py-2 font-semibold">₹000</td>
        </tr>
      </table>

      <div class="mt-6 flex items-center justify-between rounded-md border border-black/10">
        <div class="px-4 py-4">
          <div class="text-md font-semibold">September Net Salary</div>
          <div class="text-sm">Gross Earning- Deduction</div>
        </div>
        <div class="self-stretch border"></div>
        <div class="px-4 py-4 text-xl font-semibold text-[#2D76E0]">
          ₹0000000
        </div>
      </div>
      <div class="flex items-center justify-end text-sm mt-2">
        Amount In Words : Indian Rupee Lorium Ipsm Only
      </div>
    </div>
  );
};

export default Template;

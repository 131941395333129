import React, { useEffect, useState } from "react";
import StandardAppContainer from "../../styled/generic/StandardAppContainer";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import {
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import HorizBox from "../../styled/generic/HorizBox";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import SearchField from "../../styled/generic/SearchField";
import FilterButton from "../../styled/generic/FilterButton";
import TableContainer from "../../styled/generic/TableContainer";
import Button from "../../styled/generic/Button";
import {
  AddAlarmOutlined,
  AlarmOffOutlined,
  AlarmOnOutlined,
} from "@mui/icons-material";
import { useDebounce } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helpers/Api";
import DateText from "../../styled/generic/DateText";
import MoreButton from "../../styled/generic/MoreButton";

const Timesheet = ({
  currentTimesheet,
  timesheets,
  timesheetsLoading,
  finrel,
  isWorking,
  workStartTime,
  workEndTime,
}) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [today, setToday] = useState(new Date());

  return (
    <StandardAppContainerRounded>
      {currentTimesheet?._id && (
        <Box
          sx={{
            border: "1px solid rgba(0,0,0,0.10)",
            borderRadius: "10px",
            px: 2,
            py: 4,
            mt: 3,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4} alignSelf="center">
              <HorizBox spacing={1}>
                <Typography variant="h6">
                  {dayjs(currentTimesheet?.date).format("DD MMM YYYY")}
                </Typography>
                <Typography variant="h6" color="primary">
                  ({dayjs(currentTimesheet?.date).format("dddd")})
                </Typography>
              </HorizBox>
            </Grid>
            <Grid item xs={4} alignSelf="center">
              <Typography variant="h6">
                Work Schedule (
                {dayjs(currentTimesheet?.workSchedule?.shiftStart).format(
                  "hh:mm A"
                ) +
                  " to " +
                  dayjs(currentTimesheet?.workSchedule?.shiftEnd).format(
                    "hh:mm A"
                  )}
                )
              </Typography>
            </Grid>
            <Grid item xs={4} alignSelf="center">
              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                }}
              >
                <Typography
                  color="success"
                  variant="subtitle1"
                  sx={{ fontWeight: "bold" }}
                >
                  Clocked In:{" "}
                  {workStartTime
                    ? dayjs(workStartTime).format("hh:mm A")
                    : "Pending"}
                </Typography>
                <Typography
                  color="success"
                  variant="subtitle1"
                  sx={{ fontWeight: "bold" }}
                >
                  Clocked Out:{" "}
                  {workEndTime
                    ? dayjs(workEndTime).format("hh:mm A")
                    : "Pending"}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Box>
      )}

      <BoxSpaceBetween sx={{ mt: 3 }}>
        <SearchField placeholder="Search" size="small" sx={{ width: 300 }} />
        <FilterButton />
      </BoxSpaceBetween>
      <Box>
        <TableContainer
          columns={[
            "DATE",
            "DAY",
            "CLOCK IN",
            "CLOCK OUT",
            "TOTAL TIME",
            "REMARKS",
            "ACTIONS",
          ]}
          data={timesheets}
          loading={timesheetsLoading}
          boldTextEnabled={true}
        >
          {timesheets.map((timesheet) => (
            <tr>
              <td>
                <DateText date={dayjs(timesheet?.date)} />
              </td>
              <td>
                <Typography variant="subtitle1">
                  {dayjs(timesheet?.date).format("dddd")}
                </Typography>
              </td>
              <td>
                <Typography variant="subtitle1">
                  {timesheet?.lastClockInTime
                    ? dayjs(timesheet?.lastClockInTime).format("hh:mm A")
                    : "-"}
                </Typography>
              </td>
              <td>
                <Typography variant="subtitle1">
                  {timesheet?.lastClockOutTime
                    ? dayjs(timesheet?.lastClockOutTime).format("hh:mm A")
                    : "-"}
                </Typography>
              </td>
              <td>
                <Typography variant="subtitle1">
                  {isNaN(
                    dayjs(timesheet?.lastClockOutTime).diff(
                      dayjs(timesheet?.lastClockInTime),
                      "hours",
                      true
                    )
                  )
                    ? 0
                    : dayjs(timesheet?.lastClockOutTime).diff(
                        dayjs(timesheet?.lastClockInTime),
                        "hours",
                        true
                      )}{" "}
                  {" hours"}
                </Typography>
              </td>
              <td>
                <Typography variant="subtitle1">
                  {timesheet?.remarks || "-"}
                </Typography>
              </td>
              <td>
                <MoreButton />
              </td>
            </tr>
          ))}
        </TableContainer>
      </Box>
    </StandardAppContainerRounded>
  );
};

export default Timesheet;

import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../../../../styled/generic/StandardAppContainerRounded";
import { Box, IconButton, MenuItem } from "@mui/material";
import LabelWithSingleActionButton from "../../../../styled/generic/LabelWithSingleActionButton";
import { Add, DeleteOutline, EditOutlined } from "@mui/icons-material";
import SearchField from "../../../../styled/generic/SearchField";
import TableContainer from "../../../../styled/generic/TableContainer";
import Api from "../../../../../helpers/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import PopupMenuList from "../../../../styled/generic/PopupMenuList";
import MoreButton from "../../../../styled/generic/MoreButton";
import HorizBox from "../../../../styled/generic/HorizBox";
import DrawerContainer from "../../../../styled/generic/DrawerContainer";
import FormBox from "../../../../styled/generic/FormBox";
import TextField from "../../../../styled/generic/TextField";
import Select from "../../../../styled/generic/Select";
import DuoButtonGroup from "../../../../styled/generic/DuoButtonGroup";
import {
  ChromePicker,
  CirclePicker,
  SketchPicker,
  SwatchesPicker,
} from "react-color";
import PopupMenu from "../../../../styled/generic/PopupMenu";

const WorkEntryCode = () => {
  const { organizationId } = useParams();
  const dispatch = useDispatch();

  const [showCreateWorkEntryDrawer, setShowCreateWorkEntryDrawer] =
    useState(false);

  const [codes, setCodes] = useState([]);
  const [codesLoading, setCodesLoading] = useState(false);
  const [codeName, setCodeName] = useState("");
  const [abbreviation, setAbbreviation] = useState("");
  const [color, setColor] = useState("");
  const [category, setCategory] = useState("");
  const [code, setCode] = useState();
  const CATEGORY_OPTIONS = [
    "Working",
    "Leave",
    "Out",
    "Non Working Day",
    "Exception",
    "Over Time",
  ];

  const getAttendanceCodes = async () => {
    try {
      setCodesLoading(true);
      const { data } = await Api.post("/attendance-codes/get", {
        organizationId: organizationId,
      });
      if (data) {
        setCodes(data);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setCodesLoading(false);
    }
  };

  const createCode = async () => {
    try {
      const { data } = await Api.post("/attendance-codes/create", {
        organization: organizationId,
        name: codeName,
        abbreviation,
        color,
        category,
      });
      if (data) {
        setCodes([data, ...codes]);
        setShowCreateWorkEntryDrawer(false);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create attendance code",
        },
      });
    }
  };

  const updateCode = async () => {
    try {
      const { data } = await Api.post("/attendance-codes/update", {
        _id: code?._id,
        updateBody: {
          name: codeName,
          abbreviation,
          color,
          category,
        },
      });
      if (data) {
        setCodes(
          codes?.map((item) => {
            if (item?._id === data?._id) {
              return data;
            } else {
              return item;
            }
          })
        );
        setShowCreateWorkEntryDrawer(false);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create attendance code",
        },
      });
    }
  };

  const deleteCode = async () => {
    try {
      const { data } = await Api.post("/attendance-codes/delete", {
        _id: code?._id,
      });
      if (data?.message) {
        const { message, deleted } = data;
        if (deleted) {
          setCodes(codes?.filter((item) => item?._id !== code?._id));
          setShowCreateWorkEntryDrawer(false);
        }
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: deleted,
            message: message,
          },
        });
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to delete attendance code",
        },
      });
    }
  };

  useEffect(() => {
    getAttendanceCodes();
  }, []);

  useEffect(() => {
    if (code) {
      setCodeName(code?.name);
      setAbbreviation(code?.abbreviation);
      setColor(code?.color);
      setCategory(code?.category);
    } else {
      setCodeName("");
      setAbbreviation("");
      setColor("#BD10E0");
      setCategory(CATEGORY_OPTIONS[0]);
    }
  }, [showCreateWorkEntryDrawer]);

  return (
    <Box>
      <LabelWithSingleActionButton
        label="Work Entry Code"
        actionButtonIcon={<Add />}
        actionButtonText="Create"
        actionButtonVariant="contained"
        actionButtonListener={() => {
          setCode();
          setShowCreateWorkEntryDrawer(true);
        }}
      />
      <StandardAppContainerRounded>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <SearchField size="small" />
        </Box>
        <TableContainer
          columns={[
            "CODE NAME",
            "ABBREVIATION",
            "CODE COLOR",
            "TYPE",
            "ACTIONS",
          ]}
          data={codes}
          loading={codesLoading}
          boldTextEnabled={true}
        >
          {codes?.map((item) => (
            <tr>
              <td>{item.name}</td>
              <td>{item.abbreviation}</td>
              <td>
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    backgroundColor: item?.color,
                  }}
                ></div>
              </td>
              <td>{item?.category}</td>
              <td>
                <HorizBox>
                  <IconButton
                    onClick={() => {
                      setCode(item);
                      setShowCreateWorkEntryDrawer(true);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                  <IconButton disabled={item.flag} onClick={() => deleteCode()}>
                    <DeleteOutline />
                  </IconButton>
                </HorizBox>
              </td>
            </tr>
          ))}
        </TableContainer>
      </StandardAppContainerRounded>
      <DrawerContainer
        title="Work Entry Code"
        open={showCreateWorkEntryDrawer}
        setOpen={setShowCreateWorkEntryDrawer}
      >
        <FormBox label="Code name">
          <TextField
            placeholder="Enter"
            value={codeName}
            onChange={(e) => setCodeName(e.target.value)}
            fullWidth
          />
        </FormBox>
        <FormBox label="Abbreviation">
          <TextField
            placeholder="Enter"
            value={abbreviation}
            onChange={(e) => setAbbreviation(e.target.value)}
            fullWidth
          />
        </FormBox>
        <FormBox label="Category">
          <Select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            fullWidth
          >
            {CATEGORY_OPTIONS.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormBox>
        <FormBox label="Color">
          <PopupMenu
            appearContent={
              <Box
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  backgroundColor: color,
                }}
              />
            }
          >
            <SketchPicker
              color={color}
              onChange={(val) => setColor(val?.hex)}
            />
          </PopupMenu>
        </FormBox>
        <DuoButtonGroup
          primaryButtonText={code?._id ? "Update" : "Create"}
          primaryButtonListener={() => {
            if (code?._id) {
              updateCode();
            } else {
              createCode();
            }
          }}
          secondaryButtonText="Cancel"
          secondaryButtonListener={() => setShowCreateWorkEntryDrawer(false)}
        />
      </DrawerContainer>
    </Box>
  );
};

export default WorkEntryCode;

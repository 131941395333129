import React, { useState, useEffect } from "react";
import FormBox from "../generic/FormBox";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import keyConfig from "../../../config/keys.config";
import axios from "axios";
import { useDebounce } from "react-use";
import SpaceBetween from "../generic/SpaceBetween";

const useFullLocationForm = ({ size = "medium" }) => {
  const [placeText, setPlaceText] = useState("");
  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState();

  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [landMark, setLandMark] = useState("");
  const [zip, setZip] = useState("");

  const searchPlaces = async (text) => {
    const options = {
      method: "GET",
      url: "https://address-completion.p.rapidapi.com/v1/geocode/autocomplete",
      params: {
        text: text,
        limit: "15",
        lang: "en",
        countrycodes: "in",
      },
      headers: {
        "X-RapidAPI-Key": keyConfig?.rapidApi,
        "X-RapidAPI-Host": "address-completion.p.rapidapi.com",
      },
    };

    let response = await axios.request(options);

    let places = response?.data?.features;
    let formatted_places = [];

    for (let i = 0; i < places?.length; i++) {
      let place = places[i];

      if (place?.properties?.name) {
        formatted_places.push(place?.properties);
      } else {
        formatted_places.push({
          ...place?.properties,
          name: place?.properties?.address_line1,
        });
      }
    }

    setPlaces(formatted_places);
  };

  useDebounce(
    () => {
      if (placeText?.length >= 2) {
        searchPlaces(placeText);
      }
    },
    1000,
    [placeText]
  );

  const handleLocationAdd = (newPlace) => {
    if (newPlace?.place_id) {
      setSelectedPlace(newPlace);
      setPlaceText(newPlace?.name);
      setCity(newPlace?.city);
      setState(newPlace?.state);
      setAddress1(newPlace?.address_line1);
      setAddress2(newPlace?.address_line2);
      setLandMark(newPlace?.landMark);
      setZip(newPlace?.zip);
    }
  };

  const handleLocationUpdate = (key, value) => {
    if (key === "city") {
      setCity(value);
    } else if (key === "state") {
      setState(value);
    } else if (key === "address_line1") {
      setAddress1(value);
    } else if (key === "address_line2") {
      setAddress2(value);
    } else if (key === "landMark") {
      setLandMark(value);
    } else if (key === "zip") {
      setZip(value);
    }

    let temp = { ...selectedPlace };
    temp[key] = value;
    setSelectedPlace(temp);
  };

  const locationForm = (
    <Box>
      <FormBox label="Search by">
        <Box sx={{ mt: size === "small" ? 0 : 2 }}>
          <Autocomplete
            options={places}
            onChange={(evt, newPlace) => handleLocationAdd(newPlace)}
            fullWidth
            getOptionLabel={(option) => option?.name || option}
            popupIcon={<KeyboardArrowDown />}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                placeholder="Location"
                value={placeText}
                onChange={(e) => setPlaceText(e.target.value)}
                size={size}
              />
            )}
          />
        </Box>
      </FormBox>
      <SpaceBetween
        left={
          <FormBox label="City">
            <TextField
              fullWidth
              variant="outlined"
              value={city}
              onChange={(e) => {
                handleLocationUpdate("city", e.target.value);
              }}
              placeholder="City"
            />
          </FormBox>
        }
        right={
          <FormBox label="State">
            <TextField
              fullWidth
              variant="outlined"
              value={state}
              onChange={(e) => {
                handleLocationUpdate("state", e.target.value);
              }}
              placeholder="State"
            />
          </FormBox>
        }
      />
      <SpaceBetween
        left={
          <FormBox label="Address 1">
            <TextField
              fullWidth
              variant="outlined"
              value={address1}
              onChange={(e) => {
                handleLocationUpdate("address_line1", e.target.value);
              }}
              placeholder="Address Line 1"
            />
          </FormBox>
        }
        right={
          <FormBox label="Address 2">
            <TextField
              fullWidth
              variant="outlined"
              value={address2}
              onChange={(e) => {
                handleLocationUpdate("address_line2", e.target.value);
              }}
              placeholder="Address Line 2"
            />
          </FormBox>
        }
      />
      <SpaceBetween
        left={
          <FormBox label="Landmark">
            <TextField
              fullWidth
              variant="outlined"
              value={landMark}
              onChange={(e) => {
                handleLocationUpdate("landMark", e.target.value);
              }}
              placeholder="Landmark"
            />
          </FormBox>
        }
        right={
          <FormBox label="Zip">
            <TextField
              fullWidth
              variant="outlined"
              value={zip}
              onChange={(e) => {
                handleLocationUpdate("zip", e.target.value);
              }}
              placeholder="Zip"
            />
          </FormBox>
        }
      />
    </Box>
  );

  return {
    locationForm,
    location: selectedPlace,
    setLocation: setSelectedPlace,
    city,
    setCity,
    state,
    setState,
    address1,
    setAddress1,
    address2,
    setAddress2,
    landMark,
    setLandMark,
    zip,
    setZip,
  };
};

export default useFullLocationForm;

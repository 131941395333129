import React, { useEffect } from "react";
import StandardAppContainer from "../../styled/generic/StandardAppContainer";
import StandardContainer from "../../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import SpaceBetween from "../../styled/generic/SpaceBetween";
import FormBox from "../../styled/generic/FormBox";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "../../styled/generic/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import { TbDotsCircleHorizontal } from "react-icons/tb";
import Skeleton from "@material-ui/lab/Skeleton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { useState } from "react";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../styled/generic/Select";
import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import DatePicker from "../../styled/generic/DatePicker";
import { searchUsers } from "../../contacts/api.call";
import Autocomplete from "../../styled/generic/Autocomplete";
import { useDebounce } from "react-use";
import UserSearcher from "../../styled/generic/UserSearcher";
import TableContainer from "../../styled/generic/TableContainer";
import TextFieldWithIcon from "../../styled/generic/TextFieldWithIcon";
import HorizBox from "../../styled/generic/HorizBox";
import {
  Add,
  ConnectWithoutContactOutlined,
  CurrencyRupeeSharp,
  DeleteOutline,
  PercentOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import CreatePalDrawer from "../../AddMember/CreatePalDrawer";
import EntitySearch from "../../styled/CommonComponents/EntitySearch";
import { useTableDStyles } from "../../../utils/style";
import { update } from "lodash";
import { Paper } from "@material-ui/core";
import ParticipantAddBody from "../../AccessControl/Participant.Add.Body";
import { useGetAccessOptionWithStucther } from "../../AccessControl/AccessControl.Hook";
import {
  addParticipants,
  getAllAccessRolesByTeam,
  getParticipantsByUserAndTeam,
  updateParticipant,
} from "../../AccessControl/api.call";
import {
  createAndUpdateFinalcialRelation,
  getProfileForEntitySearch,
} from "../../styled/CommonComponents/api.call";
import * as Yup from "yup";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import PaginatedEntityDropdown from "../../styled/CommonComponents/PaginatedEntityDropdown";

const validationSchema = Yup.object().shape({
  finRelMainProfile: Yup.object().shape({
    _id: Yup.string().required("Please select an employee"),
  }),
  department: Yup.string().required("Please select a department"),
  designation: Yup.string().required("Please select a designation"),
  doj: Yup.date().required("Please select a date of joining"),
  workLocation: Yup.string().required("Please select a work location"),
  workSchedule: Yup.string().required("Please select a work schedule"),
  contractStartDate: Yup.date().required("Please select a contract start date"),
  salaryStructure: Yup.string().required("Please select a salary structure"),
  payCycle: Yup.string().required("Please select a pay cycle"),
  paidLeaves: Yup.string().required("Please select a paid leaves"),
  salaryDetails: Yup.array().min(1, "Please add salary components"),
  leaves: Yup.array()
    .min(1, "Please add leaves")
    .of(
      Yup.object().shape({
        leave: Yup.string().required("Please select leaves"),
      })
    ),
  financialRelationType: Yup.string().required("Please select role"),
  selectedRoles: Yup.array().min(1, "Please select access role"),
});

const useStyles = makeStyles((theme) => ({
  accessContain: {
    width: "100%",
    padding: "25px",
  },
  accessHeaderCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    "& .MuiTypography-h5": {
      margin: "0px",
    },
  },
  iconText: {
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    "& p": {
      fontSize: "15px",
      fontWeight: "500",
      color: "gray",
      marginRight: "5px",
    },
  },
  showSelectedEntity: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  showEntityInfoCont: {
    marginLeft: "5px",
    "& h3": {
      fontSize: "16px",
      fontWeight: "550",
    },
    "& p": {
      fontSize: "12px",
      fontWeight: "400",
      color: "gray",
    },
  },
  emptyTextStyle: {
    width: "100%",
    padding: "30px 0px",
    textAlign: "center",
    fontSize: "14px",
    color: "gray",
    fontWeight: "400",
  },
}));

const mainRoles = [
  "Owner",
  "Admin",
  "Viewer",
  "Employee",
  "Contractor",
  "Worker",
  "Other",
];

const defaultSerachParameter = [
  {
    entity: "User",
    scop: [
      {
        type: "Global",
        role: [],
      },
      {
        type: "Contact",
        role: [...mainRoles],
      },
    ],
  },
];

const EditEmployee = () => {
  const { organizationId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const salaryTableStyle = useTableDStyles();
  const searchParams = new URLSearchParams(location.search);

  const { user } = useSelector((state) => state.auth);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);

  const [employeeId, setEmployeeId] = useState();
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [locations, setLocations] = useState([]);
  const [workSchedules, setWorkSchedules] = useState([]);
  const [salaryStructures, setSalaryStructures] = useState([]);
  const [showPalDrawer, setShowPalDrawer] = useState(false);
  const [preventFetchingSalaryComponents, setPreventFetchingSalaryComponents] =
    useState(false); // Dont fetch them for first time on salary structure change when in update mode
  const PAY_CYCLE_OPTIONS = [
    "Daily",
    "Weekly",
    "Bi-weekly",
    "Semi-monthly",
    "Monthly",
    "Bi-Monthly",
    "Quarterly",
    "Semi-annually",
    "Annually",
  ];
  const PAID_LEAVES = ["15FY", "20FY", "25FY", "30FY", "35FY", "40FY"];
  const [loadingEmployeeDetails, setLoadingEmployeeDetails] = useState(true);
  const [loadingHROptions, setLoadingHROptions] = useState(true);
  const [existingEmployees, setExistingEmployees] = useState([]);

  // Employee
  const [employee, setEmployee] = useState();
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [doj, setDoj] = useState(dayjs());
  const [workLocation, setWorkLocation] = useState("");
  const [manager, setManager] = useState();
  const [mentor, setMentor] = useState();
  const [finRelMainProfile, setFinRelMainProfile] = useState();
  const [openEditEmployee, setOpenEditEmployee] = useState(false);
  const [totalSalary, setTotalSalary] = useState(0);
  const [employeeLoading, setEmployeeLoading] = useState(false);

  // Contract
  const [workSchedule, setWorkSchedule] = useState("");
  const [contractStartDate, setContractStartDate] = useState(dayjs());
  const [salaryStructure, setSalaryStructure] = useState("");
  const [payCycle, setPayCycle] = useState(PAY_CYCLE_OPTIONS[4]);
  const [paidLeaves, setPaidLeaves] = useState(PAID_LEAVES[0]);
  const [salaryComponents, setSalaryComponents] = useState([]);
  const [salaryDetails, setSalaryDetails] = useState([]);
  const [leaveOptions, setLeaveOptions] = useState([]);
  const [leaves, setLeaves] = useState([{ leave: "", balance: null }]);

  //access
  const [teamData, setTeamData] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [financialRelationType, setFinancialRelationType] =
    useState("Employee");
  const [selectedAcccessIds, setSelectedAcccessIds] = useState([]);
  const [oldSelectedAcccessIds, setOldSelectedAcccessIds] = useState([]);
  const [accessRoles, setAccessRoles] = useState([]);
  const [participantData, setParticipantData] = useState();
  const [participantDataLoader, setParticipantDataLoader] = useState(false);

  const [mainProfileWitParent, setMainProfileWithParent] = useState(null);
  const [profileIdArr, setProfileIdArr] = useState([]);

  const { accessOptions, setAccessOptions, idObjectMap, setIdObjectMap } =
    useGetAccessOptionWithStucther(teamData?.parent?.profile?._id);

  useEffect(() => {
    if (organizationId) {
      getProfileForEntitySearch({
        entityId: organizationId,
        type: "Organization",
      })
        .then((data) => {
          const profiles = data?.profiles || [];
          const currentProfileWithParent = data?.currentProfileWithParent;
          let locProfileIdArr = new Set([...profiles]);
          setProfileIdArr([...locProfileIdArr]);
          setMainProfileWithParent(currentProfileWithParent);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [organizationId]);

  useEffect(() => {
    teamIds.map((teamId) => {
      if (
        teamDictionary &&
        teamDictionary[teamId] &&
        teamDictionary[teamId]?.parent &&
        teamDictionary[teamId]?.parent?._id == organizationId
      ) {
        setTeamData(teamDictionary[teamId]);
      }
    });
  }, [teamDictionary, teamIds, organizationId]);

  useEffect(() => {
    if (teamData?._id) {
      getAllAccessRolesByTeam({ team: teamData?._id })
        .then((data) => {
          setAccessRoles(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [teamData]);

  useEffect(() => {
    const _employeeId = searchParams.get("employeeId");
    if (_employeeId) {
      setEmployeeId(_employeeId);
    }
  }, [searchParams]);

  const getEmployeeDetails = async () => {
    try {
      setLoadingEmployeeDetails(true);
      const { data } = await Api.post("/employee/get-by-id", {
        employeeId: employeeId,
      });

      if (data) {
        setEmployee(data);
        setFinRelMainProfile(data?.mainProfile);
        setDepartment(data?.firstPartyContact?.department?._id);
        setDesignation(data?.firstPartyContact?.designation?._id);
        setDoj(dayjs(data?.firstPartyContact?.doj));
        setWorkLocation(data?.firstPartyContact?.workLocation?._id);
        setManager(data?.firstPartyContact?.manager);
        setMentor(data?.firstPartyContact?.mentor);

        setPreventFetchingSalaryComponents(true);
        setWorkSchedule(data?.defaultWorkSchedule?._id || "");
        setContractStartDate(dayjs(data?.payrollContract?.contractStartDate));
        setSalaryStructure(data?.payrollContract?.salaryStructure?._id || "");
        setPayCycle(data?.payrollContract?.payCycle);
        setPaidLeaves(data?.payrollContract?.paidLeaves);
        setTotalSalary(data?.payrollContract?.monthlySalary);
        setSalaryDetails(data?.payrollContract?.salaryDetails);
        if (data?.payrollContract?.leaves?.length > 0) {
          setLeaves(data?.payrollContract?.leaves);
        } else {
          setLeaves([{ leave: "", balance: null }]);
        }
        setPreventFetchingSalaryComponents(false);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch employee details",
        },
      });
    } finally {
      setLoadingEmployeeDetails(false);
    }
  };

  const getHROptions = async () => {
    try {
      setLoadingHROptions(true);
      const { data } = await Api.post("/employee/hr-options", {
        organizationId: organizationId,
      });
      if (data) {
        setDepartments(data?.departments);
        setDesignations(data?.designations);
        setLocations(data?.locations);
        setWorkSchedules(data?.workSchedules);
        setSalaryStructures(data?.salaryStructures);
        setLeaveOptions(data?.leaves);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setLoadingHROptions(false);
    }
  };

  const getExistingEmployees = async () => {
    try {
      const { data } = await Api.post("/employee/getProfiles", {
        organizationId,
      });

      if (data && Array.isArray(data)) {
        setExistingEmployees(data);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch existing employees",
        },
      });
    }
  };

  const getSalaryComponentsByStructure = async () => {
    try {
      const { data } = await Api.post(`/salary-component/get-by-structure`, {
        structureId: salaryStructure,
      });
      if (data) {
        setSalaryComponents(data);
        setSalaryDetails(
          data.map((item) => ({
            componentRef: item,
            amount: 0,
          }))
        );
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch salary components",
        },
      });
    }
  };

  const handleEmployeeData = async () => {
    try {
      setEmployeeLoading(true);
      const formData = {
        finRelMainProfile,
        department,
        designation,
        doj,
        workLocation,
        workSchedule,
        contractStartDate,
        salaryStructure,
        payCycle,
        paidLeaves,
        salaryDetails,
        leaves,
        financialRelationType,
        selectedRoles,
      };

      await validationSchema
        .validate(formData, { abortEarly: false })
        .then(async (validationRes) => {
          const mailDataObj = {
            reciverFirstName: finRelMainProfile?.data?.parent?.displayName
              ? finRelMainProfile?.data?.parent?.displayName.split(" ")[0]
              : "",
            senderName: user?.displayName,
            teamName: teamData?.parent?.displayName,
            reciverMail: finRelMainProfile?.data?.parent?.email,
            reciverFullName: finRelMainProfile?.data?.parent?.displayName,
          };

          const { data } = await Api.post("/employee/handle", {
            userId: user?._id,
            userProfileId: user?.profile,
            organizationId,
            finRelMainProfileId: finRelMainProfile?.data?._id,
            department,
            designation,
            doj,
            workLocation,
            manager,
            mentor,
            workSchedule,
            contractStartDate,
            salaryStructure,
            payCycle,
            paidLeaves,
            // annualCTC,
            mailDataObj,
            monthlySalary: totalSalary,
            salaryDetails: salaryDetails.map((item) => {
              return {
                ...item,
                componentRef: item?.componentRef?._id,
              };
            }),
            leaves: leaves,
          });
          const accessCodeNew = data?.invitation?.accessCode;
          if (data) {
            if (participantData?._id) {
              await updateAccessRole();
            } else {
              await addAccessRole(accessCodeNew);
            }
            dispatch({
              type: "AddApiAlert",
              payload: {
                success: true,
                message: "Invitation sent to employee successfully",
              },
            });
            history.goBack();
          }
        })
        .catch((err) => {
          let errorArr = err?.inner;
          let errMessage = null;
          errorArr.map((err) => {
            if (!errMessage) {
              errMessage = err?.message;
            }
          });
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: errMessage,
            },
          });
          return;
        });
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setEmployeeLoading(false);
    }
  };

  // const reCalculateSalaryComponents = async () => {
  //   try {
  //     if (!employee?._id) {
  //       dispatch({
  //         type: "AddApiAlert",
  //         payload: {
  //           success: false,
  //           message: "Please create a user first",
  //         },
  //       });
  //       return;
  //     }

  //     const { data } = await Api.post("/employee/refreshSalaryDetails", {
  //       organizationId,
  //       finRelMainProfileId: finRelMainProfile?.data?._id,
  //     });
  //     if (data) {
  //       setSalaryDetails(data);
  //     } else {
  //       dispatch({
  //         type: "AddApiAlert",
  //         payload: {
  //           success: false,
  //           message: "An unknown error occurred",
  //         },
  //       });
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     dispatch({
  //       type: "AddApiAlert",
  //       payload: {
  //         success: false,
  //         message: "An unknown error occurred",
  //       },
  //     });
  //   }
  // };

  const evaluateMathExpression = (expression, mongoComponents) => {
    // Convert expression to string
    const expressionString = String(expression);

    // Replace MongoDB IDs with their corresponding values
    const replacedExpression = expressionString.replace(
      /[_a-f0-9]{24}/gi,
      (match) => {
        const component = mongoComponents.find((c) => c._id === match);
        return component ? component.value : 0;
      }
    );

    // Check if the expression contains any functions
    if (/\b(IF|ROUND|CEIL|FLOOR|MAX)\b/i.test(replacedExpression)) {
      // Replace function calls with their evaluated values
      const evaluatedExpression = replacedExpression.replace(
        /\b(IF|ROUND|CEIL|FLOOR|MAX)\b\(([^()]*(\([^()]*\))?)*\)/gi,
        (match, functionName, functionArgs) => {
          const evaluatedArgs = splitFunctionArgs(
            functionArgs,
            mongoComponents
          ).map((arg) => evaluateMathExpression(arg, mongoComponents));

          switch (functionName.toUpperCase()) {
            case "IF":
              return evaluatedArgs[0] ? evaluatedArgs[1] : evaluatedArgs[2];
            case "ROUND":
              return (
                Math.round(evaluatedArgs[0] * Math.pow(10, evaluatedArgs[1])) /
                Math.pow(10, evaluatedArgs[1])
              );
            case "CEIL":
              return Math.ceil(evaluatedArgs[0]);
            case "FLOOR":
              return Math.floor(evaluatedArgs[0]);
            case "MAX":
              return Math.max(...evaluatedArgs);
            default:
              throw new Error(`Unknown function: ${functionName}`);
          }
        }
      );

      // Evaluate the expression without functions
      return evaluateSimpleExpression(evaluatedExpression);
    }

    // Evaluate the expression without functions
    return evaluateSimpleExpression(replacedExpression);
  };

  const evaluateSimpleExpression = (expression) => {
    const tokens = expression.split(/([+\-*/^%><=!()])/);
    const operators = ["+", "-", "*", "/", "^", "%", ">", "<", "=", "!"];
    const precedence = {
      "^": 5,
      "*": 4,
      "/": 4,
      "%": 4,
      "+": 3,
      "-": 3,
      ">": 2,
      "<": 2,
      "=": 2,
      "!": 2,
    };

    const outputQueue = [];
    const operatorStack = [];

    tokens.forEach((token) => {
      if (token.trim() === "") {
        return;
      }

      if (!isNaN(parseFloat(token))) {
        outputQueue.push(parseFloat(token));
      } else if (operators.includes(token)) {
        while (
          operatorStack.length > 0 &&
          operators.includes(operatorStack[operatorStack.length - 1]) &&
          precedence[operatorStack[operatorStack.length - 1]] >=
          precedence[token]
        ) {
          outputQueue.push(operatorStack.pop());
        }
        operatorStack.push(token);
      } else if (token === "(") {
        operatorStack.push(token);
      } else if (token === ")") {
        while (
          operatorStack.length > 0 &&
          operatorStack[operatorStack.length - 1] !== "("
        ) {
          outputQueue.push(operatorStack.pop());
        }
        operatorStack.pop();
      }
    });

    while (operatorStack.length > 0) {
      outputQueue.push(operatorStack.pop());
    }

    const evaluationStack = [];

    outputQueue.forEach((token) => {
      if (!isNaN(parseFloat(token))) {
        evaluationStack.push(token);
      } else {
        const right = evaluationStack.pop();
        const left = evaluationStack.pop();
        switch (token) {
          case "+":
            evaluationStack.push(left + right);
            break;
          case "-":
            evaluationStack.push(left - right);
            break;
          case "*":
            evaluationStack.push(left * right);
            break;
          case "/":
            evaluationStack.push(left / right);
            break;
          case "^":
            evaluationStack.push(Math.pow(left, right));
            break;
          case "%":
            evaluationStack.push(left % right);
            break;
          case ">":
            evaluationStack.push(left > right);
            break;
          case "<":
            evaluationStack.push(left < right);
            break;
          case "=":
            evaluationStack.push(left === right);
            break;
          case "!":
            evaluationStack.push(left !== right);
            break;
        }
      }
    });

    return evaluationStack.pop();
  };

  const splitFunctionArgs = (functionArgs, mongoComponents) => {
    const args = [];
    let currentArg = "";
    let openParenCount = 0;
    let insideQuotes = false;

    for (let i = 0; i < functionArgs.length; i++) {
      const char = functionArgs[i];

      if (char === "(") {
        openParenCount++;
      } else if (char === ")") {
        openParenCount--;
      } else if (char === '"') {
        insideQuotes = !insideQuotes;
      }

      if (char === "," && openParenCount === 0 && !insideQuotes) {
        args.push(evaluateArgument(currentArg.trim(), mongoComponents));
        currentArg = "";
      } else {
        currentArg += char;
      }
    }

    if (currentArg !== "") {
      args.push(evaluateArgument(currentArg.trim(), mongoComponents));
    }

    return args;
  };

  const evaluateArgument = (arg, mongoComponents) => {
    const comparisonRegex = /(.+)(==|!=|>|<|>=|<=)(.+)/;
    const matches = arg.match(comparisonRegex);

    if (matches) {
      const left = evaluateMathExpression(matches[1].trim(), mongoComponents);
      const operator = matches[2].trim();
      const right = evaluateMathExpression(matches[3].trim(), mongoComponents);

      switch (operator) {
        case "==":
          return left === right;
        case "!=":
          return left !== right;
        case ">":
          return left > right;
        case "<":
          return left < right;
        case ">=":
          return left >= right;
        case "<=":
          return left <= right;
      }
    }

    return evaluateMathExpression(arg, mongoComponents);
  };

  const reCalculateSalaryComponents = () => {
    let detailAry = [];

    let objectArray = salaryDetails.map((item) => {
      return {
        _id: String(item?.componentRef?._id),
        value: item?.annualAmount || 0,
      };
    });

    for (let i = 0; i < salaryDetails?.length; i++) {
      let _salaryDetails = [...salaryDetails];
      let detail = _salaryDetails[i];
      let amount = detail?.annualAmount || 0;
      let component = detail?.componentRef;
      if (component?.calculationType === "Formula") {
        console.log("Expression: ", component?.expression);
        console.log("Object ary: ", objectArray);

        let value = evaluateMathExpression(
          "(" + component?.expression + ")",
          objectArray
        );

        console.log("Value is: ", value);

        if (isNaN(value)) {
          value = 0;
        }

        value = parseFloat(value).toFixed(0);

        for (let j = 0; j < salaryDetails?.length; j++) {
          let curDetail = salaryDetails[j];
          let curComp = curDetail?.componentRef;
          if (curComp?._id === component?._id) {
            objectArray[j] = {
              _id: curComp?._id,
              value,
            };
          }
        }

        let newDetails = [...salaryDetails];
        newDetails[i].annualAmount = value;
        setSalaryDetails(newDetails);
      } else {
        detailAry.push(detail);
      }
    }

    setSalaryDetails(
      salaryDetails.map((item) => {
        return {
          ...item,
          amount: parseFloat((item?.annualAmount || 0) / 12).toFixed(0),
        };
      })
    );
  };

  const calculateTotalSalaryFromComponents = () => {
    let total = 0;

    // Calculate total sum
    for (let i = 0; i < salaryDetails?.length; i++) {
      const item = salaryDetails[i];
      total += parseFloat(item?.amount || 0);
    }

    total = total.toFixed(2);
    setTotalSalary(total);
  };

  const addLeave = () => {
    setLeaves([...leaves, { leave: "", balance: null }]);
  };

  const removeLeave = (index) => {
    if (leaves.length <= 1) return;
    const newLeaves = [...leaves];
    newLeaves.splice(index, 1);
    setLeaves(newLeaves);
  };

  const updateLeave = (index, leave) => {
    const newLeaves = [...leaves];
    newLeaves[index] = leave;
    setLeaves(newLeaves);
  };

  useEffect(() => {
    getEmployeeDetails();
  }, [employeeId]);

  useEffect(() => {
    if (organizationId) {
      getHROptions();
      getExistingEmployees();
    }
  }, []);

  useEffect(() => {
    if (salaryStructure && preventFetchingSalaryComponents === false) {
      getSalaryComponentsByStructure();
    }
  }, [salaryStructure]);

  useEffect(() => {
    calculateTotalSalaryFromComponents();
  }, [salaryDetails]);

  useEffect(() => {
    if (finRelMainProfile?.data?._id) {
      setParticipantDataLoader(true);
      let filterObj = {
        teamId: teamData?._id,
      };
      if (finRelMainProfile?.data?.parentModelName === "OutSider") {
        filterObj.outSiderIdentifier = finRelMainProfile?.data?._id;
      } else {
        filterObj.profileId = finRelMainProfile?.data?._id;
      }
      getParticipantsByUserAndTeam(filterObj)
        .then((data) => {
          if (data && data.length > 0 && data[0]?._id) {
            setParticipantData(data[0]);
            setSelectedRoles(data[0]?.rolePermissions);
            setFinancialRelationType(data[0]?.mainRole || "Employee");
            let locPermissions = data[0]?.permissions || [];
            let locSelectedOptionIds = [];
            locPermissions.map((item) => {
              locSelectedOptionIds.push(item.identifier);
              if (item?.fields && item.fields.length > 0) {
                let arr = [];
                item.fields.map((fItem) => {
                  arr.push(`${item.identifier}-${fItem}`);
                });
                locSelectedOptionIds = [...locSelectedOptionIds, ...arr];
              }
            });
            setSelectedAcccessIds(locSelectedOptionIds);
            setOldSelectedAcccessIds(locSelectedOptionIds);
          } else {
            setParticipantData(null);
            setSelectedRoles([]);
            setFinancialRelationType("Employee");
            setSelectedAcccessIds([]);
            setOldSelectedAcccessIds([]);
          }
          setParticipantDataLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [finRelMainProfile]);

  useEffect(() => {
    if (finRelMainProfile?.data?._id) {
      setOpenEditEmployee(false);
    } else {
      setOpenEditEmployee(true);
    }
  }, [finRelMainProfile, finRelMainProfile?.data?._id]);

  const updateAccessRole = async () => {
    let newAccessArr = [];
    const oldPermissions = participantData?.permissions || [];
    //newly added identifier
    let toBeAdded = selectedAcccessIds.filter(
      (id) => !oldSelectedAcccessIds.includes(id)
    );
    //removed identifier
    let toBeDeleted = oldSelectedAcccessIds.filter(
      (id) => !selectedAcccessIds.includes(id)
    );

    let exisitingIds = [];
    let exisitingIdentifier = [];
    let toBeDeletedIds = [];
    let existingIdentifierIdMap = {};
    let updatedObjectArr = [];
    oldPermissions.map((permission) => {
      if (!toBeDeleted.includes(permission?.identifier)) {
        exisitingIds.push(permission?._id);
        exisitingIdentifier.push(permission?.identifier);
        existingIdentifierIdMap[permission?.identifier] = permission?._id;
      } else {
        toBeDeletedIds.push(permission?._id);
      }
    });

    exisitingIdentifier.map((_id) => {
      const curObj = idObjectMap[_id];
      if (curObj?.action && !curObj?.field) {
        let children = curObj?.children;
        let fArr = [];
        children.map((it) => {
          if (selectedAcccessIds.includes(it?._id)) {
            fArr.push(it?.title);
          }
        });

        updatedObjectArr.push({
          updateOne: {
            filter: { _id: existingIdentifierIdMap[curObj?._id] },
            update: {
              $set: {
                fields: fArr,
              },
            },
          },
        });
      }
    });

    toBeAdded.map((_id) => {
      if (!idObjectMap[_id]?.field) {
        const curObj = idObjectMap[_id];
        let newObj = {
          module: curObj?.module,
          team: teamData?._id,
          createdBy: user?.profile,
          createdAt: new Date(),
        };
        if (curObj?.resource) {
          newObj.resource = curObj?.resource;
        }
        if (curObj?.action) {
          newObj.action = curObj?.action;
        }
        if (curObj?._id) {
          newObj.identifier = curObj?._id;
        }
        if (curObj?.action && !curObj?.field) {
          let children = curObj?.children;
          let fArr = [];
          children.map((it) => {
            if (selectedAcccessIds.includes(it?._id)) {
              fArr.push(it?.title);
            }
          });
          newObj.fields = fArr;
        }
        if (!curObj?.field) {
          newAccessArr.push(newObj);
        }
      }
    });

    let pRoleIds = [];
    selectedRoles.map((selectPRole) => {
      pRoleIds.push(selectPRole?._id);
    });

    const reqObj = {
      newAccess: newAccessArr,
      toBeDeletedIds,
      _id: participantData?._id,
      updatedObjectArr: updatedObjectArr,
      exisitingIds,
      mainRole: financialRelationType,
      rolePermissions: pRoleIds,
      updateBy: user?.profile,
    };
    const relObj = {
      mainProfile: teamData?.parent?.profile?._id,
      relationProfileIds: [participantData?.profile?._id],
      notOnPlatformProfileIds: [],
      type: financialRelationType ? financialRelationType : "Employee",
      parent: teamData?.parent?._id,
      parentModelName: teamData?.parentModelName,
      addedBy: user?.profile,
      user: user?._id,
    };
    await createAndUpdateFinalcialRelation(relObj)
      .then(async (resData) => {
        console.log("resData");
      })
      .catch((err) => {
        console.log(err);
      });

    await updateParticipant(reqObj)
      .then((data) => {
        console.log("data");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addAccessRole = async (newAccessCode) => {
    let newAccessArr = [];
    selectedAcccessIds.map((_id) => {
      if (idObjectMap[_id] != null && idObjectMap[_id] != undefined) {
        const curObj = idObjectMap[_id];
        let newObj = {
          module: curObj?.module,
          team: teamData?._id,
          createdBy: user?.profile,
          createdAt: new Date(),
        };
        if (curObj?.resource) {
          newObj.resource = curObj?.resource;
        }
        if (curObj?.action) {
          newObj.action = curObj?.action;
        }
        if (curObj?._id) {
          newObj.identifier = curObj?._id;
        }
        if (curObj?.action && !curObj?.field) {
          let children = curObj?.children;
          let fArr = [];
          children.map((it) => {
            if (selectedAcccessIds.includes(it?._id)) {
              fArr.push(it?.title);
            }
          });
          newObj.fields = fArr;
        }
        if (!curObj?.field) {
          newAccessArr.push(newObj);
        }
      }
    });

    const selectedProfileId = finRelMainProfile?.data?._id;
    let participantProfiles = [
      {
        _id: selectedProfileId,
        parentModelName: finRelMainProfile?.data?.parentModelName,
      },
    ];
    let participantProfileIds = [selectedProfileId];

    const roleAccessIds = selectedRoles.map((roleAccess) => roleAccess?._id);
    const reqObj = {
      participantProfiles,
      teamId: teamData?._id,
      mainRole: financialRelationType,
      roleAccessIds,
      addedBy: user?.profile,
      newAccess: newAccessArr,
      teamParentModel: teamData?.parentModelName,
      teamProfileId: teamData?.parent?.profile?._id,
      needToSendInvitation: false,
      newAccessCode,
    };
    const relObj = {
      mainProfile: teamData?.parent?.profile?._id,
      relationProfileIds: participantProfileIds,
      notOnPlatformProfileIds: [],
      type: financialRelationType ? financialRelationType : "Employee",
      parent: teamData?.parent?._id,
      parentModelName: teamData?.parentModelName,
      addedBy: user?.profile,
      user: user?._id,
    };
    await createAndUpdateFinalcialRelation(relObj)
      .then(async (resData) => {
        console.log("resData");
      })
      .catch((err) => {
        console.log(err);
      });

    await addParticipants(reqObj)
      .then((data) => {
        console.log("data");
      })
      .catch((err) => {
        console.log(err);
      });
  };


  //use for automatic validation of salary (not use do to performance issues).

  // useEffect(() => {
  //   const revalidedSalary = setTimeout(() => {
  //     reCalculateSalaryComponents();
  //   }, 500);
  //   return () => clearTimeout(revalidedSalary);
  // }, [salaryDetails]);

  return (
    <StandardContainer
      appBarTitle={employeeId ? "Edit Employee" : "New Employee"}
      showAppBar={true}
    >
      {loadingEmployeeDetails || loadingHROptions ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "80vh" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <StandardAppContainerRounded>
            <SpaceBetween
              left={
                <FormBox label="Employee Name" isRequired={true}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <PaginatedEntityDropdown
                      givenProfileIdArr={profileIdArr}
                      value={finRelMainProfile}
                      onChange={(value) => {
                        setFinRelMainProfile(value);
                      }}
                      isMulti={false}
                      entity={"Organization"}
                      curEntityId={organizationId}
                      palCreate={true}
                      financialRelationType="Employee"
                      givenFilterOption={[
                        {
                          option: "Contact",
                          types: mainRoles,
                        },
                        {
                          option: "Network",
                          types: ["User"]
                        }
                      ]}
                    />
                  </Stack>
                </FormBox>
              }
            />
            <SpaceBetween
              left={
                <FormBox label="Department" isRequired={true}>
                  <Select
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="">Select</MenuItem>
                    {departments.map((item) => (
                      <MenuItem value={item?._id}>{item?.displayName}</MenuItem>
                    ))}
                  </Select>
                </FormBox>
              }
              right={
                <FormBox label="Designation" isRequired={true}>
                  <Select
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="">Select</MenuItem>
                    {designations?.map((item) => (
                      <MenuItem value={item?._id}>{item?.name}</MenuItem>
                    ))}
                  </Select>
                </FormBox>
              }
            />
            <SpaceBetween
              left={
                <FormBox label="DOJ" isRequired={true}>
                  <DatePicker
                    value={doj}
                    onChange={(_date) => {
                      console.log("Date is: ", _date);
                      setDoj(_date);
                    }}
                    fullWidth
                  />
                </FormBox>
              }
              right={
                <FormBox label="Work Location" isRequired={true}>
                  <Select
                    value={workLocation}
                    onChange={(e) => setWorkLocation(e.target.value)}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="">Select</MenuItem>
                    {locations?.map((item) => (
                      <MenuItem value={item?._id}>{item?.name}</MenuItem>
                    ))}
                  </Select>
                </FormBox>
              }
            />
            <SpaceBetween
              left={
                <FormBox label="Manager">
                  <UserSearcher
                    defaultValue={manager}
                    onSelect={(newVal) => setManager(newVal)}
                  />
                </FormBox>
              }
              right={
                <FormBox label="Mentor">
                  <UserSearcher
                    defaultValue={mentor}
                    onSelect={(newVal) => setMentor(newVal)}
                  />
                </FormBox>
              }
            />
          </StandardAppContainerRounded>

          <Paper elevation={2} className={classes.accessContain}>
            <div className={classes.accessHeaderCont}>
              <Typography variant="h5" sx={{ mb: 3 }}>
                Access Role
              </Typography>
              {finRelMainProfile?.data?._id && (
                <>
                  {participantDataLoader ? (
                    <Skeleton
                      style={{
                        marginLeft: "10px",
                        height: "40px",
                        width: "100px",
                      }}
                    />
                  ) : (
                    <div className={classes.iconText}>
                      {participantData?._id ? (
                        <>
                          {participantData?.isAccepted ? (
                            <>
                              <CheckCircleIcon style={{ color: "green" }} />
                              <p>Already a participant</p>
                            </>
                          ) : (
                            <>
                              <TbDotsCircleHorizontal
                                style={{ color: "orange", fontSize: "23px" }}
                              />
                              <p>Already invited</p>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <ErrorIcon style={{ color: "red" }} />
                          <p>Not a participant</p>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>

            {finRelMainProfile && finRelMainProfile?.data?._id ? (
              <ParticipantAddBody
                accessRoles={accessRoles}
                selectedAcccessIds={selectedAcccessIds}
                setSelectedAcccessIds={setSelectedAcccessIds}
                financialRelationType={financialRelationType}
                setFinancialRelationType={setFinancialRelationType}
                selectedRoles={selectedRoles}
                setSelectedRoles={setSelectedRoles}
                accessOptions={accessOptions}
                isFullScreen={true}
              />
            ) : (
              <div className={classes.emptyTextStyle}>
                To give access you need to select user first!
              </div>
            )}
          </Paper>

          <StandardAppContainerRounded>
            <Typography variant="h5" sx={{ mb: 3 }}>
              Contract Details
            </Typography>
            <SpaceBetween
              left={
                <FormBox label="Work Schedule" isRequired={true}>
                  <Select
                    fullWidth
                    value={workSchedule}
                    onChange={(e) => setWorkSchedule(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="">Select</MenuItem>
                    {workSchedules?.map((item) => (
                      <MenuItem value={item?._id}>
                        {item?.workScheduleName || "Untitled"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormBox>
              }
              right={
                <FormBox label="Contract Start Date" isRequired={true}>
                  <DatePicker
                    value={contractStartDate}
                    onChange={(e) => setContractStartDate(e?.target?.value)}
                    fullWidth
                  />
                </FormBox>
              }
            />
            <SpaceBetween
              left={
                <FormBox label="Salary Structure" isRequired={true}>
                  <Select
                    fullWidth
                    value={salaryStructure}
                    onChange={(e) => setSalaryStructure(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="">Select</MenuItem>
                    {salaryStructures?.map((item) => {
                      return (
                        <MenuItem value={item?._id}>{item?.name}</MenuItem>
                      );
                    })}
                  </Select>
                </FormBox>
              }
              right={
                <FormBox label="Pay Cycle" isRequired={true}>
                  <Select
                    fullWidth
                    value={payCycle}
                    onChange={(e) => setPayCycle(e.target.value)}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {PAY_CYCLE_OPTIONS?.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormBox>
              }
            />

            <FormBox label="Leaves" isRequired={true}>
              {leaves?.map((item, index) => (
                <HorizBox sx={{ mb: 2 }}>
                  <Select
                    sx={{ width: 300 }}
                    displayEmpty
                    value={item?.leave}
                    onChange={(e) => {
                      updateLeave(index, {
                        ...item,
                        leave: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {leaveOptions?.map((item) => (
                      <MenuItem value={item?._id}>{item?.name}</MenuItem>
                    ))}
                  </Select>
                  <TextField
                    placeholder="Balance"
                    value={item?.balance}
                    type="number"
                    onChange={(e) => {
                      updateLeave(index, {
                        ...item,
                        balance: e.target.value,
                      });
                    }}
                    disabled={
                      leaveOptions.find((i) => i?._id === item?.leave)?.type ===
                      "UNPAID"
                    }
                  />
                  <IconButton onClick={() => removeLeave(index)}>
                    <DeleteOutline />
                  </IconButton>
                  <IconButton onClick={() => addLeave()}>
                    <Add />
                  </IconButton>
                </HorizBox>
              ))}
            </FormBox>
          </StandardAppContainerRounded>

          <StandardAppContainerRounded>
            <BoxSpaceBetween>
              <Typography variant="h5" sx={{ mb: 3 }}>
                Salary Details
              </Typography>
              <IconButton
                color="primary"
                onClick={() => reCalculateSalaryComponents()}
              >
                <RefreshOutlined />
              </IconButton>
            </BoxSpaceBetween>

            <TableContainer
              columns={[
                "SALARY COMPONENT",
                "TYPE",
                "ANNUAL AMOUNT",
                "MONTHLY AMOUNT",
              ]}
              data={[]}
              tableStyle={salaryTableStyle}
            >
              {salaryDetails?.map((item, index) => (
                <tr>
                  <td>
                    <Typography variant="subtitle1">
                      {item?.componentRef?.name}
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="subtitle1">
                      {item?.componentRef?.type}
                    </Typography>
                  </td>
                  <td>
                    <TextFieldWithIcon
                      placeholder="Enter"
                      size="small"
                      value={item?.annualAmount}
                      readonly={
                        item?.componentRef?.calculationType === "Percentage"
                      }
                      onChange={(e) => {
                        const newSalaryDetails = [...salaryDetails];
                        newSalaryDetails[index] = {
                          ...item,
                          annualAmount: e.target.value,
                        };
                        setSalaryDetails(newSalaryDetails);
                      }}
                      startIcon={<CurrencyRupeeSharp />}
                      type="number"
                    />
                  </td>
                  <td>
                    <TextFieldWithIcon
                      placeholder="Enter"
                      size="small"
                      value={item?.amount}
                      readonly={
                        item?.componentRef?.calculationType === "Percentage"
                      }
                      onChange={(e) => {
                        const newSalaryDetails = [...salaryDetails];
                        newSalaryDetails[index] = {
                          ...item,
                          amount: e.target.value,
                        };
                        setSalaryDetails(newSalaryDetails);
                      }}
                      startIcon={<CurrencyRupeeSharp />}
                      type="number"
                    />
                  </td>
                </tr>
              ))}
              {/* <tr>
                <td colSpan={3}>
                  <Typography variant="h6" textAlign="right">
                    ₹ {totalSalary}
                  </Typography>
                </td>
              </tr> */}
            </TableContainer>
            <DuoButtonGroup
              primaryButtonText={employee?._id ? "Update" : "Create"}
              primaryButtonListener={() => handleEmployeeData()}
              loadingPrimary={employeeLoading}
              secondaryButtonText="Cancel"
              secondaryButtonListener={() => history.goBack()}
            />
          </StandardAppContainerRounded>
        </>
      )}
    </StandardContainer>
  );
};

export default EditEmployee;

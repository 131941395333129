import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../../../styled/generic/StandardAppContainerRounded";
import FormBox from "../../../styled/generic/FormBox";
import {
  AlarmOutlined,
  EmailOutlined,
  FaceOutlined,
  PhoneOutlined,
  PlaceOutlined,
} from "@material-ui/icons";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../../helpers/Api";
import StandardContainer from "../../../styled/generic/StandardContainer";
import TabsWithCommonAction from "../../../styled/generic/TabsWithCommonAction";
import ContractDetails from "./tabs/ContractDetails";
import Documents from "./tabs/Documents";
import Loans from "./tabs/Loans";
import SalaryHistory from "./tabs/SalaryHistory";
import Leaves from "./tabs/Leaves";
import Attendance from "./tabs/Attendance";

const ListComponent = ({ icon, label, value }) => {
  return (
    <Box display="flex" alignItems="center">
      {icon}
      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
          {label}
        </Typography>
        <Typography variant="subtitle2">{value || "-"}</Typography>
      </Box>
    </Box>
  );
};

const GridComponent = ({ label, value }) => {
  return (
    <Box>
      <Typography variant="subtitle2">{label}</Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
        {value || "-"}
      </Typography>
    </Box>
  );
};

const EmployeeAbout = () => {
  const { employeeId } = useParams();
  const dispatch = useDispatch();
  const [finrel, setFinrel] = useState();
  const [loadingEmployeeDetails, setLoadingEmployeeDetails] = useState(true);
  const [tab, setTab] = useState("Contract Details");

  const getEmployeeDetails = async () => {
    try {
      setLoadingEmployeeDetails(true);
      const { data } = await Api.post("/employee/get-by-id", {
        employeeId: employeeId,
      });

      if (data) {
        setFinrel(data);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch employee details",
        },
      });
    } finally {
      setLoadingEmployeeDetails(false);
    }
  };

  useEffect(() => {
    getEmployeeDetails();
  }, []);

  return (
    <StandardContainer showAppBar={true} appBarTitle="Back to employees">
      {loadingEmployeeDetails ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40vw",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <StandardAppContainerRounded fullHeight={true}>
              <Stack direction="column" spacing={2}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Avatar
                    sx={{ width: 80, height: 80 }}
                    src={finrel?.mainProfile?.parent?.displayPicture?.url}
                  />
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    {finrel?.mainProfile?.parent?.displayName}
                  </Typography>
                  <Typography variant="subtitle1">
                    {finrel?.firstPartyContact?.department?.displayName +
                      " " +
                      finrel?.firstPartyContact?.designation?.name}
                  </Typography>
                </Box>
                <FormBox
                  label="Personal Details"
                  labelTypographyVariant="subtitle1"
                  fontWeight="bold"
                >
                  <Stack direction="column" spacing={1}>
                    <ListComponent
                      icon={<EmailOutlined />}
                      label="Email"
                      value={finrel?.mainProfile?.parent?.email}
                    />
                    <ListComponent
                      icon={<PhoneOutlined />}
                      label="Phone"
                      value={finrel?.mainProfile?.parent?.phone}
                    />
                  </Stack>
                </FormBox>
                <FormBox
                  label="Professional Details"
                  labelTypographyVariant="subtitle1"
                  fontWeight="bold"
                >
                  <Stack direction="column" spacing={1}>
                    <ListComponent
                      icon={<FaceOutlined />}
                      label={finrel?.firstPartyContact?.department?.displayName}
                      value="Department"
                    />
                    <ListComponent
                      icon={<PlaceOutlined />}
                      label={finrel?.firstPartyContact?.workLocation?.name}
                      value="Work Location"
                    />
                    <ListComponent
                      icon={<AlarmOutlined />}
                      label={
                        dayjs(finrel?.defaultWorkSchedule?.shiftStart).format(
                          "hh:mm A"
                        ) +
                        " to " +
                        dayjs(finrel?.defaultWorkSchedule?.shiftEnd).format(
                          "hh:mm A"
                        )
                      }
                      value="Work Schedule"
                    />
                  </Stack>
                </FormBox>
              </Stack>
            </StandardAppContainerRounded>
          </Grid>
          <Grid item xs={9}>
            <TabsWithCommonAction
              tabs={[
                "Contract Details",
                "Attendance",
                "Leaves",
                "Salary History",
                "Loans",
                "Documents",
              ]}
              onTabChange={(data) => setTab(data)}
              currentTabVariable={tab}
              action={<></>}
            />
            <Box>
              {tab === "Contract Details" && (
                <ContractDetails finrel={finrel} />
              )}
              {tab === "Attendance" && <Attendance finrel={finrel} />}
              {tab === "Leaves" && <Leaves finrel={finrel} />}
              {tab === "Salary History" && <SalaryHistory finrel={finrel} />}
              {tab === "Loans" && <Loans finrel={finrel} />}
              {tab === "Documents" && <Documents finrel={finrel} />}
            </Box>
          </Grid>
        </Grid>
      )}
    </StandardContainer>
  );
};

export default EmployeeAbout;

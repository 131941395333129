import React, { useEffect, useState } from "react";
import StandardContainer from "../../../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../../../styled/generic/StandardAppContainerRounded";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../../helpers/Api";
import TableContainer from "../../../styled/generic/TableContainer";
import ListingTableSingleUserCell from "../../../styled/generic/ListingTableSingleUserCell";
import SingleUserWithCaptionTableCell from "../../../styled/generic/SingleUserWithCaptionTableCell";
import { makeStyles } from "@material-ui/core";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import dayjs from "dayjs";
import { Edit, EditOutlined } from "@mui/icons-material";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import HorizBox from "../../../styled/generic/HorizBox";

const CustomNumberInput = ({ value, onChange }) => {
  return (
    <input
      type="number"
      value={value}
      onChange={(e) => {
        let val = e.target.value;
        if (!val || val < 0 || val === "" || isNaN(val)) {
          return;
        } else {
          onChange(e);
        }
      }}
      placeholder="Enter"
      style={{
        border: "1px solid rgba(0,0,0,0.1)",
        outline: "none",
        fontWeight: "bold",
        display: "block",
        fontSize: "0.9rem",
        width: "100px",
        padding: "5px 10px",
      }}
    />
  );
};

const PayrollCheckDrawer = ({
  open,
  setOpen,
  finrel,
  setFinrel,
  leavesRecord,
  setLeavesRecord,
  absentTimesheetRecord,
  setAbsentTimesheetRecord,
  halfDayTimesheetRecord,
  setHalfDayTimesheetRecord,
  lateTimesheetRecord,
  setLateTimesheetRecord,
  onPaycheckReviewed,
  loanPayments,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [reviewing, setReviewing] = useState(false);

  const reviewPaycheck = async () => {
    try {
      setReviewing(true);
      const { data } = await Api.post(`/payroll/paycheck/review`, {
        paycheck: finrel,
        leavesRecord: leavesRecord,
        absentTimesheetRecord,
        halfDayTimesheetRecord,
        lateTimesheetRecord,
        salaryData: finrel?.salaryData,
        totalSalary: finrel?.totalSalary,
        reviewedBy: user?._id,
      });
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Paycheck reviewed successfully",
        },
      });
      setOpen(false);
      onPaycheckReviewed();
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to update paycheck",
        },
      });
    } finally {
      setReviewing(false);
    }
  };

  return (
    <DrawerContainer
      title="Review Paycheck"
      open={open}
      setOpen={setOpen}
      width="80vw"
      hideHeader={true}
      backgroundColor="#F5F7FE"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ background: "#FFFFFF", p: 2, borderRadius: 4 }}
      >
        <Box>
          <SingleUserWithCaptionTableCell
            label={finrel?.worker?.mainProfile?.parent?.displayName}
            caption={`${finrel?.worker?.firstPartyContact?.department?.displayName} ${finrel?.worker?.firstPartyContact?.designation?.name}`}
            url={finrel?.worker?.mainProfile?.parent?.displayPicture?.url}
          />
        </Box>
        <Box>
          <Typography variant="h5">
            {dayjs(finrel?.date).format("MMMM YYYY")}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                boxShadow: "0px 0px 80px 0px rgba(171, 190, 209, 0.15)",
                padding: "16px",
              }}
            >
              <BoxSpaceBetween>
                <Typography variant="h6" sx={{ color: "#127E1D" }}>
                  Earnings
                </Typography>
              </BoxSpaceBetween>
              <Box>
                {finrel?.salaryData
                  ?.filter((item) => item?.type === "Earning")
                  ?.map((item) => (
                    <BoxSpaceBetween sx={{ mt: 2 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                        {item?.label}
                      </Typography>
                      <CustomNumberInput
                        value={item?.value}
                        onChange={(e) => {
                          let _finrel = { ...finrel };
                          let _salaryData = [..._finrel?.salaryData];
                          let _salaryItem = _salaryData.find(
                            (x) => x?.label === item?.label
                          );
                          let oldAmount = parseFloat(_salaryItem.value);
                          _salaryItem.value = e.target.value;
                          _finrel.salaryData = _salaryData;

                          let newTotal = parseFloat(_finrel.totalSalary);
                          newTotal -= oldAmount;
                          newTotal += parseFloat(e.target.value);
                          _finrel.totalSalary = newTotal;

                          setFinrel(_finrel);
                        }}
                      />
                    </BoxSpaceBetween>
                  ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                boxShadow: "0px 0px 80px 0px rgba(171, 190, 209, 0.15)",
                padding: "16px",
              }}
            >
              <BoxSpaceBetween>
                <Typography variant="h6" sx={{ color: "#E01507" }}>
                  Deductions
                </Typography>
              </BoxSpaceBetween>
              <Box>
                {finrel?.salaryData
                  ?.filter(
                    (item) =>
                      item?.type === "PreTaxDeduction" ||
                      item?.type === "PostTaxDeduction"
                  )
                  ?.map((item) => (
                    <BoxSpaceBetween sx={{ mt: 2 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                        {item?.label}
                      </Typography>
                      <CustomNumberInput
                        value={item?.value}
                        onChange={(e) => {
                          let _finrel = { ...finrel };
                          let _salaryData = [..._finrel?.salaryData];
                          let _salaryItem = _salaryData.find(
                            (x) => x?.label === item?.label
                          );
                          let oldAmount = parseFloat(_salaryItem.value);
                          _salaryItem.value = e.target.value;
                          _finrel.salaryData = _salaryData;

                          let newTotal = parseFloat(_finrel.totalSalary);
                          newTotal += oldAmount;
                          newTotal -= parseFloat(e.target.value);
                          _finrel.totalSalary = newTotal;

                          setFinrel(_finrel);
                        }}
                      />
                    </BoxSpaceBetween>
                  ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                boxShadow: "0px 0px 80px 0px rgba(171, 190, 209, 0.15)",
                padding: "16px",
              }}
            >
              <BoxSpaceBetween>
                <Typography variant="h6" sx={{ color: "#161719" }}>
                  Reimbursments
                </Typography>
              </BoxSpaceBetween>
              <Box>
                {finrel?.salaryData
                  ?.filter((item) => item?.type === "Reimbursement")
                  ?.map((item) => (
                    <BoxSpaceBetween sx={{ mt: 2 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                        {item?.label}
                      </Typography>
                      <CustomNumberInput
                        value={item?.value}
                        onChange={(e) => {
                          let _finrel = { ...finrel };
                          let _salaryData = [..._finrel?.salaryData];
                          let _salaryItem = _salaryData.find(
                            (x) => x?.label === item?.label
                          );
                          let oldAmount = parseFloat(_salaryItem.value);
                          _salaryItem.value = e.target.value;
                          _finrel.salaryData = _salaryData;

                          let newTotal = parseFloat(_finrel.totalSalary);
                          newTotal -= oldAmount;
                          newTotal += parseFloat(e.target.value);
                          _finrel.totalSalary = newTotal;

                          setFinrel(_finrel);
                        }}
                      />
                    </BoxSpaceBetween>
                  ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                boxShadow: "0px 0px 80px 0px rgba(171, 190, 209, 0.15)",
                padding: "16px",
              }}
            >
              <BoxSpaceBetween>
                <Typography variant="h6" sx={{ color: "#161719" }}>
                  ATTENDANCE DETAILS
                </Typography>
              </BoxSpaceBetween>
              <Box>
                <table
                  style={{
                    textAlign: "left",
                  }}
                >
                  <tr>
                    <th>Attendance Type</th>
                    <th>Days</th>
                    <th>Amount</th>
                  </tr>
                  <tr>
                    <td>Absent</td>
                    <td>{absentTimesheetRecord?.days}</td>
                    <td>
                      <CustomNumberInput
                        value={absentTimesheetRecord?.amount}
                        onChange={(e) => {
                          let newRecord = { ...absentTimesheetRecord };
                          let oldAmount = parseFloat(newRecord?.amount);
                          let newAmount = parseFloat(e.target.value);
                          newRecord.amount = newAmount;
                          setAbsentTimesheetRecord(newRecord);

                          let _finrel = { ...finrel };
                          _finrel.totalSalary += oldAmount;
                          _finrel.totalSalary -= newAmount;

                          setFinrel(_finrel);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Half Day</td>
                    <td>{halfDayTimesheetRecord?.days}</td>
                    <td>
                      <CustomNumberInput
                        value={halfDayTimesheetRecord?.amount}
                        onChange={(e) => {
                          let newRecord = { ...halfDayTimesheetRecord };
                          let oldAmount = parseFloat(newRecord?.amount);
                          let newAmount = parseFloat(e.target.value);
                          newRecord.amount = newAmount;
                          setHalfDayTimesheetRecord(newRecord);

                          let _finrel = { ...finrel };
                          _finrel.totalSalary += oldAmount;
                          _finrel.totalSalary -= newAmount;

                          setFinrel(_finrel);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Late</td>
                    <td>{lateTimesheetRecord?.days}</td>
                    <td>
                      <CustomNumberInput
                        value={lateTimesheetRecord?.amount}
                        onChange={(e) => {
                          let newRecord = { ...lateTimesheetRecord };
                          let oldAmount = parseFloat(newRecord?.amount);
                          let newAmount = parseFloat(e.target.value);
                          newRecord.amount = newAmount;
                          setLateTimesheetRecord(newRecord);

                          let _finrel = { ...finrel };
                          _finrel.totalSalary += oldAmount;
                          _finrel.totalSalary -= newAmount;

                          setFinrel(_finrel);
                        }}
                      />
                    </td>
                  </tr>
                </table>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                boxShadow: "0px 0px 80px 0px rgba(171, 190, 209, 0.15)",
                padding: "16px",
              }}
            >
              <BoxSpaceBetween>
                <Typography variant="h6" sx={{ color: "#161719" }}>
                  LEAVE DETAILS
                </Typography>
              </BoxSpaceBetween>
              {leavesRecord?.length > 0 ? (
                <Box>
                  <table
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <tr>
                      <th>Leave Type</th>
                      <th>Days</th>
                      {/* <th>Amount</th> */}
                    </tr>
                    {leavesRecord?.map((lr) => (
                      <tr>
                        <td>{lr?.parent?.name}</td>
                        <td>{lr?.days}</td>
                        {/* <td>
                          <input
                            type="text"
                            value={lr?.amount}
                            onChange={(e) => {
                              let newLeavesRecord = [...leavesRecord];
                              let curItem = newLeavesRecord.find(
                                (i) => i?._id === lr?._id
                              );
                              let oldAmount = parseFloat(curItem?.amount);
                              let newAmount = parseFloat(e.target.value);
                              curItem.amount = newAmount;
                              setLeavesRecord(newLeavesRecord);
  
                              let _finrel = { ...finrel };
                              _finrel.totalSalary += oldAmount;
                              _finrel.totalSalary -= newAmount;
  
                              setFinrel(_finrel);
                            }}
                            placeholder="Enter"
                            style={{
                              border: "none",
                              outline: "none",
                              fontWeight: "bold",
                              display: "block",
                              fontSize: "0.9rem",
                              width: "100px",
                            }}
                          />
                        </td> */}
                      </tr>
                    ))}
                  </table>
                </Box>
              ) : (
                <Typography variant="subtitle1">No leaves taken</Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                boxShadow: "0px 0px 80px 0px rgba(171, 190, 209, 0.15)",
                padding: "16px",
              }}
            >
              <BoxSpaceBetween>
                <Typography variant="h6" sx={{ color: "#161719" }}>
                  LOANS
                </Typography>
              </BoxSpaceBetween>
              {loanPayments && loanPayments?.length > 0 ? (
                <Box>
                  <table
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <tr>
                      <th>Loan</th>
                      <th>Amount Deducted</th>
                    </tr>
                    {loanPayments?.map((lp) => (
                      <tr>
                        <td>{lp?.loan?.title}</td>
                        <td>₹ {lp?.amount || 0}</td>
                      </tr>
                    ))}
                  </table>
                </Box>
              ) : (
                <Typography variant="subtitle1">No loans taken</Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Box
          display="flex"
          alignItems="center"
          sx={{
            p: 2,
            border: "1px solid #000",
            borderRadius: "10px",
            backgroundColor: "#ffffff",
          }}
        >
          <HorizBox spacing={1}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Total Pay: ₹
            </Typography>
            <CustomNumberInput
              value={finrel?.totalSalary}
              onChange={(e) => {
                let _finrel = { ...finrel };
                _finrel.totalSalary = e.target.value;
                setFinrel(_finrel);
              }}
            />
          </HorizBox>
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{ ml: 4 }}
            disableElevation
            onClick={() => reviewPaycheck()}
            loading={reviewing}
          >
            Review
          </LoadingButton>
        </Box>
      </Box>
    </DrawerContainer>
  );
};

export default PayrollCheckDrawer;

import { Box, Typography } from "@mui/material";
import React from "react";
import FormBox from "./FormBox";
import Button from "./Button";

const LabelWithCustomActions = ({
  label,
  caption,
  actions,
  captionTypographyVariant = "subtitle1",
  ...props
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ marginBottom: caption ? "auto" : "10px" }}
      {...props}
    >
      <FormBox label={label} disableMargins={!caption}>
        {caption && (
          <Typography variant={captionTypographyVariant}>{caption}</Typography>
        )}
      </FormBox>
      <Box sx={{ alignSelf: "flex-start" }}>{actions}</Box>
    </Box>
  );
};

export default LabelWithCustomActions;

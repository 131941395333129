import React, { useEffect, useState } from "react";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Api from "../../../../helpers/Api";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import PalFormDrawer from "../../../AddMember/newPalFormDrawer";
import FormBox from "../../../styled/generic/FormBox";
import Autocomplete from "../../../styled/generic/Autocomplete";
import TextField from "../../../styled/generic/TextField";
import { useDebounce } from "react-use";
import Chip from "../../../styled/generic/Chip";
import { Clear } from "@material-ui/icons";
import StandardChip from "../../../styled/generic/StandardChip";
import StandardChipContainer from "../../../styled/generic/StandardChipContainer";
import StandardContainer from "../../../styled/generic/StandardContainer";
import StandardContainerRounded from "../../../styled/generic/StandardContainerRounded";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import PalAutocompleteMultiple from "../../../styled/generic/PalAutocompleteMultiple";

const CreateUsersTimesheet = ({
  open,
  setOpen,
  selectedProject,
  reloadTimesheets,
  setShowCreatePalDrawer,
}) => {
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [totalEmployeeCost, setTotalEmployeeCost] = useState(0);
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { teamId } = useParams();

  const createUsersTimesheet = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/timesheet/users/create", {
        title: "Group Timesheet",
        userId: user?._id,
        projectId: selectedProject?._id || null,
        orgTeamId: teamId,
        employees: selectedEntities,
        date: new Date().toISOString(),
      });
      if (data) {
        setOpen(false);
        reloadTimesheets();
      }
    } catch (err) {
      alert("Error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let cost = 0;
    for (let i = 0; i < selectedEntities?.length; i++) {
      let e = selectedEntities[i];
      cost += parseFloat(e?.amount) || 0;
    }
    setTotalEmployeeCost(cost);
  }, [selectedEntities]);

  return (
    <DrawerContainer
      title="Create Users Timesheet"
      open={open}
      setOpen={setOpen}
    >
      <Box>
        <PalAutocompleteMultiple
          selectedEntities={selectedEntities}
          setSelectedEntities={setSelectedEntities}
          teamId={teamId}
          label="Employee"
          palType="User"
        />
        <Box sx={{ mt: 2 }}>
          {selectedEntities?.map((employee, index) => (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <TextField
                variant="outlined"
                placeholder="Employee Name"
                fullWidth
                value={employee?.profile?.parent?.displayName}
                readOnly
                InputProps={{
                  inputProps: { min: 0 },
                }}
                sx={{ mr: 2 }}
              />

              <TextField
                variant="outlined"
                placeholder="Amount"
                fullWidth
                value={employee?.amount}
                onChange={(e) => {
                  let updatedArr = [...selectedEntities];
                  updatedArr[index].amount = e.target.value;
                  setSelectedEntities(updatedArr);
                }}
                type="number"
                InputProps={{
                  inputProps: { min: 0 },
                }}
                sx={{ ml: 2 }}
              />
            </Box>
          ))}
        </Box>
        <StandardContainerRounded>
          <FormBox disableMargins={true} label="Total Cost">
            <Typography variant="subtitle1" color="primary">
              ₹ {totalEmployeeCost}
            </Typography>
          </FormBox>
        </StandardContainerRounded>
        <DuoButtonGroup
          primaryButtonText="Create"
          primaryButtonListener={() => createUsersTimesheet()}
          secondaryButtonText="New Relation"
          secondaryButtonListener={() => setShowCreatePalDrawer(true)}
        />
      </Box>
    </DrawerContainer>
  );
};

export default CreateUsersTimesheet;

import {
  Avatar,
  Box,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import FormBox from "./FormBox";
import TextField from "./TextField";
import StandardChipContainer from "./StandardChipContainer";
import StandardChip from "./StandardChip";
import { useDebounce } from "react-use";
import Api from "../../../helpers/Api";
import { useSelector } from "react-redux";
import Autocomplete from "./Autocomplete";

const PalAutocompleteMultiple = ({
  label,
  selectedEntities,
  setSelectedEntities,
  teamId,
  palType,
}) => {
  const [entities, setEntities] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useSelector((state) => state.auth);

  const sendQuery = async () => {
    setSearching(true);
    await Api.post("/wallet/relation/searchPalsByType", {
      searchStr: searchQuery,
      userId: user?._id,
      orgTeamId: teamId,
      type: "User",
    })
      .then(({ data }) => {

        // If you select a user in autocomplete and then change search text,
        // New options will be loaded and if they contain old user in response,
        // Old user will not be selected by default
        // This means client can add same user twice when creating work schedule
        // To prevent this, perform a filter operation when fetching users

        setEntities(
          data.filter(
            (userA) =>
              !selectedEntities.some((userB) => userA._id === userB._id)
          ) || []
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSearching(false);
      });
  };

  const searchFinancialContacts = async () => {
    try {
      const res = await Api.post("/wallet/relation/searchGroupPals", {
        userId: user._id,
        searchStr: searchQuery,
        orgTeamId: teamId,
      });
      const data = res?.data || [];
      let parsedData = [];
      for (let i = 0; i < data?.length; i++) {
        let item = data[i];
        if (
          parsedData.find(
            (x) => String(x?.profile?._id) === String(item?.profile?._id)
          )
        ) {
        } else {
          parsedData.push(item);
        }
      }
      setEntities(parsedData);
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    if (searchQuery && searchQuery?.length > 2) {
      if (palType === "Group") {
        searchFinancialContacts();
      } else if (palType === "User") {
        sendQuery();
      } else {
        // NO-OP
      }
    } else if (searchQuery && searchQuery?.length === 0) {
      setEntities([]);
    }
  };

  useDebounce(
    () => {
      getData();
    },
    1200,
    [searchQuery]
  );

  return (
    <Box>
      <FormBox label={label}>
        <Autocomplete
          multiple={true}
          options={entities}
          defaultValue={selectedEntities}
          onChange={(evt, newEntities) => setSelectedEntities(newEntities)}
          disableCloseOnSelect
          getOptionLabel={(option) => option?.profile?.parent?.displayName}
          loading={searching}
          renderOption={(props, option, { selected }) => {
            return (
              <ListItemButton {...props} selected={selected}>
                <ListItemAvatar>
                  <Avatar
                    src={option?.profile?.parent?.displayPicture?.thumbUrl}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={option?.profile?.parent?.displayName}
                  secondary={option?.profile?.parent?.type}
                />
              </ListItemButton>
            );
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select employees"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              variant="outlined"
            />
          )}
        />
      </FormBox>
      <StandardChipContainer>
        {selectedEntities?.map((item) => (
          <StandardChip
            label={item?.profile?.parent?.displayName}
            src={item?.profile?.parent?.displayPicture?.thumbUrl}
            onDelete={() => {
              setSelectedEntities(
                selectedEntities?.filter((i) => i._id !== item._id)
              );
            }}
          />
        ))}
      </StandardChipContainer>
    </Box>
  );
};

export default PalAutocompleteMultiple;

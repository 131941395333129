import React, { useEffect, useState } from "react";
import StandardContainer from "../../../../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../../../../styled/generic/StandardAppContainerRounded";
import {
  Add,
  CancelOutlined,
  DeleteOutline,
  EditOutlined,
  LocalAtmOutlined,
  MoreVert,
} from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import PopupMenuList from "../../../../styled/generic/PopupMenuList";
import LabelWithCustomActions from "../../../../styled/generic/LabelWithCustomActions";
import Button from "../../../../styled/generic/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSalaryComponentOrder,
  deleteSalaryComponent,
  getSalaryComponents,
} from "./editsalarycomponentforms/api";
import TableContainer from "../../../../styled/generic/TableContainer";
import HorizBox from "../../../../styled/generic/HorizBox";
import Api from "../../../../../helpers/Api";



const SalaryComponents = () => {
  const [tab, setTab] = useState("Earnings");
  const history = useHistory();
  const { organizationId } = useParams();
  const handleCreateComponent = (type) => {
    history.push(
      `/hr/settings/${organizationId}?view=EditSalaryComponent&type=${type}`
    );
  };

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [components, setComponents] = useState([]);
  const [componentsLoading, setComponentsLoading] = useState(false);

  const getComponents = async () => {
    try {
      setComponentsLoading(true);
      const { data } = await getSalaryComponents({
        organization: organizationId,
      });
      setComponents(data);
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setComponentsLoading(false);
    }
  };

  const deleteComponent = async (id) => {
    try {
      return;
      await deleteSalaryComponent({ _id: id });
      setComponents(components.filter((item) => item?._id !== id));
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Salary Component deleted successfully",
        },
      });
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to delete Salary Component",
        },
      });
    }
  };

  useEffect(() => {
    getComponents();
  }, []);

  const move = async (index, structureId, componentId) => {
    let { data } = await changeSalaryComponentOrder({
      index: index,
      componentId: componentId,
      structureId,
    });
  };

  const markComponentAsBasicPay = async (itemId) => {
    try {
      const { data } = await Api.post(
        "/salary-component/markComponentAsBasicPay",
        {
          componentId: itemId,
        }
      );
      if (data) {
        setComponents(
          components.map((item) => {
            if (item._id === data?._id) {
              return {
                ...item,
                flag: "BASIC_PAY",
              };
            } else {
              return {
                ...item,
                flag: null,
              };
            }
          })
        );
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const handleComponentIgnoreInCalculation = async (itemId, value) => {
    try {
      const { data } = await Api.post(
        "/salary-component/handleComponentIgnoreInPayrollCalculation",
        {
          componentId: itemId,
          value: value,
        }
      );
      if (data) {
        setComponents(
          components.map((item) => {
            if (item._id === data?._id) {
              return {
                ...item,
                ignoreInCalculation: value,
              };
            } else {
              return item;
            }
          })
        );
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (index) => {
    if (draggedIndex !== null && draggedIndex !== index) {
      const newData = [...components];
      const draggedItem = newData[draggedIndex];
      newData.splice(draggedIndex, 1);
      newData.splice(index, 0, draggedItem);
      setComponents(newData);
      setDraggedIndex(index);
    }
  };

  const handleDragEnd = () => {
    move(
      draggedIndex,
      components[0]?.salaryStructure,
      components[draggedIndex]?._id
    );
    console.log("Moving: ", components[draggedIndex]);
    setDraggedIndex(null);
  };

  return (
    <StandardContainer>
      <LabelWithCustomActions
        label="Salary Component"
        actions={
          <Box>
            <PopupMenuList
              appearContent={
                <Button variant="contained" startIcon={<Add />}>
                  Component
                </Button>
              }
              actions={[
                {
                  title: "Earning",
                  action: () => handleCreateComponent("Earning"),
                },
                {
                  title: "Deduction (PreTax)",
                  action: () => handleCreateComponent("PreTaxDeduction"),
                },
                {
                  title: "Deduction (PostTax)",
                  action: () => handleCreateComponent("PostTaxDeduction"),
                },
                {
                  title: "Reimbursement",
                  action: () => handleCreateComponent("Reimbursement"),
                },
                {
                  title: "Taxes",
                  action: () => handleCreateComponent("Taxes"),
                },
              ]}
            />
          </Box>
        }
      />

      <StandardAppContainerRounded>
        <Box sx={{ p: 2 }}>
          <TableContainer
            columns={["NAME", "CALCULATION TYPE", "FLAGS", "ACTIONS"]}
            data={components}
            loading={componentsLoading}
          >
            {components?.map((item, index) => (
              <tr
                key={index}
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragOver={() => handleDragOver(index)}
                onDragEnd={handleDragEnd}
              >
                <td>{item?.name}</td>
                <td>{item?.type}</td>
                <td>
                  <HorizBox>
                    {item?.flag === "BASIC_PAY" && (
                      <Tooltip title="Monthly salary that user will recieve in hand">
                        <IconButton size="small">
                          <LocalAtmOutlined />
                        </IconButton>
                      </Tooltip>
                    )}
                    {item?.ignoreInCalculation && (
                      <Tooltip title="This component will be ignored in monthly payroll calculation">
                        <IconButton size="small">
                          <CancelOutlined />
                        </IconButton>
                      </Tooltip>
                    )}
                  </HorizBox>
                </td>
                <td>
                  <HorizBox>
                    <IconButton
                      onClick={() =>
                        history.push(
                          `/hr/settings/${organizationId}?view=EditSalaryComponent&type=Earning&componentId=${item?._id}`
                        )
                      }
                    >
                      <EditOutlined />
                    </IconButton>
                    <IconButton onClick={() => deleteComponent(item?._id)}>
                      <DeleteOutline />
                    </IconButton>
                    <PopupMenuList
                      appearContent={
                        <IconButton>
                          <MoreVert />
                        </IconButton>
                      }
                      actions={[
                        {
                          title: "Mark as monthly salary",
                          action: () => {
                            markComponentAsBasicPay(item?._id);
                          },
                        },
                        {
                          title:
                            item?.ignoreInCalculation === true
                              ? "Use in monthly payroll calculation"
                              : "Do not use in monthly payroll calculation",
                          action: () => {
                            handleComponentIgnoreInCalculation(
                              item?._id,
                              !item?.ignoreInCalculation
                            );
                          },
                        },
                      ]}
                    />
                  </HorizBox>
                </td>
              </tr>
            ))}
          </TableContainer>

          {/* {tab === "Earnings" && <EarningsTable />}
          {tab === "Deduction" && <DeductionTable />}
          {tab === "Reimbursement" && <ReimbursementTable />}
          {tab === "Taxes" && <TaxesTable />} */}
        </Box>
      </StandardAppContainerRounded>
    </StandardContainer>
  );
};

export default SalaryComponents;

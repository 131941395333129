import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import FormHeaderWithActions from "../../styled/generic/FormHeaderWithActions";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import SearchField from "../../styled/generic/SearchField";
import FilterButton from "../../styled/generic/FilterButton";
import HorizBox from "../../styled/generic/HorizBox";
import {
  DeleteOutline,
  EditOutlined,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import PopupMenuList from "../../styled/generic/PopupMenuList";
import TableContainer from "../../styled/generic/TableContainer";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import { useDispatch } from "react-redux";
import DateText from "../../styled/generic/DateText";
import StatusChip from "../../styled/generic/StatusChip";
import dayjs from "dayjs";
import { parsedInt } from "../../../helpers/textHelper";

const Employees = () => {
  const history = useHistory();
  const { organizationId } = useParams();
  const dispatch = useDispatch();

  const [columns, setColumns] = useState([]);
  const [stats, setStats] = useState([
    {
      title: "Head Count",
      value: "0",
    },
    {
      title: "New Joined",
      value: "0",
    },
    {
      title: "Left Employee",
      value: "0",
    },
    {
      title: "Total Active",
      value: "0",
    },
  ]);

  const [leaveMap, setLeaveMap] = useState({});
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [employeeFilterType, setEmployeeFilterType] = useState("active");
  const [employees, setEmployees] = useState([]);
  const [employeesLoading, setEmployeesLoading] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const getEmployees = async () => {
    try {
      setEmployeesLoading(true);
      const { data } = await Api.post("/employee/get", {
        organizationId,
      });

      if (data && Array.isArray(data)) {
        setEmployees(data);

        const oneMonthAgo = dayjs().subtract(1, "month");

        let newlyJoined = data.filter((item) =>
          dayjs(item?.firstPartyContact?.doj).isAfter(oneMonthAgo)
        );

        let leftEmployees = data.filter((item) => item?.hasLeft === true);
        let activeEmployees = data.filter((item) => item?.hasLeft === false);

        setStats([
          {
            title: "Head Count",
            value: data?.length,
          },
          {
            title: "New Joined",
            value: newlyJoined?.length,
          },
          {
            title: "Left Employee",
            value: leftEmployees?.length,
          },
          {
            title: "Total Active",
            value: activeEmployees?.length,
          },
        ]);

        let _leaveMap = {};
        for (let i = 0; i < data.length; i++) {
          let finrel = data[i];
          let leaveBalances = finrel?.payrollContract?.leaves || [];
          leaveBalances = leaveBalances.filter(
            (lb) => lb?.leave?.type === "PAID"
          );
          for (let j = 0; j < leaveBalances?.length; j++) {
            let leaveBalance = leaveBalances[j];
            let leave = leaveBalance?.leave;
            _leaveMap[leave?._id] = leave;
          }
        }
        setLeaveMap(_leaveMap);
        setLeaveTypes(Object.keys(_leaveMap));
        let _leaveTypes = Object.keys(_leaveMap).map(
          (key) => _leaveMap[key]?.name
        );

        setColumns([
          "EMPLOYEE NAME",
          "DESIGNATION",
          "DEPARTMENT",
          "LOCATION",
          "DOJ",
          "Monthly Salary",
          "Basic Pay",
          ..._leaveTypes,
          "ACTIONS",
        ]);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setEmployeesLoading(false);
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  useEffect(() => {
    if (employeeFilterType === "active") {
      setFilteredEmployees(
        employees.filter((item) => item?.status === "active")
      );
    } else if (employeeFilterType === "noticeperiod") {
      setFilteredEmployees(
        employees.filter((item) => item?.status === "notice period")
      );
    } else if (employeeFilterType === "exited") {
      setFilteredEmployees(
        employees.filter((item) => item?.status === "inactive")
      );
    }
  }, [employeeFilterType, employees]);

  return (
    <StandardAppContainerRounded>
      <Box
        sx={{
          border: "1px solid rgba(0,0,0,0.10)",
          borderRadius: "10px",
          p: 2,
        }}
      >
        <Grid container spacing={2}>
          {stats?.map((item, index) => (
            <Grid item xs={3}>
              <Box
                sx={{
                  px: 3,
                  borderLeft:
                    index === 0 ? "none" : "1px solid rgba(0,0,0,0.10)",
                }}
              >
                <Typography variant="h5" gutterBottom>
                  {item?.value}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {item?.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <BoxSpaceBetween sx={{ mt: 3 }}>
        <PopupMenuList
          appearContent={
            <HorizBox spacing={1} sx={{ cursor: "pointer" }}>
              <Typography variant="h6">
                {employeeFilterType === "active"
                  ? "Active Employees"
                  : employeeFilterType === "noticeperiod"
                  ? "Notice Period Employees"
                  : "Exited Employees"}
              </Typography>
              <KeyboardArrowDownOutlined />
            </HorizBox>
          }
          actions={[
            {
              title: "Active Employees",
              action: () => setEmployeeFilterType("active"),
            },
            {
              title: "Notice Period Employees",
              action: () => setEmployeeFilterType("noticeperiod"),
            },
            {
              title: "Exited Employees",
              action: () => setEmployeeFilterType("exited"),
            },
          ]}
        />
        <HorizBox>
          <SearchField placeholder="Search Employee" size="small" />
          <FilterButton />
        </HorizBox>
      </BoxSpaceBetween>
      <Box sx={{ mt: 2 }}>
        <TableContainer
          columns={columns}
          data={filteredEmployees}
          loading={employeesLoading}
        >
          {filteredEmployees?.map((item, index) => (
            <tr
              onClick={() => {
                history.push(`/hr/employees/employee/about/${item?._id}`);
              }}
            >
              <td>{item?.mainProfile?.parent?.displayName}</td>
              <td>{item?.firstPartyContact?.designation?.name}</td>
              <td>{item?.firstPartyContact?.department?.displayName}</td>
              <td>{item?.firstPartyContact?.workLocation?.name}</td>
              <td>
                <DateText date={item?.firstPartyContact?.doj} />
              </td>
              <td>₹ {parsedInt(item?.payrollContract?.monthlySalary) || 0}</td>
              <td>
                ₹{" "}
                {parsedInt(
                  item?.payrollContract?.salaryDetails?.find(
                    (c) => c?.componentRef?.flag === "BASIC_PAY"
                  )?.amount
                ) || 0}
              </td>
              {leaveTypes?.map((leaveType) => (
                <td>
                  {item?.payrollContract?.leaves?.find(
                    (c) => c?.leave?._id === leaveType
                  )?.balance || 0}
                </td>
              ))}
              <td
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <HorizBox>
                  <IconButton
                    onClick={() =>
                      history.push(
                        `/hr/employees/employee/${organizationId}?employeeId=${item?._id}`
                      )
                    }
                  >
                    <EditOutlined />
                  </IconButton>
                </HorizBox>
              </td>
            </tr>
          ))}
        </TableContainer>
      </Box>
    </StandardAppContainerRounded>
  );
};

export default Employees;

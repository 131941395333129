import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../../../../styled/generic/StandardAppContainerRounded";
import FormHeaderWithActions from "../../../../styled/generic/FormHeaderWithActions";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import DatePicker from "../../../../styled/generic/DatePicker";
import HorizBox from "../../../../styled/generic/HorizBox";
import BoxSpaceBetween from "../../../../styled/generic/BoxSpaceBetween";
import SearchField from "../../../../styled/generic/SearchField";
import Select from "../../../../styled/generic/Select";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import Api from "../../../../../helpers/Api";
import TableContainer from "../../../../styled/generic/TableContainer";
import PopupMenuList from "../../../../styled/generic/PopupMenuList";
import MoreButton from "../../../../styled/generic/MoreButton";
import StatusHandler from "../../../../styled/generic/StatusHandler";

const Leaves = () => {
  const { employeeId } = useParams();
  const dispatch = useDispatch();
  const [stats, setStats] = useState([
    {
      title: "Leave Taken",
      value: "0",
    },
    {
      title: "Incoming Leaves",
      value: "0",
    },
    {
      title: "Requested Leaves",
      value: "0",
    },
    {
      title: "Total Leaves",
      value: "0",
    },
  ]);
  const [leaves, setLeaves] = useState([]);
  const [leavesLoading, setLeavesLoading] = useState(false);
  const [date, setDate] = useState(dayjs());

  const getLeaves = async () => {
    try {
      setLeavesLoading(true);
      const { data } = await Api.post(`/employee/leaves/month`, {
        date: date?.toDate(),
        employeeId,
      });
      setLeaves(data);

      let leaveTaken = data?.filter((item) => item?.status === "APPROVED");
      let incomingLeaves = data?.filter((item) => item?.status === "PENDING");
      let requestedLeaves = data?.filter(
        (item) => item?.status === "REQUESTED"
      );
      let totalLeaves = data?.length;

      let newStats = [
        {
          title: "Leave Taken",
          value: leaveTaken?.length,
        },
        {
          title: "Incoming Leaves",
          value: incomingLeaves?.length,
        },
        {
          title: "Requested Leaves",
          value: requestedLeaves?.length,
        },
        {
          title: "Total Leaves",
          value: totalLeaves,
        },
      ];

      setStats(newStats);

      setLeavesLoading(false);
    } catch (error) {
      console.log(error);
      setLeavesLoading(false);
    }
  };

  useEffect(() => {
    getLeaves();
  }, [date]);

  return (
    <StandardAppContainerRounded>
      <FormHeaderWithActions
        label="Leave Details"
        actions={
          <HorizBox>
            {leavesLoading && <CircularProgress size={20} />}
            <DatePicker
              textfieldProps={{ size: "small" }}
              value={date}
              onChange={(date) => setDate(date)}
              views={["year", "month"]}
              format="MMM YYYY"
            />
          </HorizBox>
        }
      />
      <Box
        sx={{
          border: "1px solid rgba(0,0,0,0.10)",
          borderRadius: "10px",
          p: 2,
          mt: 3,
        }}
      >
        <Grid container spacing={2}>
          {stats?.map((item, index) => (
            <Grid item xs={3}>
              <Box
                sx={{
                  px: 3,
                  borderLeft:
                    index === 0 ? "none" : "1px solid rgba(0,0,0,0.10)",
                }}
              >
                <Typography variant="h5" gutterBottom>
                  {item?.value}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {item?.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ mt: 3 }}>
        <TableContainer
          columns={["LEAVE NAME", "LEAVE TYPE", "DATE", "STATUS", "ACTIONS"]}
          data={leaves}
        >
          {leaves?.map((item) => (
            <tr>
              <td>{item?.leave?.name}</td>
              <td>{item?.leave?.type}</td>

              <td>
                {dayjs(item?.fromDate).format("DD MMM YYYY")} -{" "}
                {dayjs(item?.toDate).format("DD MMM YYYY")}
              </td>
              <td>
                <StatusHandler
                  label={item?.status}
                  success={["APPROVED"]}
                  error={["REJECTED"]}
                  info={["REQUESTED"]}
                />
              </td>
              <td>
                <MoreButton />
              </td>
            </tr>
          ))}
        </TableContainer>
      </Box>
    </StandardAppContainerRounded>
  );
};

export default Leaves;

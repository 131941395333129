import { Box } from "@mui/material";
import React from "react";

const StandardContainerRounded = ({ children }) => {
  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: "#F4F7FF",
        borderRadius: "10px",
      }}
    >
      {children}
    </Box>
  );
};

export default StandardContainerRounded;

import React, { useEffect, useState } from "react";
import DrawerContainer from "../styled/generic/DrawerContainer";
import { Box, Typography } from "@mui/material";
import Api from "../../helpers/Api";
import teamUtils from "../team/team.utils";
import { useDispatch, useSelector } from "react-redux";
import DuoButtonGroup from "../styled/generic/DuoButtonGroup";
import { format } from "timeago.js";
import AccessControlStoreHook from "../AccessControl/AccessControlStoreHook";
import { inviteAccept } from "../team/api.call";

const MyWorkInvitationsDrawer = ({ open, setOpen }) => {
  const [invitations, setInvitations] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { handleTeams } = teamUtils;
  const { updateAccessByTeam } = AccessControlStoreHook()

  const getInvitations = async () => {
    try {
      const data = await Api.post("invite/getUserInvites", {
        user: user.profile,
        inviteFor: "WorkSchedule",
      });
      if (data) {
        setInvitations(data?.invitations || []);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    getInvitations();
  }, []);

  const handleReject = async (invite) => {
    const reject = await Api.post("invite/reject", { invite: invite._id });
    setInvitations(invitations.filter((el) => el._id !== invite._id));
  };

  const handleAccept = async (invite) => {
    await inviteAccept({ invite: invite._id, acceptBy : "_id" })
      .then((teamData) => {
        let filteredInvites = invitations.filter((el) => el._id !== invite._id);
        dispatch({
          type: "AddTeam",
          payload: {
            invitations: filteredInvites,
          },
        });
        setInvitations(filteredInvites);

        if (teamData?.parentModelName === "Project" || teamData?.parentModelName === "Organization") {
          handleTeams([teamData], state, dispatch);
          updateAccessByTeam(teamData?._id)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  };

  return (
    <DrawerContainer open={open} setOpen={setOpen} title="View Invitations">
      <Box>
        {invitations?.length > 0 ? (
          <Box>
            {invitations.map((item, index) => (
              <Box
                sx={{
                  mb: 2,
                  py: 2,
                  borderBottom: "1px solid rgba(0,0,0,0.10)",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Organization: {item?.team?.parent?.displayName}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {item?.text}
                </Typography>
                <Typography variant="subtitle2">
                  {format(item?.createdAt)}
                </Typography>

                <DuoButtonGroup
                  primaryButtonListener={() => handleAccept(item)}
                  primaryButtonText="Accept"
                  secondaryButtonListener={() => handleReject(item)}
                  secondaryButtonText="Decline"
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Typography variant="subtitle1" gutterBottom textAlign="center">
            No invitations
          </Typography>
        )}
      </Box>
    </DrawerContainer>
  );
};

export default MyWorkInvitationsDrawer;

import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../../../../styled/generic/StandardAppContainerRounded";
import FormHeaderWithActions from "../../../../styled/generic/FormHeaderWithActions";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import DatePicker from "../../../../styled/generic/DatePicker";
import HorizBox from "../../../../styled/generic/HorizBox";
import BoxSpaceBetween from "../../../../styled/generic/BoxSpaceBetween";
import SearchField from "../../../../styled/generic/SearchField";
import Select from "../../../../styled/generic/Select";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import Api from "../../../../../helpers/Api";
import TableContainer from "../../../../styled/generic/TableContainer";
import PopupMenuList from "../../../../styled/generic/PopupMenuList";
import MoreButton from "../../../../styled/generic/MoreButton";
import StatusHandler from "../../../../styled/generic/StatusHandler";

const SalaryHistory = () => {
  const { employeeId } = useParams();
  const dispatch = useDispatch();
  const [stats, setStats] = useState([
    {
      title: "Total Payslip",
      value: "0",
    },
    {
      title: "Pending Payslip",
      value: "0",
    },
  ]);

  const [payslips, setPayslips] = useState([]);
  const [payslipsLoading, setPayslipsLoading] = useState(false);

  const getPayslips = async () => {
    try {
      setPayslipsLoading(true);
      const { data } = await Api.post(`/employee/payslips`, {
        employeeId,
      });

      setPayslips(data);

      let totalPayslip = data?.length;
      let pendingPayslip = data?.filter((item) => item?.status === "Unpaid");

      let newStats = [
        {
          title: "Total Payslip",
          value: totalPayslip,
        },
        {
          title: "Pending Payslip",
          value: pendingPayslip.length,
        },
      ];

      setStats(newStats);

      setPayslipsLoading(false);
    } catch (error) {
      console.log(error);
      setPayslipsLoading(false);
    }
  };

  useEffect(() => {
    getPayslips();
  }, []);

  return (
    <StandardAppContainerRounded>
      <FormHeaderWithActions label="Salary History" actions={<></>} />
      <Box
        sx={{
          border: "1px solid rgba(0,0,0,0.10)",
          borderRadius: "10px",
          p: 2,
          mt: 3,
        }}
      >
        <Grid container spacing={2}>
          {stats?.map((item, index) => (
            <Grid item xs={3}>
              <Box
                sx={{
                  px: 3,
                  borderLeft:
                    index === 0 ? "none" : "1px solid rgba(0,0,0,0.10)",
                }}
              >
                <Typography variant="h5" gutterBottom>
                  {item?.value}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {item?.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ mt: 3 }}>
        <TableContainer
          columns={[
            "PAYROLL RUN",
            "PAID DAYS",
            "PAY CYCLE",
            "NET PAYMENT",
            "STATUS",
            "ACTIONS",
          ]}
          data={payslips}
          loading={payslipsLoading}
        >
          {payslips?.map((item) => (
            <tr>
              <td>{dayjs(item?.payrollRun?.date).format("MMMM YYYY")}</td>
              <td>{item?.payrollRun?.totalWorkingDays || 0}</td>
              <td>Monthly</td>
              <td>{item?.totalSalary}</td>
              <td>
                <StatusHandler
                  label={item?.status}
                  success={["Paid"]}
                  error={["Unpaid"]}
                />
              </td>
              <td>
                <MoreButton />
              </td>
            </tr>
          ))}
        </TableContainer>
      </Box>
    </StandardAppContainerRounded>
  );
};

export default SalaryHistory;

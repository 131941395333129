import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../../../../styled/generic/StandardAppContainerRounded";
import FormHeaderWithActions from "../../../../styled/generic/FormHeaderWithActions";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import DatePicker from "../../../../styled/generic/DatePicker";
import HorizBox from "../../../../styled/generic/HorizBox";
import BoxSpaceBetween from "../../../../styled/generic/BoxSpaceBetween";
import SearchField from "../../../../styled/generic/SearchField";
import Select from "../../../../styled/generic/Select";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import Api from "../../../../../helpers/Api";
import TableContainer from "../../../../styled/generic/TableContainer";
import PopupMenuList from "../../../../styled/generic/PopupMenuList";
import MoreButton from "../../../../styled/generic/MoreButton";
import FormBox from "../../../../styled/generic/FormBox";
import StatusHandler from "../../../../styled/generic/StatusHandler";

const Loans = () => {
  const { employeeId } = useParams();
  const dispatch = useDispatch();

  const [loans, setLoans] = useState([]);
  const [loansLoading, setLoansLoading] = useState(false);

  const getLoans = async () => {
    try {
      setLoansLoading(true);
      const { data } = await Api.post(`/employee/loans`, {
        employeeId,
      });

      setLoans(data);
      setLoansLoading(false);
    } catch (error) {
      console.log(error);
      setLoansLoading(false);
    }
  };

  useEffect(() => {
    getLoans();
  }, []);

  return (
    <StandardAppContainerRounded>
      {loansLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {loans?.length === 0 ? (
            <Typography variant="h6">No Loans Found</Typography>
          ) : (
            <>
              {loans?.map(({ loan, payments }, index) => (
                <>
                  <FormBox
                    sx={{
                      border: "1px solid #F3F3F3",
                      p: 3,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <HorizBox>
                          <Typography variant="subtitle1">
                            Loan Name:
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}
                          >
                            {loan?.title}
                          </Typography>
                        </HorizBox>
                        <HorizBox>
                          <Typography variant="subtitle1">
                            Loan Start Month:
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}
                          >
                            {dayjs(loan?.startDate).format("MMMM YYYY")}
                          </Typography>
                        </HorizBox>
                        <HorizBox>
                          <Typography variant="subtitle1">
                            Total Amount:
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}
                          >
                            ₹ {loan?.totalAmount || 0}
                          </Typography>
                        </HorizBox>
                      </Grid>
                      <Grid item xs={6}>
                        <HorizBox>
                          <Typography variant="subtitle1">
                            Amount To Pay Monthly:
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}
                          >
                            ₹ {loan?.amountToPayMonthly || 0}
                          </Typography>
                        </HorizBox>
                        <HorizBox>
                          <Typography variant="subtitle1">
                            Amount Paid:
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}
                          >
                            ₹ {loan?.amountPaid || 0}
                          </Typography>
                        </HorizBox>
                        <HorizBox>
                          <Typography variant="subtitle1">Status:</Typography>
                          <StatusHandler
                            label={loan?.status}
                            success={["Closed"]}
                            error={["Open"]}
                          />
                        </HorizBox>
                      </Grid>
                    </Grid>
                  </FormBox>
                  {payments?.length > 0 && (
                    <FormBox label="Repayment Summary">
                      {payments?.map((payment) => (
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            border: "1px solid #F3F3F3",
                            m: 1,
                          }}
                        >
                          <Grid item xs={6}>
                            <Typography variant="h6">
                              {dayjs(payment?.date).format("DD MMMM YYYY")}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Date
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="h6">
                              ₹ {payment?.amount || 0}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: "bold" }}
                            >
                              Amount
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </FormBox>
                  )}
                </>
              ))}
            </>
          )}
        </Box>
      )}
    </StandardAppContainerRounded>
  );
};

export default Loans;

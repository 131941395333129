import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableContainer from "../../../../styled/generic/TableContainer";
import DateText from "../../../../styled/generic/DateText";
import MoreButton from "../../../../styled/generic/MoreButton";
import DrawerContainer from "../../../../styled/generic/DrawerContainer";
import FormBox from "../../../../styled/generic/FormBox";
import EmployeeSearcher from "../../../../styled/generic/EmployeeSearcher";
import TextField from "../../../../styled/generic/TextField";
import { useDispatch, useSelector } from "react-redux";
import DuoButtonGroup from "../../../../styled/generic/DuoButtonGroup";
import DatePicker from "../../../../styled/generic/DatePicker";
import dayjs from "dayjs";
import Api from "../../../../../helpers/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SpaceBetween from "../../../../styled/generic/SpaceBetween";
import { parsedInt } from "../../../../../helpers/textHelper";

const Loan = ({ showLoanDrawer, setShowLoanDrawer }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { organizationId } = useParams();
  const [loans, setLoans] = useState([]);
  const [loansLoading, setLoansLoading] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [title, setTitle] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [amountToPayMonthly, setAmountToPayMonthly] = useState(0);
  const [startDate, setStartDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);

  const createLoan = async () => {
    try {
      setLoading(true);

      let errMsg = "";
      if (!employee?._id) {
        errMsg = "Employee is required";
      } else if (!title || title.trim() === "") {
        errMsg = "Loan name is required";
      } else if (!totalAmount || totalAmount <= 0) {
        errMsg = "Total amount is required";
      } else if (!amountToPayMonthly || amountToPayMonthly <= 0) {
        errMsg = "Amount to pay monthly is required";
      } else if (!startDate) {
        errMsg = "Start date is required";
      }

      if (errMsg) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: errMsg,
          },
        });
        return;
      }

      const { data } = await Api.post(`/loan/create`, {
        worker: employee?._id,
        title,
        totalAmount: totalAmount,
        amountPaid,
        amountToPayMonthly,
        startDate,
        organization: organizationId,
        createdBy: user?._id,
      });

      if (data) {
        setLoans([...loans, data]);
        setShowLoanDrawer(false);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Loan created successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getLoans = async () => {
    try {
      setLoansLoading(true);
      const { data } = await Api.post(`/loan/get`, {
        organizationId: organizationId,
      });
      if (data && Array.isArray(data)) {
        setLoans(data);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch loans",
        },
      });
    } finally {
      setLoansLoading(false);
    }
  };

  useEffect(() => {
    getLoans();
  }, []);

  return (
    <>
      <Box>
        <TableContainer
          columns={[
            "EMPLOYEE NAME",
            "LOAN NAME",
            "LOAN AMOUNT",
            "EMI AMOUNT",
            "REMAINING EMI",
            "LOAN START DATE",
            "STATUS",
          ]}
          data={loans}
          loading={loansLoading}
        >
          {loans?.map((loan) => (
            <tr>
              <td>{loan?.worker?.mainProfile?.parent?.displayName}</td>
              <td>{loan?.title}</td>
              <td>{parsedInt(loan?.totalAmount)}</td>
              <td>{parsedInt(loan?.amountToPayMonthly)}</td>
              <td>{parsedInt(loan?.totalAmount - loan?.amountPaid)}</td>
              <td>
                <DateText date={loan?.startDate} />
              </td>
              <td>{loan?.status}</td>
              {/* <td>
                <MoreButton />
              </td> */}
            </tr>
          ))}
        </TableContainer>
      </Box>
      <DrawerContainer
        title="Add Loan"
        open={showLoanDrawer}
        setOpen={setShowLoanDrawer}
      >
        <SpaceBetween
          left={
            <FormBox label="Employee" isRequired={true}>
              <EmployeeSearcher
                organizationId={organizationId}
                onSelect={(data) => setEmployee(data)}
              />
            </FormBox>
          }
          right={
            <FormBox label="Loan Name" isRequired={true}>
              <TextField
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter"
              />
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <FormBox label="Total Amount" isRequired={true}>
              <TextField
                fullWidth
                value={totalAmount}
                onChange={(e) => setTotalAmount(e.target.value)}
                placeholder="Enter"
                type="number"
              />
            </FormBox>
          }
          right={
            <FormBox label="Amount Paid" isRequired={true}>
              <TextField
                fullWidth
                value={amountPaid}
                onChange={(e) => setAmountPaid(e.target.value)}
                placeholder="Enter"
                type="number"
              />
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <FormBox label="Amount To Pay Per Month" isRequired={true}>
              <TextField
                fullWidth
                value={amountToPayMonthly}
                onChange={(e) => setAmountToPayMonthly(e.target.value)}
                placeholder="Enter"
                type="number"
              />
            </FormBox>
          }
          right={
            <FormBox label="Start Date" isRequired={true}>
              <DatePicker
                value={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </FormBox>
          }
        />
        <Box sx={{ p: 4, backgroundColor: "#E7ECFD33" }}>
          <Typography variant="h6" gutterBottom>
            Loan Preview
          </Typography>
          <Typography variant="body1" gutterBottom>
            Total Repayment Amount: {totalAmount}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Amount Already Paid: {amountPaid}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Remaining Amount: {totalAmount - amountPaid}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Amount To Pay Per Month: {amountToPayMonthly}
          </Typography>
        </Box>
        <DuoButtonGroup
          primaryButtonText="Create"
          primaryButtonListener={() => createLoan()}
          loadingPrimary={loading}
          secondaryButtonText="Cancel"
          secondaryButtonListener={() => setShowLoanDrawer(false)}
        />
      </DrawerContainer>
    </>
  );
};

export default Loan;

import React, { useState } from "react";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import TableContainer from "../../styled/generic/TableContainer";
import TabsWithActions from "../../styled/generic/TabsWithActions";
import { Box } from "@mui/material";
import TabsWithSearch from "../../styled/generic/TabsWithSearch";
import Api from "../../../helpers/Api";

const EmployeesTab = () => {
  const [tab, setTab] = useState("Employees");

  return (
    <>
      <StandardAppContainerRounded>
        <TabsWithSearch
          tabs={["Employees", "Work Schedule"]}
          currentTabVariable={tab}
          onTabChange={(tab) => setTab(tab)}
          disableUnderline={true}
        />
        {tab === "Employees" && (
          <Box>
            <TableContainer
              columns={[
                "ID",
                "Full Name",
                "Email",
                "Created Date",
                "Organization",
                "Action",
              ]}
            ></TableContainer>
          </Box>
        )}
      </StandardAppContainerRounded>
    </>
  );
};

export default EmployeesTab;

import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../../../../styled/generic/StandardAppContainerRounded";
import FormHeaderWithActions from "../../../../styled/generic/FormHeaderWithActions";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import DatePicker from "../../../../styled/generic/DatePicker";
import HorizBox from "../../../../styled/generic/HorizBox";
import BoxSpaceBetween from "../../../../styled/generic/BoxSpaceBetween";
import SearchField from "../../../../styled/generic/SearchField";
import Select from "../../../../styled/generic/Select";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import Api from "../../../../../helpers/Api";
import TableContainer from "../../../../styled/generic/TableContainer";
import PopupMenuList from "../../../../styled/generic/PopupMenuList";
import MoreButton from "../../../../styled/generic/MoreButton";

const Attendance = () => {
  const { employeeId } = useParams();
  const dispatch = useDispatch();
  const [stats, setStats] = useState([
    {
      title: "Total Present",
      value: "0",
    },
    {
      title: "Total Absent",
      value: "0",
    },
    {
      title: "Total Miss Punch",
      value: "0",
    },
    {
      title: "Total Site Visit",
      value: "0",
    },
  ]);
  const [attendance, setAttendance] = useState([]);
  const [attendanceLoading, setAttendanceLoading] = useState(false);
  const [date, setDate] = useState(dayjs());

  const getAttendance = async () => {
    try {
      setAttendanceLoading(true);
      const { data } = await Api.post(`/employee/attendance/month`, {
        date: date?.toDate(),
        employeeId,
      });
      setAttendance(data);

      let present = data?.filter(
        (item) => item?.attendanceCode?.flag === "PRESENT"
      );

      let absent = data?.filter(
        (item) => item?.attendanceCode?.flag === "ABSENT"
      );

      let missPunch = data?.filter(
        (item) => item?.attendanceCode?.category === "MissPunch"
      );

      let siteVisit = data?.filter(
        (item) => item?.attendanceCode?.category === "SiteVisit"
      );

      let newStats = [
        {
          title: "Total Present",
          value: present?.length,
        },
        {
          title: "Total Absent",
          value: absent?.length,
        },
        {
          title: "Total Miss Punch",
          value: missPunch?.length,
        },
        {
          title: "Total Site Visit",
          value: siteVisit?.length,
        },
      ];

      setStats(newStats);
      setAttendanceLoading(false);
    } catch (error) {
      console.log(error);
      setAttendanceLoading(false);
    }
  };

  useEffect(() => {
    getAttendance();
  }, [date]);

  return (
    <StandardAppContainerRounded>
      <FormHeaderWithActions
        label="Attendance Details"
        actions={
          <HorizBox>
            {attendanceLoading && <CircularProgress size={20} />}
            <DatePicker
              textfieldProps={{ size: "small" }}
              value={date}
              onChange={(date) => setDate(date)}
              views={["year", "month"]}
              format="MMM YYYY"
            />
          </HorizBox>
        }
      />
      <Box
        sx={{
          border: "1px solid rgba(0,0,0,0.10)",
          borderRadius: "10px",
          p: 2,
          mt: 3,
        }}
      >
        <Grid container spacing={2}>
          {stats?.map((item, index) => (
            <Grid item xs={3}>
              <Box
                sx={{
                  px: 3,
                  borderLeft:
                    index === 0 ? "none" : "1px solid rgba(0,0,0,0.10)",
                }}
              >
                <Typography variant="h5" gutterBottom>
                  {item?.value}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {item?.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ mt: 3 }}>
        <TableContainer
          columns={[
            "DATE",
            "WORK SCHEDULE",
            "PUNCH IN",
            "PUNCH OUT",
            "ATTENDENCE CODE",
            "ACTION",
          ]}
          data={attendance}
        >
          {attendance.map((item) => (
            <tr>
              <td>{dayjs(item?.date).format("DD MMM YYYY")}</td>
              <td>{item?.workSchedule?.workScheduleName}</td>
              <td>
                {item?.lastClockInTime
                  ? dayjs(item?.lastClockInTime).format("hh:mm A")
                  : "-"}
              </td>
              <td>
                {item?.lastClockOutTime
                  ? dayjs(item?.lastClockOutTime).format("hh:mm A")
                  : "-"}
              </td>
              <td>
                <div
                  style={{
                    backgroundColor: item?.attendanceCode?.color,
                    fontSize: "0.9rem",
                    height: "100%",
                    padding: "5px",
                    textAlign: "center",
                    width: "60%",
                    borderRadius: "4px",
                  }}
                >
                  {item?.attendanceCode?.name}
                </div>
              </td>
              <td>
                <PopupMenuList
                  appearContent={<MoreButton />}
                  actions={[
                    {
                      title: "Todo",
                      action: () => {},
                    },
                  ]}
                />
              </td>
            </tr>
          ))}
        </TableContainer>
      </Box>
    </StandardAppContainerRounded>
  );
};

export default Attendance;

import Api from "../../../../../../helpers/Api";

const createSalaryComponent = async (data) => {
  try {
    const res = await Api.post("/salary-component/create", data);
    return res;
  } catch (err) {
    console.log(err);
  }
};

const getSalaryComponents = async (data) => {
  try {
    const res = await Api.post("/salary-component/get", data);
    return res;
  } catch (err) {
    console.log(err);
  }
};

const getSalaryComponent = async (data) => {
  try {
    const res = await Api.post("/salary-component/get-by-id", data);
    return res;
  } catch (err) {
    console.log(err);
  }
};

const updateSalaryComponent = async (data) => {
  try {
    const res = await Api.post("/salary-component/update", data);
    return res;
  } catch (err) {
    console.log(err);
  }
};

const deleteSalaryComponent = async (data) => {
  try {
    const res = await Api.post("/salary-component/delete", data);
    return res;
  } catch (err) {
    console.log(err);
  }
};

const changeSalaryComponentOrder = async (data) => {
  try {
    const res = await Api.post("/salary-component/change-order", data);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export {
  createSalaryComponent,
  getSalaryComponents,
  getSalaryComponent,
  updateSalaryComponent,
  deleteSalaryComponent,
  changeSalaryComponentOrder
};

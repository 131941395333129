import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import LabelWithSingleActionButton from "../../../../styled/generic/LabelWithSingleActionButton";
import { Add, DeleteOutline, EditOutlined } from "@mui/icons-material";
import StandardAppContainerRounded from "../../../../styled/generic/StandardAppContainerRounded";
import TableContainer from "../../../../styled/generic/TableContainer";

import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../../../helpers/Api";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import DateText from "../../../../styled/generic/DateText";

const WorkSchedule = () => {
  const history = useHistory();
  const { organizationId } = useParams();
  const dispatch = useDispatch();

  const [workschedules, setWorkschedules] = useState([]);
  const [workschedulesLoading, setWorkschedulesLoading] = useState(false);

  const getWorkSchedules = async () => {
    try {
      setWorkschedulesLoading(true);
      const { data } = await Api.post(`/workschedule/employee/get`, {
        organization: organizationId,
      });
      if (data) {
        setWorkschedules(data);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get workschedules",
        },
      });
    } finally {
      setWorkschedulesLoading(false);
    }
  };

  const deleteWorkSchedule = async (id) => {
    try {
      const { data } = await Api.post(`/workschedule/employee/delete`, {
        _id: id,
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "workschedule deleted successfully",
        },
      });

      setWorkschedules(workschedules.filter((item) => item?._id !== id));
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to delete workschedule",
        },
      });
    }
  };

  useEffect(() => {
    getWorkSchedules();
  }, []);

  return (
    <Box>
      <LabelWithSingleActionButton
        label="Work Schedule"
        actionButtonIcon={<Add />}
        actionButtonText="Schedule"
        actionButtonVariant="contained"
        actionButtonListener={() =>
          history.push(
            `/hr/settings/${organizationId}?view=ConfigureWorkSchedule`
          )
        }
      />
      <StandardAppContainerRounded>
        <TableContainer
          columns={[
            "SHIFT NAME",
            "SHIFT START TIME",
            "SHIFT END TIME",
            "WORKING DAYS",
            "ACTIONS",
          ]}
          data={workschedules}
          loading={workschedulesLoading}
        >
          {workschedules.map((workschedule) => (
            <tr>
              <td>{workschedule?.workScheduleName || "Untitled"}</td>
              <td>
                <DateText date={workschedule?.shiftStart} mode="time" />
              </td>
              <td>
                <DateText date={workschedule?.shiftEnd} mode="time" />
              </td>
              <td>{workschedule?.daysOfWeek?.length}</td>
              <td>
                <Box>
                  <IconButton
                    sx={{ mr: 2 }}
                    onClick={() =>
                      history.push(
                        `/hr/settings/${organizationId}?view=ConfigureWorkSchedule&scheduleId=${workschedule?._id}`
                      )
                    }
                  >
                    <EditOutlined />
                  </IconButton>
                  <IconButton>
                    <DeleteOutline
                      onClick={() => deleteWorkSchedule(workschedule?._id)}
                    />
                  </IconButton>
                </Box>
              </td>
            </tr>
          ))}
        </TableContainer>
      </StandardAppContainerRounded>
    </Box>
  );
};

export default WorkSchedule;

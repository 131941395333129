import React, { useEffect, useState } from "react";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import { Box, Typography } from "@mui/material";
import PalAutocomplete from "../../../styled/generic/PalAutocomplete";
import TextField from "../../../styled/generic/TextField";
import FormBox from "../../../styled/generic/FormBox";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import StandardContainerRounded from "../../../styled/generic/StandardContainerRounded";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import Api from "../../../../helpers/Api";
import { useSelector } from "react-redux";

const CreateGroupTimesheet = ({
  open,
  setOpen,
  reloadTimesheets,
  teamId,
  setShowCreatePalDrawer,
  selectedProject,
}) => {
  const [selectedEntity, setSelectedEntity] = useState();
  const [numOfWorkers, setNumOfWorkers] = useState(0);
  const [hourlyRate, setHourlyRate] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [costCode, setCostCode] = useState();
  const [allowance, setAllowance] = useState(0);
  const [deduction, setDeduction] = useState(0);
  const [overtime, setOvertime] = useState(0);
  const [lateFine, setLateFine] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const createGroupTimesheet = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post("/timesheet/group/create", {
        title: "Group Timesheet",
        userId: user?._id,
        projectId: selectedProject?._id || null,
        orgTeamId: teamId,
        worker: selectedEntity?._id,
        workerProfile: selectedEntity?.profile?._id,
        date: new Date().toISOString(),
        numberOfWorkers: numOfWorkers,
        totalHours: totalHours,
        hourlyRate: hourlyRate,
        allowance: allowance,
        deduction: deduction,
        overtime: overtime,
        lateFine: lateFine,
        totalCost: totalCost,
      });
      if (data) {
        setOpen(false);
        reloadTimesheets();
      }
    } catch (err) {
      alert("Error");
    } finally {
      setLoading(false);
    }
  };

  const calculateTotalCost = () => {
    let cost = 0;
    cost +=
      parseFloat(numOfWorkers) *
      parseFloat(hourlyRate) *
      parseFloat(totalHours);

    cost += parseFloat(allowance);
    cost += parseFloat(overtime);

    cost -= parseFloat(deduction);
    cost -= parseFloat(lateFine);

    setTotalCost(isNaN(cost) ? 0 : cost);
  };

  useEffect(() => {
    calculateTotalCost();
  }, [
    numOfWorkers,
    hourlyRate,
    totalHours,
    costCode,
    allowance,
    deduction,
    overtime,
    lateFine,
  ]);

  return (
    <DrawerContainer
      title="Create Individual Timesheet"
      open={open}
      setOpen={setOpen}
    >
      <Box>
        <PalAutocomplete
          selectedEntity={selectedEntity}
          setSelectedEntity={setSelectedEntity}
          teamId={teamId}
          label="Group"
          palType="Group"
        />
        <SpaceBetween
          left={
            <FormBox label="Number of Workers">
              <TextField
                fullWidth
                value={numOfWorkers}
                onChange={(evt) => setNumOfWorkers(evt.target.value)}
                placeholder="Enter"
                type="Number"
              />
            </FormBox>
          }
          right={
            <FormBox label="Hourly Rate">
              <TextField
                fullWidth
                value={hourlyRate}
                onChange={(evt) => setHourlyRate(evt.target.value)}
                placeholder="Enter"
                type="Number"
              />
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <FormBox label="Total Hours">
              <TextField
                fullWidth
                value={totalHours}
                onChange={(evt) => setTotalHours(evt.target.value)}
                placeholder="Enter"
                type="Number"
              />
            </FormBox>
          }
          right={
            <FormBox label="Cost Code">
              <TextField
                fullWidth
                value={costCode}
                onChange={(evt) => setCostCode(evt.target.value)}
                placeholder="Enter"
                type="Number"
              />
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <FormBox label="Allowance">
              <TextField
                fullWidth
                value={allowance}
                onChange={(evt) => setAllowance(evt.target.value)}
                placeholder="Enter"
                type="Number"
              />
            </FormBox>
          }
          right={
            <FormBox label="Deduction">
              <TextField
                fullWidth
                value={deduction}
                onChange={(evt) => setDeduction(evt.target.value)}
                placeholder="Enter"
                type="Number"
              />
            </FormBox>
          }
        />
        <SpaceBetween
          left={
            <FormBox label="Overtime">
              <TextField
                fullWidth
                value={overtime}
                onChange={(evt) => setOvertime(evt.target.value)}
                placeholder="Enter"
                type="Number"
              />
            </FormBox>
          }
          right={
            <FormBox label="Late Fine">
              <TextField
                fullWidth
                value={lateFine}
                onChange={(evt) => setLateFine(evt.target.value)}
                placeholder="Enter"
                type="Number"
              />
            </FormBox>
          }
        />
        <StandardContainerRounded>
          <FormBox disableMargins={true} label="Total Cost">
            <Typography variant="subtitle1" color="primary">
              {`(${numOfWorkers} * ${hourlyRate} * ${totalHours}) + ${allowance} + ${overtime} - ${deduction} - ${lateFine} =  ₹ ${totalCost}`}
            </Typography>
          </FormBox>
        </StandardContainerRounded>
        <DuoButtonGroup
          primaryButtonText="Create"
          primaryButtonListener={() => createGroupTimesheet()}
          secondaryButtonText="New Relation"
          secondaryButtonListener={() => setShowCreatePalDrawer(true)}
        />
      </Box>
    </DrawerContainer>
  );
};

export default CreateGroupTimesheet;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import TableContainer from "../../styled/generic/TableContainer";
import SingleUserWithCaptionTableCell from "../../styled/generic/SingleUserWithCaptionTableCell";
import StatusHandler from "../../styled/generic/StatusHandler";
import dayjs from "dayjs";

const Leaves = ({ finrel }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { organizationId } = useParams();
  const [tab, setTab] = useState("APPROVED");
  const [leaves, setLeaves] = useState([]);
  const [leavesLoading, setLeavesLoading] = useState(false);
  const [filteredLeaves, setFilteredLeaves] = useState([]); // [approved, pending, rejected

  const getLeaves = async () => {
    try {
      setLeavesLoading(true);
      const { data } = await Api.post(`/employee/leaves`, {
        employeeId: finrel?._id,
      });
      if (data && Array.isArray(data)) {
        setLeaves(data);
        setFilteredLeaves(data?.filter((leave) => leave.status === tab));
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch leaves",
        },
      });
    } finally {
      setLeavesLoading(false);
    }
  };

  useEffect(() => {
    getLeaves();
  }, []);

  useEffect(() => {
    setFilteredLeaves(leaves?.filter((leave) => leave.status === tab));
  }, [tab]);

  return (
    <StandardAppContainerRounded>
      <BoxSpaceBetween sx={{ mb: 3 }}>
        <Box>
          <ToggleButtonGroup
            color="primary"
            value={tab}
            exclusive
            onChange={(e, value) => setTab(value)}
            aria-label="Platform"
            size="small"
          >
            <ToggleButton value="APPROVED">Approved</ToggleButton>
            <ToggleButton value="PENDING">Pending</ToggleButton>
            <ToggleButton value="REJECTED">Rejected</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </BoxSpaceBetween>

      <TableContainer
        columns={[
          "LEAVE NAME",
          "LEAVE TYPE",
          "FROM DATE",
          "TO DATE",
          "TOTAL DAYS",
          "STATUS",
        ]}
        loading={leavesLoading}
        data={filteredLeaves}
      >
        {filteredLeaves.map((leave, index) => (
          <tr>
            <td>{leave?.leave?.name}</td>
            <td>{leave?.leave?.type}</td>
            <td>{dayjs(leave.fromDate).format("DD MMMM YYYY")}</td>
            <td>{dayjs(leave.toDate).format("DD MMMM YYYY")}</td>
            <td>{dayjs(leave?.toDate).diff(leave?.fromDate, "day") + 1}</td>
            <td>
              <StatusHandler
                label={leave?.status}
                success={["APPROVED"]}
                error={["REJECTED"]}
                info={["REQUESTED"]}
              />
            </td>
          </tr>
        ))}
      </TableContainer>
    </StandardAppContainerRounded>
  );
};

export default Leaves;

import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import SearchField from "../../styled/generic/SearchField";
import { Box, CircularProgress, MenuItem, Stack } from "@mui/material";
import Select from "../../styled/generic/Select";
import FilterButton from "../../styled/generic/FilterButton";
import TableContainer from "../../styled/generic/TableContainer";
import Api from "../../../helpers/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import dayjs from "dayjs";
import { useTableFStyles } from "../../../utils/style";
import { useDispatch } from "react-redux";
import HorizBox from "../../styled/generic/HorizBox";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import FormBox from "../../styled/generic/FormBox";
import DatePicker from "../../styled/generic/DatePicker";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";

const AttendanceMonthView = ({
  showBulkAttendanceDrawer,
  setShowBulkAttendanceDrawer,
}) => {
  const dispatch = useDispatch();
  const classes = useTableFStyles();
  const { organizationId } = useParams();
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([
    "January",
    "Feburary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [selectedMonth, setSelectedMonth] = useState(months[dayjs().month()]);
  const [attendance, setAttendance] = useState([]);
  const [users, setUsers] = useState({});
  const [columns, setColumns] = useState([]);
  const [attendanceLoading, setAttendanceLoading] = useState(false);
  const [openAttendanceDrawer, setOpenAttendanceDrawer] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState();
  const [workEntryCodes, setWorkEntryCodes] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedBulkAttendance, setSelectedBulkAttendance] = useState("");
  const [bulkStartDate, setBulkStartDate] = useState(dayjs());
  const [bulkEndDate, setBulkEndDate] = useState(dayjs().add(1, "day"));
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const initCalendarAndGetAttendance = async () => {
    try {
      // Clear old attendance
      setAttendance({});

      let START_YEAR = 1901;
      let END_YEAR = 2099;
      let yearsArray = [];
      let days = [];

      // Calculate days for the month and prepare an array out of those day count
      for (let year = START_YEAR; year <= END_YEAR; year++) {
        yearsArray.push(year);
      }
      setYears(yearsArray);
      if (selectedYear % 4 === 0 && selectedMonth === "Feburary") {
        days = [...Array(29).keys()];
      } else if (selectedMonth === "Feburary") {
        days = [...Array(28).keys()];
      } else if (
        [
          "January",
          "March",
          "May",
          "July",
          "August",
          "October",
          "December",
        ].includes(selectedMonth)
      ) {
        days = [...Array(31).keys()];
      } else {
        days = [...Array(30).keys()];
      }

      let newDateFromSelectedYearAndMonth = dayjs(
        selectedYear + "-" + selectedMonth + "-01"
      );
      setSelectedDate(newDateFromSelectedYearAndMonth);

      // Now set columns using days
      setColumns(["EMPLOYEE NAME", ...days]);

      // Now fetch attendance
      setAttendanceLoading(true);
      const { data } = await Api.post(`/employee/attendance`, {
        organizationId: organizationId,
        year: selectedYear,
        month: months.indexOf(selectedMonth),
      });

      let processedAttendance = {};
      let tempUsers = {};

      // Create blank objects array as per day count
      let blankDayArr = [];
      for (let i = 0; i < days?.length; i++) {
        blankDayArr.push({});
      }

      // Process attendance data
      for (let i = 0; i < data.length; i++) {
        let timesheet = data[i];
        let employee = timesheet?.worker;
        let employeeId = employee?._id;
        let dateObj = dayjs(timesheet?.date);

        if (!processedAttendance[employeeId]) {
          processedAttendance[employeeId] = [...blankDayArr];
          tempUsers[employeeId] = employee;
        }

        let dateOfMonth = dateObj?.date() - 1;

        processedAttendance[employeeId][dateOfMonth] = timesheet;
      }

      setAttendance(processedAttendance);
      setUsers(tempUsers);
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch attendance",
        },
      });
    } finally {
      setAttendanceLoading(false);
    }
  };

  useEffect(() => {
    initCalendarAndGetAttendance();
  }, [selectedMonth, selectedYear]);

  // const getAttendance = async () => {
  //   try {
  //     setAttendanceLoading(true);
  //     const { data } = await Api.post(`/employee/attendance`, {
  //       organizationId: organizationId,
  //       year: selectedYear,
  //       month: months.indexOf(selectedMonth),
  //     });
  //     let processedAttendance = {};
  //     let tempUsers = {};

  //     // Blank objects as per day count
  //     let blankDayArr = [];

  //     for (let i = 0; i < days?.length; i++) {
  //       blankDayArr.push({});
  //     }

  //     for (let i = 0; i < data.length; i++) {
  //       let timesheet = data[i];
  //       let employee = timesheet?.worker;
  //       let employeeId = employee?._id;
  //       let dateObj = dayjs(timesheet?.date);
  //       let dateOfMonth = dateObj?.date();

  //       if (!processedAttendance[employeeId]) {
  //         processedAttendance[employeeId] = [...blankDayArr];
  //         tempUsers[employeeId] = employee;
  //       }


  //       // if (dateOfMonth > days.length) {
  //       //   processedAttendance[employeeId][dateOfMonth - 1] = {}
  //       //   continue;
  //       // }

  //       processedAttendance[employeeId][dateOfMonth - 1] = timesheet;

  //     }
  //     setAttendance(processedAttendance);
  //     setUsers(tempUsers);
  //   } catch (error) {
  //     console.log(error);
  //     dispatch({
  //       type: "AddApiAlert",
  //       payload: {
  //         success: false,
  //         message: "Unable to fetch attendance",
  //       },
  //     });
  //   } finally {
  //     setAttendanceLoading(false);
  //   }
  // };

  const handleTimesheetOpen = (attendance, user, dayOfMonth) => {
    setSelectedAttendance(attendance);
    setOpenAttendanceDrawer(true);
  };

  const getHROptions = async () => {
    try {
      const { data } = await Api.post("/employee/hr-options", {
        organizationId: organizationId,
      });

      if (data) {
        setWorkEntryCodes(data?.workEntryCodes);
        setEmployees(data?.employees);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    getHROptions();
  }, []);

  const createTimesheet = async (timesheet) => {
    try {
      const { data } = await Api.post("/timesheet/create/employee", {
        attendanceCode: timesheet?.attendanceCode?._id,
        lastClockInTime: timesheet?.lastClockInTime,
        lastClockOutTime: timesheet?.lastClockOutTime,
        date: timesheet?.date,
        worker: timesheet?.worker?._id,
        organization: organizationId,
        workSchedule: timesheet?.worker?.defaultWorkSchedule,
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Timesheet created successfully",
          },
        });
        initCalendarAndGetAttendance();
        setSelectedAttendance();
        setOpenAttendanceDrawer(false);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const updateTimesheet = async (timesheet) => {
    try {
      const { data } = await Api.post("/timesheet/update", {
        timesheetId: timesheet?._id,
        updateBody: {
          attendanceCode: timesheet?.attendanceCode?._id,
          lastClockInTime: timesheet?.lastClockInTime,
          lastClockOutTime: timesheet?.lastClockOutTime,
          workSchedule: timesheet?.worker?.defaultWorkSchedule,
        },
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Timesheet updated successfully",
          },
        });
        initCalendarAndGetAttendance();
        setSelectedAttendance();
        setOpenAttendanceDrawer(false);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const handleBulkAttendance = async () => {
    try {
      const { data } = await Api.post("/timesheet/handle-bulk-update", {
        employees: selectedEmployees,
        attendanceCode: selectedBulkAttendance,
        startDate: bulkStartDate.toDate(),
        endDate: bulkEndDate.toDate(),
        organizationId: organizationId,
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Timesheets updated successfully",
          },
        });
        initCalendarAndGetAttendance();
        //setSelectedEmployees([]);
        setShowBulkAttendanceDrawer(false);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  return (
    <StandardAppContainerRounded>
      <BoxSpaceBetween sx={{ mt: 3 }}>
        <SearchField placeholder="Search" size="small" sx={{ width: 300 }} />
        <HorizBox>
          {attendanceLoading && <CircularProgress size={20} />}
          <Select
            value={selectedYear}
            onChange={(evt) => setSelectedYear(evt.target.value)}
            size="small"
            sx={{ width: 140 }}
          >
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={selectedMonth}
            onChange={(evt) => setSelectedMonth(evt.target.value)}
            size="small"
            sx={{ width: 200 }}
          >
            {months.map((month, index) => (
              <MenuItem key={index} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
          <FilterButton size="small" />
        </HorizBox>
      </BoxSpaceBetween>
      <Box sx={{ mt: 3, maxWidth: "100%", overflowX: "auto" }}>
        <table className={classes.tableSty}>
          <tr
            style={{
              height: "40px",
              borderBottom: "none",
            }}
          >
            {columns.map((day) => (
              <th
                style={{
                  width: isNaN(day) ? "150px" : "50px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {isNaN(day)
                  ? ""
                  : dayjs(selectedDate)
                      .date(day + 1)
                      .format("ddd")
                      .toUpperCase()}
              </th>
            ))}
          </tr>
          <tr
            style={{
              height: "40px",
              borderBottom: "none",
            }}
          >
            {columns.map((day) => (
              <th
                style={{
                  width: isNaN(day) ? "150px" : "50px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {isNaN(day) ? day : day + 1}
              </th>
            ))}
          </tr>
          {Object.entries(attendance).map(([key, value]) => (
            <tr
              style={{
                height: "40px",
                borderBottom: "none",
              }}
            >
              <td style={{ width: "150px" }}>
                <span
                  style={{
                    display: "inline-block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {users[key]?.mainProfile?.parent?.displayName}
                </span>
              </td>
              {value.map((day, index) => (
                <td
                  style={{
                    width: "40px",
                    padding: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (day?._id) {
                      handleTimesheetOpen(day);
                    } else {
                      handleTimesheetOpen({
                        date: dayjs()
                          .year(selectedYear)
                          .month(months.indexOf(selectedMonth))
                          .date(index + 1)
                          .toDate(),
                        worker: users[key],
                      });
                    }
                  }}
                >
                  <div
                    style={{
                      backgroundColor: day?.attendanceCode?.color,
                      fontSize: "0.9rem",
                      height: "100%",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >
                    {day?.attendanceCode?.abbreviation || " "}
                  </div>
                </td>
              ))}
              {/* <td style={{ width: "150px" }}>-</td> */}
            </tr>
          ))}
        </table>
      </Box>
      <DrawerContainer
        title={dayjs(selectedAttendance?.date).format("DD MMM YYYY")}
        open={openAttendanceDrawer}
        setOpen={setOpenAttendanceDrawer}
      >
        <FormBox label="Attendance Code">
          <Select
            fullWidth
            value={selectedAttendance?.attendanceCode?._id || ""}
            onChange={(e) => {
              setSelectedAttendance({
                ...selectedAttendance,
                attendanceCode: workEntryCodes.find(
                  (code) => code?._id === e.target.value
                ),
              });
            }}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select
            </MenuItem>
            {workEntryCodes.map((code) => (
              <MenuItem value={code?._id}>{code?.name}</MenuItem>
            ))}
          </Select>
        </FormBox>
        {/* <FormBox label="Clock In">
          <DatePicker
            format="hh:mm A"
            fullWidth={true}
            value={
              selectedAttendance?.lastClockInTime
                ? dayjs(selectedAttendance?.lastClockInTime)
                : ""
            }
            onChange={(date) => {
              setSelectedAttendance({
                ...selectedAttendance,
                lastClockInTime: date,
              });
            }}
          />
        </FormBox>
        <FormBox label="Clock Out">
          <DatePicker
            format="hh:mm A"
            fullWidth={true}
            value={
              selectedAttendance?.lastClockOutTime
                ? dayjs(selectedAttendance?.lastClockOutTime)
                : ""
            }
            onChange={(date) => {
              setSelectedAttendance({
                ...selectedAttendance,
                lastClockOutTime: date,
              });
            }}
          />
        </FormBox> */}
        <DuoButtonGroup
          primaryButtonText={selectedAttendance?._id ? "Update" : "Create"}
          primaryButtonListener={() => {
            if (selectedAttendance?._id) {
              updateTimesheet(selectedAttendance);
            } else {
              createTimesheet(selectedAttendance);
            }
          }}
          secondaryButtonText="Cancel"
          secondaryButtonListener={() => {
            setOpenAttendanceDrawer(false);
          }}
        />
      </DrawerContainer>
      <DrawerContainer
        title="Bulk Attendance"
        open={showBulkAttendanceDrawer}
        setOpen={setShowBulkAttendanceDrawer}
      >
        <FormBox label="Employee">
          <Select
            value={selectedEmployees}
            onChange={(e) => {
              setSelectedEmployees(e.target.value);
            }}
            fullWidth
            variant="outlined"
            displayEmpty
            multiple
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <span>Select</span>;
              }
              return employees
                .filter((employee) => selected.includes(employee?._id))
                .map((employee) => employee?.mainProfile?.parent?.displayName)
                .join(", ");
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                  width: 250,
                },
              },
            }}
          >
            <MenuItem disabled>Select</MenuItem>
            {employees.map((employee) => (
              <MenuItem value={employee?._id}>
                {employee?.mainProfile?.parent?.displayName || "Untitled"}
              </MenuItem>
            ))}
          </Select>
        </FormBox>

        <FormBox label="Attendance Code">
          <Select
            fullWidth
            value={selectedBulkAttendance}
            onChange={(e) => {
              setSelectedBulkAttendance(e.target.value);
            }}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select
            </MenuItem>
            {workEntryCodes.map((code) => (
              <MenuItem value={code?._id}>{code?.name}</MenuItem>
            ))}
          </Select>
        </FormBox>

        <FormBox label="Start Date">
          <DatePicker
            value={bulkStartDate}
            onChange={(date) => setBulkStartDate(date)}
            fullWidth
            variant="outlined"
          />
        </FormBox>
        <FormBox label="End Date">
          <DatePicker
            value={bulkEndDate}
            onChange={(date) => setBulkEndDate(date)}
            fullWidth
            variant="outlined"
          />
        </FormBox>

        <DuoButtonGroup
          primaryButtonText="Update"
          primaryButtonListener={() => {
            handleBulkAttendance();
          }}
          secondaryButtonText="Cancel"
          secondaryButtonListener={() => {
            setShowBulkAttendanceDrawer(false);
          }}
        />
      </DrawerContainer>
    </StandardAppContainerRounded>
  );
};

export default AttendanceMonthView;

import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../../../../styled/generic/StandardAppContainerRounded";
import FormBox from "../../../../styled/generic/FormBox";
import TextField from "../../../../styled/generic/TextField";
import SpaceBetween from "../../../../styled/generic/SpaceBetween";
import DuoButtonGroup from "../../../../styled/generic/DuoButtonGroup";
import Api from "../../../../../helpers/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";

const PayrollSettings = () => {
  const { organizationId } = useParams();
  const dispatch = useDispatch();

  const [payrollRunDayOfMonth, setPayrollRunDayOfMonth] = useState();
  const [totalWorkingDays, setTotalWorkingDays] = useState();
  const [settings, setSettings] = useState({});

  const getPayrollSettings = async () => {
    try {
      const { data } = await Api.post("/payroll/settings/get", {
        organization: organizationId,
      });
      setPayrollRunDayOfMonth(data?.payrollRunDayOfMonth);
      setTotalWorkingDays(data?.totalWorkingDays);
      setSettings(data);
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get payroll settings",
        },
      });
    }
  };

  const updatePayrollSettings = async () => {
    try {
      await Api.post("/payroll/settings/update", {
        _id: settings?._id,
        updateBody: {
          totalWorkingDays,
        },
      });
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Payroll settings updated",
        },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to update payroll settings",
        },
      });
    }
  };

  useEffect(() => {
    getPayrollSettings();
  }, []);

  return (
    <StandardAppContainerRounded>
      <SpaceBetween
        left={
          <FormBox label="Total Working Days">
            <TextField
              value={totalWorkingDays}
              onChange={(e) => setTotalWorkingDays(e.target.value)}
              fullWidth
              type="number"
            />
          </FormBox>
        }
      />
      <DuoButtonGroup
        primaryButtonText="Save"
        primaryButtonListener={() => updatePayrollSettings()}
        hideSecondary={true}
      />
    </StandardAppContainerRounded>
  );
};

export default PayrollSettings;

import { Box, IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import StandardAppContainer from "../../../styled/generic/StandardAppContainer";
import StandardAppContainerRounded from "../../../styled/generic/StandardAppContainerRounded";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import LabelWithSingleActionButton from "../../../styled/generic/LabelWithSingleActionButton";
import { Add, DeleteOutline, Edit, EditOutlined } from "@mui/icons-material";
import Dialog from "../../../styled/generic/Dialog";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import FormBox from "../../../styled/generic/FormBox";
import TextField from "../../../styled/generic/TextField";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import Api from "../../../../helpers/Api";
import TableContainer from "../../../styled/generic/TableContainer";

const Departments = () => {
  const [showCreateDepartmentDialog, setShowCreateDepartmentDialog] =
    useState(false);

  const [departmentName, setDepartmentName] = useState("");
  const [departmentCode, setDepartmentCode] = useState("");
  const [departmentDescription, setDepartmentDescription] = useState("");
  const [departmentsLoading, setDepartmentsLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  useEffect(() => {
    if (showCreateDepartmentDialog) {
      setDepartmentName(selectedDepartment?.displayName);
      setDepartmentCode(selectedDepartment?.code);
      setDepartmentDescription(selectedDepartment?.description);
    } else {
      setDepartmentName("");
      setDepartmentCode("");
      setDepartmentDescription("");
      setSelectedDepartment(null);
    }
  }, [showCreateDepartmentDialog]);

  const { user } = useSelector((state) => state.auth);
  const { organizationId } = useParams();
  const dispatch = useDispatch();

  const createDepartment = async () => {
    try {
      setDepartmentsLoading(true);
      const { data } = await Api.post(`/department/create`, {
        displayName: departmentName,
        code: departmentCode,
        description: departmentDescription,
        organization: organizationId,
        author: user?._id,
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Department created successfully",
          },
        });
        setDepartments((prev) => [...prev, data]);
        setShowCreateDepartmentDialog(false);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setDepartmentsLoading(false);
    }
  };

  const getDepartments = async () => {
    try {
      const { data } = await Api.post(`/department/get`, {
        organization: organizationId,
      });
      if (data) {
        setDepartments(data);
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch departments",
        },
      });
    }
  };

  const updateDepartment = async () => {
    try {
      const { data } = await Api.post(`/department/update`, {
        _id: selectedDepartment?._id,
        updateBody: {
          displayName: departmentName,
          code: departmentCode,
          description: departmentDescription,
        },
      });
      if (data) {
        setDepartments((prev) => {
          let temp = [...prev];
          temp[temp.findIndex((item) => item._id === selectedDepartment._id)] =
            data;
          return temp;
        });
        setShowCreateDepartmentDialog(false);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Department updated successfully",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const deleteDepartment = async (id) => {
    try {
      const { data } = await Api.post(`/department/delete`, {
        _id: id,
      });

      setDepartments((prev) => {
        let temp = [...prev];
        temp.splice(
          temp.findIndex((item) => item._id === id),
          1
        );
        return temp;
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Department deleted successfully",
        },
      });
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  return (
    <Box>
      <LabelWithSingleActionButton
        label="Departments"
        actionButtonIcon={<Add />}
        actionButtonText="Departments"
        actionButtonVariant="contained"
        actionButtonListener={() => setShowCreateDepartmentDialog(true)}
      />
      <StandardAppContainerRounded>
        <TableContainer
          columns={[
            "DEPARTMENT NAME",
            "DEPARTMENT CODE",
            "NUMBER OF WORKFORCE",
            "ACTIONS",
          ]}
          data={departments}
          loading={departmentsLoading}
        >
          {departments?.map((item) => (
            <tr>
              <td>{item?.displayName}</td>
              <td>{item?.code}</td>
              <td>{item?.numEmployees}</td>
              <td>
                <Stack direction="row" spacing={1}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSelectedDepartment(item);
                      setShowCreateDepartmentDialog(true);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      deleteDepartment(item?._id);
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </Stack>
              </td>
            </tr>
          ))}
        </TableContainer>
      </StandardAppContainerRounded>
      <Dialog
        open={showCreateDepartmentDialog}
        setOpen={setShowCreateDepartmentDialog}
        title="Create Department"
      >
        <Box>
          <SpaceBetween
            left={
              <FormBox label="Department Name">
                <TextField
                  placeholder="Enter"
                  value={departmentName}
                  onChange={(e) => setDepartmentName(e.target.value)}
                  fullWidth
                />
              </FormBox>
            }
            right={
              <FormBox label="Department Code">
                <TextField
                  placeholder="Enter"
                  value={departmentCode}
                  onChange={(e) => setDepartmentCode(e.target.value)}
                  fullWidth
                />
              </FormBox>
            }
          />
          <FormBox label="Department Description">
            <TextField
              placeholder="Enter"
              value={departmentDescription}
              onChange={(e) => setDepartmentDescription(e.target.value)}
              multiline
              rows={5}
              fullWidth
            />
          </FormBox>
          <DuoButtonGroup
            primaryButtonText={selectedDepartment ? "Update" : "Create"}
            primaryButtonListener={() => {
              if (selectedDepartment) {
                updateDepartment();
              } else {
                createDepartment();
              }
            }}
            secondaryButtonText="Cancel"
            secondaryButtonListener={() => setShowCreateDepartmentDialog(false)}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

export default Departments;

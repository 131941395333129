import React, { useEffect, useState } from "react";
import StandardAppContainer from "../../styled/generic/StandardAppContainer";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import {
  Box,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import TableContainer from "../../styled/generic/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import dayjs from "dayjs";
import SingleUserWithCaptionTableCell from "../../styled/generic/SingleUserWithCaptionTableCell";
import StatusHandler from "../../styled/generic/StatusHandler";
import Button from "../../styled/generic/Button";
import AssignLeaveDialog from "./AssignLeaveDialog";

const Leaves = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { organizationId } = useParams();
  const [tab, setTab] = useState("APPROVED");
  const [leaves, setLeaves] = useState([]);
  const [leavesLoading, setLeavesLoading] = useState(false);
  const [filteredLeaves, setFilteredLeaves] = useState([]); // [approved, pending, rejected
  const [showAssignLeaveDialog, setShowAssignLeaveDialog] = useState(false);

  const getLeaves = async () => {
    try {
      setLeavesLoading(true);
      const { data } = await Api.post(`/leave/assign/getAll`, {
        organizationId: organizationId,
      });
      if (data && Array.isArray(data)) {
        setLeaves(data);
        setFilteredLeaves(data?.filter((leave) => leave.status === tab));
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to fetch payrolls",
        },
      });
    } finally {
      setLeavesLoading(false);
    }
  };

  useEffect(() => {
    getLeaves();
  }, []);

  useEffect(() => {
    setFilteredLeaves(leaves?.filter((leave) => leave.status === tab));
  }, [tab]);

  return (
    <StandardAppContainerRounded>
      <BoxSpaceBetween sx={{ mb: 3 }}>
        <Box>
          <ToggleButtonGroup
            color="primary"
            value={tab}
            exclusive
            onChange={(e, value) => setTab(value)}
            aria-label="Platform"
            size="small"
          >
            <ToggleButton value="APPROVED">Approved</ToggleButton>
            <ToggleButton value="PENDING">Pending</ToggleButton>
            <ToggleButton value="REJECTED">Rejected</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowAssignLeaveDialog(true)}
        >
          Leaves
        </Button>
      </BoxSpaceBetween>

      <TableContainer
        columns={[
          "EMPLOYEE NAME",
          "LEAVE NAME",
          "LEAVE TYPE",
          "FROM DATE",
          "TO DATE",
          "TOTAL DAYS",
          "STATUS",
        ]}
        loading={leavesLoading}
        data={filteredLeaves}
      >
        {filteredLeaves.map((leave, index) => (
          <tr>
            <td>
              <SingleUserWithCaptionTableCell
                label={leave?.assignedTo?.mainProfile?.parent?.displayName}
                url={
                  leave?.assignedTo?.mainProfile?.parent?.displayPicture?.url
                }
                caption={
                  leave?.assignedTo?.firstPartyContact?.department
                    ?.displayName +
                  " " +
                  leave?.assignedTo?.firstPartyContact?.designation?.name
                }
              />
            </td>
            <td>{leave?.leave?.name}</td>
            <td>{leave?.leave?.type}</td>
            <td>{dayjs(leave.fromDate).format("DD MMMM YYYY")}</td>
            <td>{dayjs(leave.toDate).format("DD MMMM YYYY")}</td>
            <td>{dayjs(leave?.toDate).diff(leave?.fromDate, "day") + 1}</td>
            <td>
              <StatusHandler
                label={leave?.status}
                success={["APPROVED"]}
                error={["REJECTED"]}
                info={["REQUESTED"]}
              />
            </td>
          </tr>
        ))}
      </TableContainer>
      <AssignLeaveDialog
        open={showAssignLeaveDialog}
        setOpen={setShowAssignLeaveDialog}
        organizationId={organizationId}
        onLeaveAssigned={() => getLeaves()}
      />
    </StandardAppContainerRounded>
  );
};

export default Leaves;

import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import Api from "../../../helpers/Api";
import { useDispatch } from "react-redux";
import TableContainer from "../../styled/generic/TableContainer";
import MoreButton from "../../styled/generic/MoreButton";
import dayjs from "dayjs";
import StatusHandler from "../../styled/generic/StatusHandler";
import PopupMenu from "../../styled/generic/PopupMenu";
import PopupMenuList from "../../styled/generic/PopupMenuList";
import axios from "axios";
import configObject from "../../../config";

const PaySlip = ({ finrel }) => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState("payrollrun");
  const [paychecks, setPaychecks] = useState([]);

  const [loans, setLoans] = useState([]);
  const [paychecksLoading, setPaychecksLoading] = useState(false);

  const getPaychecks = async () => {
    try {
      setPaychecksLoading(true);
      const { data } = await Api.post("/payroll/paycheck/worker/get", {
        finRelId: finrel._id,
      });
      setPaychecks(data?.payslips);
      setLoans(data?.loanPayments);
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to clock in",
        },
      });
    } finally {
      setPaychecksLoading(false);
    }
  };

  useEffect(() => {
    getPaychecks();
  }, []);

  const exportPaycheckAsPDF = async (paycheck) => {
    try {
      const response = await axios({
        url: `${configObject.apiUrl}/payroll/paycheck/pdf`,
        method: "POST",
        responseType: "blob",
        data: {
          paycheckId: paycheck?._id,
        },
      });

      if (response) {
        // Create a download link
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Paycheck.pdf`);
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to export paycheck as PDF",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to export paycheck as PDF",
        },
      });
    }
  };

  return (
    <StandardAppContainerRounded>
      <BoxSpaceBetween sx={{ mb: 3 }}>
        <Box>
          <ToggleButtonGroup
            color="primary"
            value={tab}
            exclusive
            onChange={(e, value) => setTab(value)}
            aria-label="Platform"
            size="small"
          >
            <ToggleButton value="payrollrun">Pay Slips</ToggleButton>
            <ToggleButton value="loans">Loans</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box></Box>
      </BoxSpaceBetween>
      {tab === "payrollrun" && (
        <Box>
          <TableContainer
            columns={[
              "MONTH",
              "PAYABLE DAYS",
              "PAY CYCLE",
              "NET PAYMENT",
              "PAYMENT DATE",
              "STATUS",
              "ACTIONS",
            ]}
            loading={paychecksLoading}
            data={paychecks}
          >
            {paychecks?.map((paycheck) => (
              <tr>
                <td>
                  <Typography variant="subtitle1">
                    {dayjs(paycheck?.date).format("MMMM YYYY")}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">
                    {
                      paycheck?.timesheets?.filter(
                        (t) => t?.attendanceCode?.flag !== "ABSENT"
                      )?.length
                    }
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">
                    {paycheck?.payCycle || "Monthly"}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">
                    {paycheck?.totalSalary}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">
                    {dayjs(paycheck?.createdAt).format("DD MMMM YYYY")}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">
                    {paycheck?.status}
                  </Typography>
                </td>
                <td>
                  <PopupMenuList
                    appearContent={<MoreButton />}
                    actions={[
                      {
                        title: "Export as PDF",
                        action: () => exportPaycheckAsPDF(paycheck),
                      },
                    ]}
                  />
                </td>
              </tr>
            ))}
          </TableContainer>
        </Box>
      )}
      {tab === "loans" && (
        <Box>
          <TableContainer
            columns={[
              "LOAN TITLE",
              "TOTAL AMOUNT",
              "AMOUNT PAID",
              "AMOUNT DUE",
              "DATE",
              "STATUS",
              "ACTIONS",
            ]}
            loading={paychecksLoading}
            data={loans}
          >
            {loans?.map((loan) => (
              <tr>
                <td>
                  <Typography variant="subtitle1">
                    {loan?.loan?.title}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">
                    {loan?.loan?.totalAmount}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">{loan?.amount}</Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">
                    {loan?.loan?.totalAmount - loan?.loan?.amountPaid}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">
                    {dayjs(loan?.date).format("DD MMMM YYYY")}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">
                    <StatusHandler
                      label={loan?.loan?.status}
                      success={["Closed"]}
                      error={["Open"]}
                    />
                  </Typography>
                </td>
                <td>
                  <MoreButton />
                </td>
              </tr>
            ))}
          </TableContainer>
        </Box>
      )}
    </StandardAppContainerRounded>
  );
};

export default PaySlip;

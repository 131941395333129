import React, { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import TabsWithActions from "../../styled/generic/TabsWithActions";
import { Add } from "@material-ui/icons";
import StandardContainer from "../../styled/generic/StandardContainer";
import TimesheetTab from "./TimesheetTab";
import PopupMenuList from "../../styled/generic/PopupMenuList";
import EmployeesTab from "./EmployeesTab";

const WorkSchedule = () => {
  const [tab, setTab] = useState("All Timesheets");

  const [showGroupTimesheetDrawer, setShowGroupTimesheetDrawer] =
    useState(false);
  const [showUsersTimesheetDrawer, setShowUsersTimesheetDrawer] =
    useState(false);
  const [
    showPersonalTimesheetCreationDrawer,
    setShowPersonalTimesheetCreationDrawer,
  ] = useState(false);

  const [showCreateWorkScheduleDrawer, setShowCreateWorkScheduleDrawer] =
    useState(false);

  return (
    <StandardContainer>
      <TabsWithActions
        tabs={[
          {
            title: "All Timesheets",
            action: (
              <PopupMenuList
                closeOnClick={true}
                appearContent={
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    disableElevation
                  >
                    Add Timesheet
                  </Button>
                }
                actions={[
                  {
                    title: "New Individual Timesheet",
                    action: () => setShowPersonalTimesheetCreationDrawer(true),
                  },
                  {
                    title: "New Users Timesheet",
                    action: () => setShowUsersTimesheetDrawer(true),
                  },
                  {
                    title: "New Group Timesheet",
                    action: () => setShowGroupTimesheetDrawer(true),
                  },
                ]}
              />
            ),
          },
          {
            title: "Employees",
            action: (
              <Button
                variant="contained"
                startIcon={<Add />}
                disableElevation
                onClick={() => setShowCreateWorkScheduleDrawer(true)}
              >
                Add WorkSchedule
              </Button>
            ),
          },
          {
            title: "Analytics",
          },
        ]}
        onTabChange={(val) => setTab(val)}
        currentTabVariable={tab}
      />

      {tab === "All Timesheets" && (
        <TimesheetTab
          showUsersTimesheetDrawer={showUsersTimesheetDrawer}
          setShowUsersTimesheetDrawer={setShowUsersTimesheetDrawer}
          showPersonalTimesheetCreationDrawer={
            showPersonalTimesheetCreationDrawer
          }
          setShowPersonalTimesheetCreationDrawer={
            setShowPersonalTimesheetCreationDrawer
          }
          showGroupTimesheetDrawer={showGroupTimesheetDrawer}
          setShowGroupTimesheetDrawer={setShowGroupTimesheetDrawer}
        />
      )}

      {tab === "Employees" && (
        <EmployeesTab
          showCreateWorkScheduleDrawer={showCreateWorkScheduleDrawer}
          setShowCreateWorkScheduleDrawer={setShowCreateWorkScheduleDrawer}
        />
      )}

      {/* {tab === "Analytics" && (
        <AnalyticsTab projects={projects} teamId={teamId} />
      )} */}

      {/* <div>
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Timesheets" {...a11yProps(0)} />
            <Tab label="Employees" {...a11yProps(1)} />
            <Tab label="Analytics" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <TimesheetTab projects={projects} teamId={teamId} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <EmployeesTab projects={projects} teamId={teamId} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <AnalyticsTab projects={projects} teamId={teamId} />
        </CustomTabPanel>
      </div> */}
    </StandardContainer>
  );
};

export default WorkSchedule;
